// components
import BaseSideModal from '../modals/BaseSideModal';
import Button from '../common/buttons/Button';
import CollapsibleFilter from './CollapsibleFilter';

// filter items
import FilterAmount from './items/FilterAmount';
import FilterMultiSelect from './items/FilterMultiSelect';
import FilterDate from './items/FilterDate';
import FilterDropdown from './items/FilterDropdown';
import FilterSwitch from './items/FilterSwitch';
import FilterSelect from './items/FilterSelect';

const FilterModal = (props) => {
	const renderFilter = (filter) => {
		switch (filter.type) {
			case 'date':
				return (
					<FilterDate
						selectedFilters={props.selectedFilters}
						onFieldChange={props.onFieldChange}
						filter={filter}
					/>
				);
			case 'dropdown':
				return (
					<FilterDropdown
						selectedFilters={props.selectedFilters}
						onFieldChange={props.onFieldChange}
						filter={filter}
					/>
				);
			case 'switch':
				return (
					<FilterSwitch
						selectedFilters={props.selectedFilters}
						onFieldChange={props.onFieldChange}
						filter={filter}
					/>
				);
			case 'select':
				return (
					<FilterSelect
						selectedFilters={props.selectedFilters}
						onFieldChange={props.onFieldChange}
						filter={filter}
					/>
				);
			case 'multi-select':
				return (
					<FilterMultiSelect
						selectedFilters={props.selectedFilters}
						onFieldChange={props.onFieldChange}
						filter={filter}
					/>
				);
			case 'amount':
				return (
					<FilterAmount
						onFieldChange={props.onFieldChange}
						selectedFilters={props.selectedFilters}
						filter={filter}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<BaseSideModal size='xs' {...props}>
			<BaseSideModal.Form
				initialFormFields={props.itemToEdit || props.initialFormFields}
				onFieldChange={props.onFieldChange}
				onSubmit={() => {}}
			>
				<BaseSideModal.Header title='Filters' />
				<BaseSideModal.Body noPadding>
					<div className='divide-y divide-gray2'>
						{Array.isArray(props.filters) && props.filters.map((filter) => (
							<CollapsibleFilter
								key={filter.title}
								title={filter.title}
								filter={filter}
								onFieldChange={props.onFieldChange}
								selectedFilters={props.selectedFilters}
							>
								{renderFilter(filter)}
							</CollapsibleFilter>
						))}
					</div>
				</BaseSideModal.Body>
				<BaseSideModal.Footer>
					<div className='flex justify-between w-full'>
						<Button variant='text-outline' disabled={props.submitDisabled} onClick={props.onResetFilters}>
							Reset Filters
						</Button>
						<Button disabled={props.submitDisabled} onClick={props.onSubmit}>
							Apply Filters
						</Button>
					</div>
				</BaseSideModal.Footer>
			</BaseSideModal.Form>
		</BaseSideModal>
	);
};

export default FilterModal;
