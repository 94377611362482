import { useState, useEffect } from 'react';
import Toggle from '../Toggle';
import SingleSelectDropdown from '../../SingleSelectDropdown';

const MAX_HOUR = 12;
const MAX_MINUTE = 59;
const MIN_HOUR = 1;
const MIN_MINUTE = 0;

let HOUR_OPTIONS = [];
for (let i = MIN_HOUR; i <= MAX_HOUR; i++) {
	HOUR_OPTIONS.push({ value: i, label: i.toString() });
}
let MINUTE_OPTIONS = [];
for (let i = MIN_MINUTE; i <= MAX_MINUTE; i++) {
	MINUTE_OPTIONS.push({ value: i, label: i.toString().padStart(2, '0') });
}


const TimeField = ({ name, defaultHour, defaultMinute, defaultPeriod, onChangeHour, onChangeMinute, onChangePeriod }) => {
	const [hour, setHour] = useState(defaultHour === 0 ? 12 : defaultHour > 12 ? defaultHour - 12 : defaultHour ?? 12);
	const [minute, setMinute] = useState(defaultMinute ?? 0);
    const [period, setPeriod] = useState(defaultPeriod);

	useEffect(() => {
		setHour(defaultHour === 0 ? 12 : defaultHour > 12 ? defaultHour - 12 : defaultHour ?? 12);
	}, [defaultHour]);

	useEffect(() => {
		setMinute(defaultMinute ?? 0);
	}, [defaultMinute]);

	useEffect(() => {
		setPeriod(defaultPeriod);
	}, [defaultPeriod]);

	return (
		<>
			<div className='flex gap-x-2 mt-2 mb-2 items-center justify-center mx-4'>
				<div className='form-group mb-0'>
					<div className='form-input'>
						<SingleSelectDropdown
							items={HOUR_OPTIONS}
							selectedId={hour}
							showAll={false}
							idField={'value'}
							align={'left'}
							showSearch={false}
							className={'form-select form-select-remove-arrow'}
							alignDropdown={'left'}
							onSelect={(value) => {
								setHour(value);
								onChangeHour(value, `${name}Hour`);
							}}
							menuPlacement='top'
							removeextraspace
						/>
					</div>
				</div>
				:
				<div className='form-group mb-0'>
					<div className='form-input'>
						<SingleSelectDropdown
							items={MINUTE_OPTIONS}
							selectedId={minute}
							showAll={false}
							idField={'value'}
							align={'left'}
							showSearch={false}
							className={'form-select form-select-remove-arrow'}
							alignDropdown={'left'}
							onSelect={(value) => {
								setMinute(value);
								onChangeMinute(value, `${name}Minute`);
							}}
							menuPlacement='top'
							removeextraspace
						/>
					</div>
				</div>
				<Toggle
					className="w-full"
					toggleValues={[
						{ value: 'am', label: 'AM' },
						{ value: 'pm', label: 'PM' },
					]}
                    value={period}
                    onClick={(value) => {
                        setPeriod(value);
						onChangePeriod(value, `${name}Period`);
                    }}
				/>
			</div>
		</>
	);
};

export default TimeField;
