import cloudServiceAgreement from '../helpers/contract_templates/cloud_service_agreement';
import ycSales from '../helpers/contract_templates/yc_sales';
import orderForm from '../helpers/contract_templates/order_form';

export function getDefaultContractTemplates() {
	return [
		{
			id: 1,
			title: 'Cloud Service Template',
			path: '/helpers/contract_templates/cloud_service_agreement.txt',
			template: cloudServiceAgreement,
		},
		{
			id: 2,
			title: 'YC Cloud Sales Template',
			path: '/helpers/contract_templates/yc_sales.txt',
			template: ycSales,
		},
		{
			id: 3,
			title: 'Order Form Template',
			path: '/helpers/contract_templates/order_form.txt',
			template: orderForm,
		},
	];
}

export function isContractExpired(contract) {
	return contract.status === 'EXPIRED';
}

export function isContractExecuted(contract) {
	return contract.status === 'EXECUTED';
}

export function isContractDraft(contract) {
	return contract.status === 'DRAFT';
}

export function canEditContract(contractStatus) {
	return ['PENDING_SIGNATURES', 'NEEDS_APPROVAL', 'REJECTED', 'DECLINED', 'EXPIRED'].includes(contractStatus);
}

export function isCampaignsEnabled(settings) {
	return (
		settings.invoice_email_config &&
		settings.invoice_email_config.send_customer_emails &&
		settings.proposal_sign_reminder_enabled
	);
}

export function canPreviewContract(contractStatus) {
	return ['PENDING_SIGNATURES', 'DRAFT', 'NEEDS_APPROVAL', 'REJECTED'].includes(contractStatus);
}

export function canRevertContractToDraft(constractStatus) {
	return ['PENDING_SIGNATURES', 'EXPIRED'].includes(constractStatus);
}
