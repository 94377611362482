import $ from "jquery";
import moment from 'moment';
import React, { createContext } from 'react';
import Label from '../components/Label';
;const _ = require("lodash");


export function getStartDateForSubscription(subscription) {
    const length = subscription.term.frequency;
    if (length === "DAY" || length === "HOUR") {
        return moment(subscription.start_date).format("MMM D hh:mma");
    } else {
        return moment(subscription.start_date).format("MMM D, YYYY");
    }
}

export function getRenewalDateForSubscription(subscription) {
    const length = subscription.term.frequency;
    if (length === "DAY" || length === "HOUR") {
        return moment(subscription.renewal_date).format("MMM D hh:mma");
    } else {
        return moment(subscription.renewal_date).format("MMM D, YYYY");
    }
}

export function getSubscriptionStatus(subscription) {
    let showPauseInformation = !_.isNil(subscription.pause_config);
    let statusDetails = null;
    if (subscription.status === "CANCELLED") {
        statusDetails = <Label.Info className="d-flex align-items-center">Cancelled</Label.Info>
    } else if (subscription.trial) {
        let dateFormat = "MMM D";
        if (moment(subscription.change_date).year() !== moment().year()) {
            dateFormat = "MMM D, YYYY";
        }
        if (subscription.end_date) {
            statusDetails =
                <div className="flex flex-row gap-1">
                    <Label.Neutral className="d-flex align-items-center">Trials till {moment(subscription.end_date).format("MMM D")}</Label.Neutral>
                    <Label.Info className="d-flex align-items-center">Cancels {moment(subscription.end_date).format("MMM D")}</Label.Info>
                </div>
        } else {
            statusDetails = <Label.Neutral>Trials till {moment(subscription.change_date).format(dateFormat)}</Label.Neutral>
        }
    } else if (subscription.end_date) {
        let dateFormat = "MMM D";
        if (moment(subscription.end_date).year() !== moment().year()) {
            dateFormat = "MMM D, YYYY";
        }
        if (moment().isAfter(subscription.end_date)) {
            statusDetails = <Label.Info>Cancelled</Label.Info>
            showPauseInformation = false;
        } else if (subscription.trial) {
            if (!_.isNil(subscription.cancel_config)) {
                statusDetails =
                    <div className="flex flex-row gap-1">
                        <Label.Neutral className="d-flex align-items-center">Trials till {moment(subscription.end_date).format("MMM D")}</Label.Neutral>
                        <Label.Info className="d-flex align-items-center">Cancels {moment(subscription.end_date).format("MMM D")}</Label.Info>
                    </div>
            } else {
                statusDetails = <Label.Neutral>Trials till {moment(subscription.end_date).format(dateFormat)}</Label.Neutral>
            }
        } else if (!_.isNil(subscription.change_config)) {
            statusDetails = <Label.Warning><i className="fa fa-clock mr-1"/>Changes on {moment(subscription.end_date).format(dateFormat)}</Label.Warning>
        } else if (!_.isNil(subscription.cancel_date)) {
            statusDetails = <Label.Info><i className="fa fa-clock mr-1"/>Cancels on { moment(subscription.end_date).format(dateFormat) }</Label.Info>
        } else {
            statusDetails = <Label.Info><i className="fa fa-clock mr-1"/>Ends on { moment(subscription.end_date).format(dateFormat) }</Label.Info>
        }
    } else if (subscription.status === "UNPAID") {
        statusDetails = <Label.Danger>Unpaid</Label.Danger>
    } else if (subscription.status === "ACTIVE") {
        statusDetails = <Label.Success>Active</Label.Success>
    } else if (subscription.status === "PENDING_PAYMENT") {
        statusDetails = <Label.Warning>Pending Payment Method</Label.Warning>
    } else {
        statusDetails = <Label.Info>{ subscription.status }</Label.Info>
    }
    return <div className="flex flex-row gap-1 flex-wrap">
        { statusDetails }
        {
            showPauseInformation &&
                <Label.Info>
                    <i className="fa fa-pause"/>&nbsp;Paused
                </Label.Info>
        }
    </div>
}

export function getSubscriptionCancelReasonOptions() {
    return [
        { value: null, label: "Not provided" },
        { value: "too_expensive", label: "It's too expensive" },
        { value: "need_more_features", label: "I need more features" },
        { value: "found_an_alternative", label: "I found an alternative" },
        { value: "no_longer_need_it", label: "I no longer need it" },
        { value: "customer_service_less", label: "Customer service was less than expected" },
        { value: "ease_of_use_less", label: "Ease of use less than expected" },
        { value: "quality_less", label: "Quality less than expected" },
        { value: "other", label: "Other Reason" },
    ]
}

export function getDescriptionForSubscriptionCancelReason(reason) {
    const options = getSubscriptionCancelReasonOptions();
    const selectedOption = options && _.find(options, (op) => op.value === reason);
    if (selectedOption) {
        return selectedOption.label
    } else {
        return reason
    }
}

export function getProductPricingIdsInPhase(phase) {
    const subsIds = phase.bundle_pricing ? _.map(phase.bundle_pricing.bundle_product_pricings, (pp) => pp.product_pricing_id) : [];
    let oneIds = [];
    if (phase.one_time_billables) {
        oneIds = _.map(phase.one_time_billables, (b) => b.product_pricing_id);
    } else if (phase.onetime_items) {
        oneIds = _.map(phase.onetime_items, (b) => b.product_pricing_id);
    }
    return _.concat(subsIds, oneIds);
}

export function getAllProductPricingIdsInContract(contract) {
    let allPPIds = getProductPricingIdsInPhase(contract);
    _.each(contract.scheduled_changes, sc => {
        allPPIds = _.concat(allPPIds, getProductPricingIdsInPhase(sc))
    })
    return allPPIds;
}
