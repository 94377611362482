import { useMemo } from 'react';

const FilterSelect = ({ selectedFilters, onFieldChange, filter }) => {
	const value = useMemo(() => selectedFilters[filter.name] ?? null, [selectedFilters, filter.name]);

	return (
		<div className='space-y-1'>
			{filter.options.map((option, i) => (
				<div key={i} className='flex items-center'>
					<input
						id={filter.name + String(option.value)}
						name={filter.name}
						type='radio'
						defaultChecked={option.value === value}
						onChange={() => onFieldChange(filter.name, option.value)}
						className='border-gray3 text-primary-updated focus:ring-primary-updated'
					/>
					<label
						htmlFor={filter.name + String(option.value)}
						className='ml-2 block text-sm font-normal leading-6 text-dark-gray'
					>
						{option.label}
					</label>
				</div>
			))}
		</div>
	);
};

export default FilterSelect;
