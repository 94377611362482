import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPost } from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import MapleTable from '../../../components/MapleTable';
import CopyableComponent from '../../../components/CopyableComponent';
import moment from 'moment/moment';
import Label from '../../../components/Label';
import BaseOverlayTrigger from '../../../components/BaseOverlayTrigger';
import InfiniteScroll from 'react-infinite-scroll-component';
import BaseForm from '../../../components/BaseForm';
import { Form } from 'react-bootstrap';

const _ = require('lodash');

function IntegrationDataSchema() {
	const { getApiUrl, setPageTitle } = useContext(BaseContext);
	const { integration } = useContext(IntegrationContext);
	const [tables, setTables] = useState([]);
	const [selectedTable, setSelectedTable] = useState(0);
	const [tableOptions, setTableOptions] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setPageTitle(`Data Schema`);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	const exportSchema = () => {
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(tables))}`;
		const link = document.createElement('a');
		link.href = jsonString;
		link.download = 'schema.json';

		link.click();
	};

	const onFieldChange = (name, value) => {
		if (name === 'table') {
			setSelectedTable(value);
		}
	};

	const fetchData = () => {
		serverFetch(getApiUrl(`/integrations/${integration.id}/data_schema`)).then((res) => {
			if (res) {
				const results = res.tables || [];
				setTables(results);
				const a = _.map(results, (table, i) => {
					return {
						value: i,
						label: table.name,
					};
				});
				setTableOptions(a);
				setLoading(false);
			}
		});
	};

	return (
		<ContentContainer>
			<Section
				title={`Data Schema`}
				loading={loading}
				subtitle={`The schema that we export to ${integration.name}.`}
				actions={[
					{
						variant: 'primary',
						icon: 'fa fa-cloud-download',
						label: 'Export Schema',
						onClick: exportSchema,
					},
				]}
			>
				<br />
				<h5>Table</h5>
				<BaseForm className='mt-0' onFieldChange={onFieldChange}>
					<BaseForm.Input
						name={`table`}
						type='select'
						label={'Table'}
						hideLabel
						formClassName='mb-0'
						options={tableOptions}
						showSearch={tableOptions.length > 6}
					/>
				</BaseForm>
				<MapleTable>
					<MapleTable.Content>
						<thead>
							<tr>
								<MapleTable.TH>Column Name</MapleTable.TH>
								<MapleTable.TH>Type</MapleTable.TH>
								<MapleTable.TH>Description</MapleTable.TH>
							</tr>
						</thead>
						<tbody className='divide-y divide-gray-200'>
							{tables.length > 0 &&
								_.map(tables[selectedTable].columns, (column) => {
									return (
										<MapleTable.TR key={tables[selectedTable].name + column.name}>
											<td>{column.name}</td>
											<td>{column.type}</td>
											<td>{column.description}</td>
										</MapleTable.TR>
									);
								})}
						</tbody>
					</MapleTable.Content>
				</MapleTable>
			</Section>
		</ContentContainer>
	);
}

export default IntegrationDataSchema;
