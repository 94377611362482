import classnames from 'classnames';

// icons
import Hourglass03 from '@untitled-ui/icons-react/build/esm/Hourglass03';
import Check from '@untitled-ui/icons-react/build/esm/Check';

const variantStyles = {
    check: "!border-[#ABEFC6] bg-[#ECFDF3]",
    hourglass: "!border-[#FEDF89] bg-[#FFFAEB]",
};

const variantTextStyles = {
    check: "text-[#067647]",
    hourglass: "text-[#B54708]",
}

const variantIcon = {
    check: <Check width={11} height={11} className="text-[#17B26A]"/>,
    hourglass: <Hourglass03 width={11} height={11} className="text-[#F79009]"/>
}

const Status = ({ variant, children }) => {
    return (
        <span className={classnames("border flex justify-center items-center py-1 px-[10px] gap-1 rounded-full text-xs", variantStyles[variant])}>
            {variantIcon[variant]}
            <span className={classnames("font-medium", variantTextStyles[variant])}>{children}</span>
        </span>
    );
};

export default Status;