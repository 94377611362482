import { canRevertContractToDraft, isContractExpired } from '../../helpers/contracts';
import { convertVariablesIntoHtml } from '../../helpers/common';

// components
import Button from '../common/buttons/Button';
import ConfirmationButton from '../common/buttons/ConfirmationButton';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useState } from 'react';

const ContractPreviewModal = (props) => {
	const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

	const { status, content, counter_signatories, signatories, title } = props.contractDetails;

	const renderPrimaryButton = () => {
		if (props.isEditingTemplate) {
			return <SubmitButton>Update</SubmitButton>;
		}

		if (!canRevertContractToDraft(status)) {
			return <Button onClick={() => props.setIsEditingTemplate(true)}>Edit</Button>;
		}

		return (
			<Button onClick={props.onClose}>
				Close
			</Button>
		);
	};

	const renderSecondaryButton = () => {
		if (props.isEditingTemplate) {
			return (
				<Button variant='text-outline' onClick={() => props.setIsEditingTemplate(false)}>
					Back
				</Button>
			);
		}

		if (canRevertContractToDraft(status)) {
			return (
				<ConfirmationButton
					variant='text-outline'
					title={'Move to Draft?'}
					body={
						<div>
							{props.hasAnyoneSigned && (
								<div>
									<span>Are you sure you want to move the contract back to draft?</span>
									<br />
									<br />
									<span>
										Some signatories have already signed. Their signatures will be invalidated, and
										they will be requested to sign the document again.
									</span>
								</div>
							)}
							{!props.hasAnyoneSigned && (
								<span>
									Are you sure you want to move the contract back to draft? Doing so will make the
									contract links unavailable until it is finalized again.
								</span>
							)}
						</div>
					}
					confirmationText={
						props.hasAnyoneSigned && !isContractExpired(props.contractDetails) ? 'CONFIRM REVERT' : null
					}
					onConfirm={(event) => {
						props.onClickMoveToDraftAndEdit(true);
					}}
					modalCentered
					onModalOpen={() => setIsSecondModalOpen(true)}
					onModalClose={() => setIsSecondModalOpen(false)}
				>
					Move to Draft & Edit
				</ConfirmationButton>
			);
		}

		return (
			<Button variant="text-outline" onClick={props.onClose}>
				Close
			</Button>
		);
	};

	return (
		<BaseModal size='xl' show={props.show} onClose={props.onClose} className={isSecondModalOpen ? "modal-dimmed" : ""}>
			<BaseForm initialFormFields={props.contractDetails} onSubmit={props.onClickUpdate}>
				<BaseModal.Header>
					<BaseModal.Title>{title}</BaseModal.Title>
					<BaseModal.Subtitle>
						{props.isEditingTemplate
							? 'Please feel free to update the content as you choose. Note that, any changes to the content will not be reflected in the template it is adopted from.'
							: 'Here is the preview of the contents of the contract.'}
					</BaseModal.Subtitle>
				</BaseModal.Header>
				<BaseModal.Body className="bg-[#F5F5F5] p-3 flex justify-center">
					<div className='overflow-auto min-h-[300px] h-[60vh] bg-white'>
						{props.isEditingTemplate ? (
							<div className='h-full py-3 px-3 w-[980px]'>
								<BaseForm.Input colSpan='12' type='text' name='title' label='Title' />
								<BaseForm.Input
									colSpan='12'
									type='editor2'
									name='content_template'
									height='600'
									tokens={props.templateFields}
								/>
							</div>
						) : (
							<div className="py-5 w-full scale-45 modal-lg:scale-74 modal-xl:scale-100 origin-top-left modal-xl:origin-top aspect-video">
								<div
									className='w-[980px] h-full mx-4'
									dangerouslySetInnerHTML={{
										__html: convertVariablesIntoHtml(content, counter_signatories, signatories),
									}}
								/>
							</div>
						)}
					</div>
				</BaseModal.Body>
				<BaseModal.Footer>
					<div className='flex justify-between flex-grow'>
						{renderSecondaryButton()}
						{renderPrimaryButton()}
					</div>
				</BaseModal.Footer>
			</BaseForm>
		</BaseModal>
	);
};

export default ContractPreviewModal;
