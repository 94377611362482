import '../../App.scss';
import '../../css/modals.scss';
import { Alert } from 'react-bootstrap';
import React, {
	Fragment,
	useEffect,
	useState,
	useContext,
	createContext,
	forwardRef,
	useImperativeHandle,
	createRef,
} from 'react';
import { renderChildren } from '../../helpers/common';
import BaseForm from '../BaseForm';
import classnames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import XClose from '@untitled-ui/icons-react/build/esm/XClose';

export const SideModalContext = createContext();

const BaseSideModal = (props) => {
	const [showChildren, setShowChildren] = useState(false);
	const [sizeClass, setSizeClass] = useState('max-w-lg');

	const handleClose = () => {
		document.body.click();
		if (props.onClose) {
			props.onClose(false);
		}
	};

	useEffect(() => {
		if (!props.show && showChildren) {
			setTimeout(() => {
				setShowChildren(false);
			}, 250);
		} else {
			setShowChildren(props.show);
		}
	}, [props.show, showChildren]);

	useEffect(() => {
		if (props.size === 'lg') {
			setSizeClass('max-w-2xl');
		} else if (props.size === 'md') {
			setSizeClass('max-w-lg');
		} else if (props.size === 'sm') {
			setSizeClass('max-w-md');
		} else if (props.size === 'xs') {
			setSizeClass('max-w-[356px]');
		}
	}, [props.size]);

	return (
		<SideModalContext.Provider value={{ handleClose }}>
			<Transition.Root show={props.show || false} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={handleClose}>
					<div className='fixed inset-0' />
					<div className='fixed inset-0 overflow-hidden'>
						<div className='absolute inset-0 overflow-hidden'>
							<div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
								<Transition.Child
									as={Fragment}
									enter='transform transition ease-in-out duration-400'
									enterFrom='translate-x-full'
									enterTo='translate-x-0'
									leave='transform transition ease-in-out duration-400'
									leaveFrom='translate-x-0'
									leaveTo='translate-x-full'
								>
									<Dialog.Panel
										className={classnames(
											'pointer-events-auto w-screen flex flex-col bg-white h-full rounded-l-xl shadow-right-modal',
											sizeClass,
											props.className,
										)}
									>
										{showChildren && renderChildren(props)}
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</SideModalContext.Provider>
	);
};

const Header = (props) => {
	const { handleClose } = useContext(SideModalContext);
	return (
		<div className={classnames('px-6 py-3 rounded-tl-xl bg-white')}>
			<div className='flex items-center justify-between space-x-3'>
				<div className='space-y-1'>
					<Dialog.Title
						className={classnames(
							' font-semibold leading-6 text-dark-900 text-lg text-dark-gray',
						)}
					>
						{props.title}
					</Dialog.Title>
					{props.subtitle && <span className='text-sm text-gray-500'>{props.subtitle}</span>}
				</div>
				<div className='flex h-7 items-center'>
					<button
						type='button'
						className='relative text-gray-400 hover:text-gray-500'
						onClick={() => handleClose()}
					>
						<span className='absolute -inset-2.5' />
						<span className='sr-only'>Close panel</span>
						<XClose width={24} height={24} className='transition' aria-hidden='true' />
					</button>
				</div>
			</div>
		</div>
	);
};
BaseSideModal.Header = Header;

const Form = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		getFormData() {
			if (formRef.current) {
				return formRef.current.getFormData();
			}
			return null;
		},
	}));
	const formRef = createRef();

	return (
		<BaseForm
			ref={formRef}
			{...props}
			className={classnames(
				'flex h-full flex-col divide-y divide-gray2 bg-white shadow-xl rounded-l-xl',
				props.className,
			)}
		>
			{renderChildren(props)}
		</BaseForm>
	);
});
BaseSideModal.Form = Form;

const Content = (props) => {
	return (
		<div
			className={classnames(
				'flex h-full flex-col divide-y bg-white shadow-xl divide-gray2 rounded-l-xl',
				props.className,
			)}
		>
			{renderChildren(props)}
		</div>
	);
};
BaseSideModal.Content = Content;

const Body = (props) => {
	return (
		<div
			className={classnames(
				{ 'px-6 py-3': !props.noPadding },
				'relative flex-1 overflow-auto',
				props.className,
			)}
		>
			<div className='hide notification-modal ahide noprint'>
				<Alert variant='warning'>Warning message</Alert>
			</div>
			{renderChildren(props)}
		</div>
	);
};
BaseSideModal.Body = Body;

const Title = (props) => {
	return <div className='title'>{renderChildren(props)}</div>;
};
BaseSideModal.Title = Title;

const Footer = (props) => {
	return (
		<div className={classnames('flex flex-shrink-0 justify-end px-6 py-3')}>
			{renderChildren(props)}
		</div>
	);
};
BaseSideModal.Footer = Footer;

export default BaseSideModal;
