import { useMemo } from 'react';

// components
import SingleSelectDropdown from '../../SingleSelectDropdown';

// styles
import classnames from 'classnames';

const FilterDropdown = ({ selectedFilters, onFieldChange, filter, disabled = false }) => {
	const value = useMemo(() => selectedFilters[filter.name] ?? null, [selectedFilters, filter.name]);

	return (
		<div className='space-y-0 w-full'>
			<div className='form-group'>
				<div className='form-input'>
					<SingleSelectDropdown
						items={filter.options}
						selectedId={value}
						showAll={false}
						idField={'value'}
						align={'left'}
						showSearch={false}
						className={classnames('form-select', disabled ? 'disabled' : '')}
						alignDropdown={'left'}
						onSelect={(value) => {
							onFieldChange(filter.name, value);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default FilterDropdown;
