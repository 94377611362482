import { useMemo } from 'react';
import moment from 'moment';

// components
import DateRangePicker from '../../common/daterangepicker/DateRangePicker';

export const getFilterDateValue = (label, range) => ({
	label,
	GTE: range[0],
	LTE: range[1],
});

const FilterDate = ({ selectedFilters, onFieldChange, filter }) => {
	const existingValue = useMemo(() => selectedFilters[filter.name] ?? null, [selectedFilters, filter.name]);
	return (
		<div className='space-y-0 w-full'>
			<div className='form-group'>
				<div className='form-input'>
					<DateRangePicker
						iconOnLeft
						defaultValue={
							existingValue && {
								start: existingValue.GTE,
								end: existingValue.LTE,
                                label: existingValue.label,
							}
						}
						name={filter.name}
						includeTime
						onDatesChange={(start, end, label) => {
							onFieldChange(filter.name, {
								GTE: start,
								LTE: end,
                                label, 
							});
						}}
						maxDate={moment().format('MM/DD/YYYY')}
                        drop="auto"
					/>
				</div>
			</div>
		</div>
	);
};

export default FilterDate;
