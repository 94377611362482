import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { BaseContext, getDescriptionForRule } from '../../helpers/common';
import { serverFetch } from '../../helpers/server';
import moment from 'moment';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';

function CommissionEventLog() {
    const { id } = useParams();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [eventLog, setEventLog] = useState({});
    const [appliedRule, setAppliedRule] = useState(null);
    const [commissionDeal, setCommissionDeal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showCalculationInputs, setShowCalculationInputs] = useState(false);
    const [showAppliedRule, setShowAppliedRule] = useState(false);

    useEffect(() => {
        setPageTitle('Commission Event Log Details');
    }, []);

    const fetchEventLogData = () => {
        serverFetch(getApiUrl(`/commission/event_log/${id}`)).then((res) => {
            if (res) {
                setEventLog(res);
                if (res.calculation_result.applied_rule) {
                    fetchAppliedRule(res.calculation_result.applied_rule)
                }
                // if (res.commission_deal_id) {
                //     fetchCommissionDeal(res.commission_deal_id)
                // }
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        fetchEventLogData();
    }, []);

    const fetchAppliedRule = (ruleId) => {
        serverFetch(getApiUrl(`/commission/rule/${ruleId}`)).then((res) => {
            if (res) {
                setAppliedRule(res);
            }
        });
    };

    const fetchCommissionDeal = (commissionDealId) => {
        serverFetch(getApiUrl(`/commission/deal/${commissionDealId}`)).then((res) => {
            if (res) {
                setCommissionDeal(res);
            }
        });
    }

    const getEventLabel = (event) => {
        const events = {
            CONTRACT_EXECUTED: 'Contract Executed',
            FIRST_INVOICE_PAID: 'First Invoice Paid',
            INVOICE_PAID: 'Invoice Paid',
        };
        return events[event] || event;
    };
    const renderStatusBadge = (type) => {
        const styles = {
            CONTRACT_EXECUTED: 'bg-blue-100 text-blue-800',
            FIRST_INVOICE_PAID: 'bg-green-100 text-green-800',
            INVOICE_PAID: 'bg-purple-100 text-purple-800',
        };
        return (
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${styles[type] || 'bg-gray-100 text-gray-800'}`}>
                {getEventLabel(type)}
            </span>
        );
    };

    const renderCommissionDetails = () => {
        if (!eventLog.calculation_result) {
            return null;
        }
        const result = eventLog.calculation_result || {};
        return (
			<div className='bg-white shadow overflow-hidden sm:rounded-lg'>
				<div className='px-6 py-5 border-b border-gray-200 bg-gray-50'>
					<div className='flex items-center justify-between'>
						<div>
							<p className='mt-1 text-sm text-gray-500'>
								{moment(eventLog.created_at).format('MMMM D hh:mma, YYYY')}
							</p>
						</div>
						<div className='flex items-center space-x-6'>
							<div className='text-right'>
								<p className='text-sm font-medium text-gray-500'>Commission Rate</p>
								<div className='flex items-center'>
									<p className='text-2xl font-semibold text-gray-900'>
										{(result.commission_calculation.rate * 100).toFixed(1)}%
									</p>
									{/*{result.is_retroactive && (*/}
									{/*    <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'>*/}
									{/*        Retroactive*/}
									{/*    </span>*/}
									{/*)}*/}
								</div>
							</div>
							<div className='text-right'>
								<p className='text-sm font-medium text-gray-500'>Commission Amount</p>
								<p className='text-2xl font-semibold text-gray-900'>
									${Number(eventLog.commission_amount).toLocaleString()}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='px-6 py-6'>
					<div className='grid grid-cols-3 gap-6'>
						<div className='col-span-3 sm:col-span-1'>
							<div className='space-y-4'>
								<div>
									<dt className='text-sm font-medium text-gray-500'>Commissionable Event</dt>
									<dd className='mt-1 text-sm text-gray-900'>
										<span className='inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800'>
											{getEventLabel(eventLog.event_type)}
										</span>
									</dd>
								</div>
								<div>
									<dt className='text-sm font-medium text-gray-500'>Event Base Type</dt>
									<dd className='mt-1 text-sm text-gray-900'>{eventLog.event_value_base_type}</dd>
								</div>
								<div>
									<dt className='text-sm font-medium text-gray-500'>Event Value</dt>
									<dd className='mt-1 text-sm text-gray-900'>
										${Number(eventLog.commission_event_value).toLocaleString()}
									</dd>
								</div>
								<div>
									<dt className='text-sm font-medium text-gray-500'>Commission Deal ID</dt>
									<dd className='mt-1 text-sm text-gray-900'>{eventLog.commission_deal_id}</dd>
								</div>
							</div>
						</div>

						<div className='col-span-3 sm:col-span-2'>
							<div className='grid grid-cols-2 gap-6'>
								<div>
									<dt className='text-sm font-medium text-gray-500'>Incremental Quota Achievement</dt>
									<dd className='mt-1 text-2xl font-semibold text-gray-900'>
										${result.incremental_quota_achievement.toLocaleString()}
									</dd>
								</div>
								<div>
									<dt className='text-sm font-medium text-gray-500'>New Total Quota Achievement</dt>
									<dd className='mt-1 text-2xl font-semibold text-gray-900'>
										${result.total_quota_achievement.toLocaleString()}
									</dd>
								</div>
							</div>
						</div>
					</div>
				</div>

				{eventLog.is_adjustment && (
					<div className='px-6 py-4 border-t border-gray-200 bg-yellow-50'>
						<div className='flex'>
							<div className='ml-3'>
								<h3 className='text-sm font-medium text-yellow-800'>Adjustment Entry</h3>
								<div className='mt-2 text-sm text-yellow-700'>
									<p>{eventLog.adjustment_reason}</p>
									{eventLog.original_event_id && (
										<p className='mt-1'>Original Event ID: {eventLog.original_event_id}</p>
									)}
								</div>
							</div>
						</div>
					</div>
				)}

				{/*{result.needs_retroactive && (*/}
				{/*	<div className='px-6 py-4 border-t border-gray-200 bg-blue-50'>*/}
				{/*		<div className='flex'>*/}
				{/*			<div className='ml-3'>*/}
				{/*				<h3 className='text-sm font-medium text-blue-800'>Needs Retroactive Calculation</h3>*/}
				{/*				{result.needs_retroactive_reason && (*/}
				{/*					<div className='mt-2 text-sm text-blue-700'>*/}
				{/*						<p>{result.needs_retroactive_reason}</p>*/}
				{/*					</div>*/}
				{/*				)}*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*)}*/}
			</div>
		);
    };

    const renderExpandableSection = (title, content, isExpanded, setExpanded) => (
        <div className="bg-white shadow sm:rounded-lg overflow-hidden">
            <button
                onClick={() => setExpanded(!isExpanded)}
                className="w-full px-6 py-4 border-b border-gray-200 bg-gray-50 text-left flex justify-between items-center hover:bg-gray-100 transition-colors"
            >
                <h3 className="text-sm font-medium text-gray-900">{title}</h3>
                <svg
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
            {isExpanded && (
                <div className="px-6 py-5">
                    {content}
                </div>
            )}
        </div>
    );

    const renderCalculationInputs = (inputs) => {
        if (!inputs) return null;
        return (
            <div className="grid grid-cols-2 gap-6 bg-white rounded-lg border border-gray-200 p-6">
                {Object.entries(inputs).map(([key, value]) => (
                    <div key={key} className="flex flex-col">
                        <dt className="text-sm font-medium text-gray-500">
                            {key.replace(/([A-Z])/g, ' $1').trim()}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {typeof value === 'number' ? `$${value.toLocaleString()}` :
                                Array.isArray(value) ? value.join(', ') : value}
                        </dd>
                    </div>
                ))}
            </div>
        );
    };

    const renderAppliedRule = () => {
        if (!appliedRule) return null;
        return (
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="px-6 py-5 border-b border-gray-200 bg-gray-50">
                    <h3 className="text-sm font-medium text-gray-900">Rule Details</h3>
                </div>
                <div className="px-6 py-5">
                    <div className="space-y-6">
                        <div>
                            <dt className="text-sm font-medium text-gray-500">Condition</dt>
                            <dd className="mt-1 text-sm text-gray-900">{getDescriptionForRule(appliedRule.rule)}</dd>
                        </div>
                        <div>
                            <dt className="text-sm font-medium text-gray-500">Commission Rate</dt>
                            <dd className="mt-1">
                                <span className="text-2xl font-semibold text-gray-900">
                                    {(appliedRule.commission_rate * 100).toFixed(1)}%
                                </span>
                                {appliedRule.is_retroactive && (
                                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                        Retroactive
                                    </span>
                                )}
                            </dd>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <BaseContainer>
            <ContentContainer>
                <Section loading={loading}>
                    <div className="max-w-5xl mx-auto space-y-6">
                        {/* Header */}
                        <div className="md:flex md:items-center md:justify-between">
                            <div className="flex-1 min-w-0">
                                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                    Commission Event Log
                                </h2>
                            </div>
                        </div>

                        {/* Commission Details */}
                        {renderCommissionDetails()}

                        {/* Calculation Inputs (Expandable) */}
                        {eventLog.calculation_input && renderExpandableSection(
                            'Calculation Inputs',
                            renderCalculationInputs(eventLog.calculation_input),
                            showCalculationInputs,
                            setShowCalculationInputs
                        )}

                        {/* Applied Rule (Expandable) */}
                        {appliedRule && renderExpandableSection(
                            'Applied Commission Rule',
                            renderAppliedRule(),
                            showAppliedRule,
                            setShowAppliedRule
                        )}
                    </div>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CommissionEventLog;
