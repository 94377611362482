import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { BaseContext, getDescriptionForRule } from '../../helpers/common';
import { serverFetch } from '../../helpers/server';
import moment from 'moment';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';

function CommissionPlan() {
	const { id } = useParams();
	const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [plan, setPlan] = useState({});
	const [loading, setLoading] = useState(true);

	const fetchCommissionPlanData = () => {
		serverFetch(getApiUrl(`/commission/plan/${id}`)).then((res) => {
			if (res) {
				setPlan(res);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		fetchCommissionPlanData();
	}, []);

	const getPlanTypeLabel = (type) => {
		const types = {
			FLAT_PERCENTAGE: 'Flat Percentage',
			QUOTA_BASED: 'Quota Based',
			VALUE_BASED: 'Value Based',
		};
		return types[type] || type;
	};

	const getPayoutEventLabel = (event) => {
		const events = {
			CONTRACT_EXECUTED: 'On Contract Execution',
			FIRST_INVOICE_PAID: 'On First Invoice Paid',
			INVOICE_PAID: 'On Invoice Paid',
		};
		return events[event] || event;
	};

	const renderRules = (rules) => {
		if (!rules) return null;
		return rules.map((rule, index) => (
			<div key={index} className='mb-4 bg-slate-50 p-4 rounded-lg border border-slate-200'>
				<div className='text-sm text-gray-500 mb-2'>{`Rule ${index + 1}`}</div>
				<div className='grid grid-cols-2 gap-4'>
					<div>
						<div className='text-sm font-medium text-gray-700'>Condition</div>
						<div className='text-sm text-gray-600'>{getDescriptionForRule(rule.rule)}</div>
					</div>
					<div>
						<div className='text-sm font-medium text-gray-700'>Commission Rate</div>
						<div className='text-sm text-gray-600'>{rule.commission_rate * 100}%</div>
						{rule.is_retroactive && (
							<div className='text-xs text-blue-600 mt-1'>Applies retroactively to previous tiers</div>
						)}
					</div>
				</div>
			</div>
		));
	};

	const getAcceleratorTypeLabel = (type) => {
		const types = {
			ADDITIVE: 'Additive',
			MULTIPLICATIVE: 'Multiplicative',
		};
		return types[type] || type;
	};

	const renderAccelerators = (accelerators) => {
		if (!accelerators?.length) return null;

		return (
			<div>
				<h3 className='text-lg font-medium text-gray-900 mb-2'>Accelerators</h3>
				<div className='text-sm text-gray-600 mb-4'>
					Type: {getAcceleratorTypeLabel(accelerators[0]?.accelerator_type)}
				</div>
				{accelerators.map((accelerator, index) => (
					<div key={index} className='mb-4 bg-slate-50 p-4 rounded-lg border border-slate-200'>
						<div className='text-sm text-gray-500 mb-2'>{`Accelerator ${index + 1}`}</div>
						<div className='grid grid-cols-2 gap-4'>
							<div>
								<div className='text-sm font-medium text-gray-700'>Condition</div>
								<div className='text-sm text-gray-600'>{getDescriptionForRule(accelerator.rule)}</div>
							</div>
							<div>
								<div className='text-sm font-medium text-gray-700'>Rate</div>
								<div className='text-sm text-gray-600'>
									{accelerator.accelerator_type === 'ADDITIVE'
										? accelerator.accelerator_rate * 100 + '%'
										: accelerator.accelerator_rate}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<Section title='Commission Plan Details' loading={loading}>
					<div className='space-y-6'>
						<div className='grid grid-cols-2 gap-6'>
							<div>
								<div className='text-sm font-medium text-gray-700'>Plan Name</div>
								<div className='text-sm text-gray-600'>{plan.name}</div>
							</div>
							<div>
								<div className='text-sm font-medium text-gray-700'>Created Date</div>
								<div className='text-sm text-gray-600'>
									{moment(plan.created_at).format('MMM D, YYYY')}
								</div>
							</div>
							<div>
								<div className='text-sm font-medium text-gray-700'>Plan Type</div>
								<div className='text-sm text-gray-600'>{getPlanTypeLabel(plan.plan_type)}</div>
							</div>
							<div>
								<div className='text-sm font-medium text-gray-700'>Payout Event</div>
								<div className='text-sm text-gray-600'>{getPayoutEventLabel(plan.payout_event)}</div>
							</div>
						</div>

						<div>
							<h3 className='text-lg font-medium text-gray-900 mb-4'>Commission Rules</h3>
							{renderRules(plan.rules)}
						</div>
					</div>
					{plan.accelerators?.length > 0 && (
						<div className='mt-6'>{renderAccelerators(plan.accelerators)}</div>
					)}
				</Section>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionPlan;
