import SubmitButton from '../components/common/buttons/SubmitButton';
import BaseForm from '../components/BaseForm';
import { Image } from 'react-bootstrap';
import Columns from "../components/Columns";
import Link from "../components/Link";
import {currencyFormatFromPrice} from "./common";
import React from "react";
import moment from "moment";
const _ = require("lodash");

const featureMap = {
    "CRM": {
        key: "CRM",
        shortTitle: "CRM",
        title: "CRM",
        description: "The integration allows you to pull leads, deals, persons, contacts from the CRM tool.",
        pillClassName: "bg-purple-100 text-purple-700",
    },
    "E_SIGN_PROVIDER": {
        key: "E_SIGN_PROVIDER",
        shortTitle: "E-Sign",
        title: "E-Signature Provider",
        description: "Use the e-signature provider to handle signature requests in Maple.",
        pillClassName: "bg-yellow-100 text-yellow-700",
    },
    "TAX_CALC": {
        key: "TAX_CALC",
        shortTitle: "Tax Calc",
        title: "Tax Calculation",
        description: "Use the API to calculate taxes on your invoices in Maple.",
        pillClassName: "bg-indigo-100 text-indigo-700",
    },
    "ACCOUNTING": {
        key: "ACCOUNTING",
        shortTitle: "Accounting",
        title: "Accounting",
        description: "Push customers, invoices, payments, refunds from Maple into your accounting software.",
        pillClassName: "bg-pink-100 text-pink-700",
    },
    "PAYMENT_PROVIDER": {
        key: "PAYMENT_PROVIDER",
        shortTitle: "Payment Provider",
        title: "Payment Provider",
        description: "Store payment information and process payment transactions securely on behalf of the company.",
        pillClassName: "bg-green-100 text-green-700",
    },
    "NOTIFICATIONS": {
        key: "NOTIFICATIONS",
        shortTitle: "Notifications",
        title: "Notifications",
        description: "Use the notification provider to configure and receive important alerts.",
        pillClassName: "bg-orange-100 text-orange-700",
    },
    "CUSTOM_EMAIL_DOMAIN": {
        key: "CUSTOM_EMAIL_DOMAIN",
        shortTitle: "Custom e-mail domain",
        title: "Custom e-mail domain",
        description: "Use the notification provider to configure your domain so that emails will go via your domain",
        pillClassName: "bg-orange-100 text-orange-700",
    },
    "EMAIL_PROVIDER": {
        key: "EMAIL_PROVIDER",
        shortTitle: "Email Provider",
        title: "Email Provider",
        description: "Use the notification provider to configure your domain so that emails will go via your email provider",
        pillClassName: "bg-orange-100 text-orange-700",
    },
    "DATA_EXPORT": {
        key: "DATA_EXPORT",
        shortTitle: "Data Export",
        title: "Data Export",
        description: "Use the data export tag to export data from Maple to your data warehouse",
        pillClassName: "bg-yellow-100 text-black-700",
    }
}

export function getIntegrationInterfaceForKey(key) {
    if (key === "intercom") {
        return new IntegrationInterface();
    } else if (key === "pipedrive") {
        return new PipedriveIntegrationInterface();
    } else if (key === "anrok") {
        return new AnrokIntegrationInterface();
    } else if (key === "hubspot") {
        return new HubSpotIntegrationInterface();
    } else if (key === "stripe") {
        return new StripeIntegrationInterface();
    } else if (key === "xodo_sign") {
        return new XodoSignIntegrationInterface();
    } else if (key === "avalara") {
        return new AvalaraIntegrationInterface();
    } else if (key === "docusign") {
        return new DocuSignIntegrationInterface();
    } else if (key === "dropbox_sign") {
        return new DropboxSignIntegrationInterface();
    } else if (key === "attio") {
        return new AttioIntegrationInterface();
    } else if (key === "mailchimp") {
        return new MailchimpIntegrationInterface();
    } else if (key === "quickbooks") {
        return new QuickbooksIntegrationInterface();
    } else if (key === "taxjar") {
        return new TaxJarIntegrationInterface();
    } else if (key === "xero") {
        return new XeroIntegrationInterface();
    } else if (key === "authorize_net") {
        return new AuthorizeNetIntegrationInterface();
    } else if (key === "salesforce") {
        return new SalesforceIntegrationInterface();
    } else if (key === "razorpay") {
        return new RazorPayIntegrationInterface();
    } else if (key === "manual_tax") {
        return new ManualTaxIntegrationInterface();
    } else if (key === "slack") {
        return new SlackIntegrationInterface();
    } else if (key === "snowflake") {
        return new SnowflakeIntegrationInterface();
    } else {
        return new IntegrationInterface();
    }
}

export function isFieldMappingAvailable(integration, feature) {
    if (!integration) {
        return false
    }
    return !!_.includes(["CRM"], feature);
}


export function isFieldMappingEnabled(integration, feature) {
    if (!integration) {
        return false
    }
    if (!_.includes(["CRM", "ACCOUNTING"], feature)) {
        return false;
    }
    if (_.includes(integration.enabled_features, feature)) {
        return true;
    }
    return false;
}

export function isActionMappingAvailable(integration, feature) {
    if (!integration) {
        return false
    }
    return !!_.includes(["CRM"], feature);
}

export function isActionMappingEnabled(integration, feature) {
    if (!integration) {
        return false
    }
    if (!_.includes(["CRM"], feature)) {
        return false;
    }
    if (_.includes(integration.enabled_features, feature)) {
        return true;
    }
    return false;
}

export function isSyncEnabled(integration, feature) {
    if (!integration) {
        return false
    }
    if (_.includes(["ACCOUNTING", "CRM"], feature) && _.includes(integration.enabled_features, feature)) {
        return true;
    }
    return false;
}

export function isIntegrationLogsAvailable(integration) {
    if (!integration) {
        return false
    }
    if (!_.includes(integration.available_features, "DATA_EXPORT")) {
        return true;
    }
    return false;
}

export function isAccountingEnabled(integration) {
    if (integration && _.includes(integration.enabled_features, "ACCOUNTING")) {
        return true;
    }
    return false;
}

export function isPaymentProviderEnabled(integration) {
    if (integration && _.includes(integration.enabled_features, "PAYMENT_PROVIDER")) {
        return true;
    }
    return false;
}

export function getFeatureDescription(feature) {
    return featureMap[feature] || {};
}

export function getFeatureDescriptions(integration) {
    return _.map(integration.available_features, (f) => getFeatureDescription(f));
}

export function getExternalCustomerReferenceURL(customer) {
    if (customer.imported_from === "STRIPE") {
        return `https://dashboard.stripe.com/customers/${customer.import_ref}`
    }
}

export function getExternalProductReferenceURL(product) {
    if (product.imported_from === "STRIPE") {
        return `https://dashboard.stripe.com/products/${product.import_ref}`
    }
}

export function getExternalProductPricingReferenceURL(price) {
    if (price.imported_from === "STRIPE") {
        return `https://dashboard.stripe.com/price/${price.import_ref}`
    }
}

export function getMappingFromFields(fields, isRemoteToMaple) {
    const result = {};
    _.each(fields, (f) => {
        if (isRemoteToMaple) {
            let value = f.selected_field && f.selected_field.key;
            if (!value && !f.editable && f.relevant_maple_fields && f.relevant_maple_fields.length > 0) {
                value = f.relevant_maple_fields[0].key
            }
            result[f.remote_field.key] = value;
        } else {
            let value = f.selected_field && f.selected_field.key;
            if (!value && !f.editable && f.relevant_remote_fields && f.relevant_remote_fields.length > 0) {
                value = f.relevant_remote_fields[0].key
            }
            result[f.maple_field.key] = value
        }
    })
    return result;
}

export function processEntityFieldsFromFields(fields, isRemoteToMaple) {
    const result = [];
    _.each(fields, (f) => {
        let entry = {}
        if (isRemoteToMaple) {
            entry.source_field = f.remote_field
            entry.selected_field = f.selected_field
            // if (!entry.selected_field) {
            //     // Check if is not editable and there is only one relevant field
            //     if (!f.editable && f.relevant_maple_fields && f.relevant_maple_fields.length === 1) {
            //         entry.selected_field = f.relevant_maple_fields[0]
            //     }
            // }
            entry.available_fields = f.relevant_maple_fields
            entry.editable = f.editable
            entry.ignorable = f.ignorable
            entry.is_autocomputed = f.is_autocomputed
        } else {
            entry.source_field = f.maple_field
            entry.selected_field = f.selected_field
            // if (!entry.selected_field) {
            //     // Check if is not editable and there is only one relevant field
            //     if (!f.editable && f.relevant_remote_fields && f.relevant_remote_fields.length === 1) {
            //         entry.selected_field = f.relevant_remote_fields[0]
            //     }
            // }
            entry.available_fields = f.relevant_remote_fields
            entry.editable = f.editable
            entry.ignorable = f.ignorable
            entry.is_autocomputed = f.is_autocomputed
        }
        result.push(entry)
    })
    return result;
}

export function renderMapleEntity(reference, entity, getCompanySpecificUrl) {
    if (entity === "CUSTOMER") {
        return (
            <>
                {
                    reference.customer ?
                        <Columns.CustomerName hideAvatar customer={reference.customer} />
                        : <span>{ reference.id }</span>
                }
            </>
        )
    } else if (entity === "INVOICE") {
        return (
            <>
                {
                    reference.invoice &&
                    <div className="flex flex-col">
                        <Link href={getCompanySpecificUrl(`/invoice/${reference.invoice.uuid}`)}>{ reference.invoice.number }</Link>
                        <span className="text-sm text-gray-400">{moment(reference.invoice.invoice_date).format("MMM D, YYYY")} - { currencyFormatFromPrice(reference.invoice.total_amount) }</span>
                    </div>
                }
            </>
        )
    } else if (entity === "PAYMENT") {
        return (
            <>
                {
                    reference.payment &&
                    <Link href={getCompanySpecificUrl(`/payment/${reference.payment.id}`)}>{ currencyFormatFromPrice(reference.payment.total) }</Link>
                }
            </>
        )
    } else if (entity === "PRODUCT_PRICING") {
        return (
            <>
                {
                    reference.product_pricing &&
                    <Link href={getCompanySpecificUrl(`/payment/${reference.payment.id}`)}>{ currencyFormatFromPrice(reference.payment.total) }</Link>
                }
            </>
        )
    }
}

export function renderRemoteData(row, integration) {
    if (!row.remote_data) {
        return null;
    }
    let title = ""
    if (row.reference_type === "INVOICE") {
        title = row.remote_data.number
    } else if (row.reference_type === "CUSTOMER") {
        title = row.remote_data.name
    }
    let additionalDescription = "";
    if (row.additional_data && row.additional_data[integration.key]) {
        const additionalData = row.additional_data[integration.key];
        if (additionalData.currency) {
            additionalDescription = ", " + additionalData.currency;
        }
    }
    return (
        <div>
            <div className="flex flex-col justify-center">
                <div className="flex flex-row gap-1">
                    <span className="font-medium text-gray-700">{ title } <span className="font-normal text-gray-400 break-all">({ row.remote_data.id }{ additionalDescription })</span></span>
                </div>
                <span className="font-medium text-gray-400">{ row.remote_data.email }</span>
            </div>
        </div>
    )
}

class IntegrationInterface {
    getWebsiteUrl() { return null }
    getDescription() {
        return ""
    }
    getWelcomePageContent() {
        return (
            <p/>
        )
    }
    getApiInstructions(updateApiKey, initialFields) {
        return (
            <div>
                <BaseForm initialFormFields={initialFields} onSubmit={updateApiKey}>
                    <p>Please refer to the application&#39;s documentation for the instruction on obtaining the api key.</p>
                    <BaseForm.Input type="text" name="api_key" label="API Key" required />
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class PipedriveIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.pipedrive.com" }
    getDescription() {
        return "Pipedrive is a cloud-based CRM that enables you to gain greater visibility into customers and prospects."
    }
    getWelcomePageContent() {
        return (
            <span>Pipedrive is a sales-focused customer relationship management tool for teams of all sizes. With 100,000+
                paying customers spanning across 179 countries, sales teams are drawn in by the CRM's simple yet powerful
                design that prioritizes usability above all else. When using Pipedrive, nothing falls through the cracks,
                allowing your team to spend less time filing and more time selling with a CRM software that is both
                agile and powerful.</span>
        )
    }
    getApiInstructions(updateApiKey) {
        return (
            <div>
                <BaseForm onSubmit={updateApiKey}>
                    <p>You can look at <a href="https://support.pipedrive.com/en/article/how-can-i-find-my-personal-api-key">this
                        article</a> to learn about where to find your personal API key.</p>
                    <p>If you ever need your personal API key, it can be found under <b>Settings > Personal preferences > API</b>.</p>
                    <Image className="instructions" src="/images/integrations/pipedrive-api.webp"/>
                    <br/><br/>
                    <BaseForm.Input type="text" name="api_key" label="API Key" required/>
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class AnrokIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.anrok.com/" }
    getDescription() {
        return "Anrok integrates with Maple to bring sales tax automation directly into your billing system, with no code required."
    }
    getWelcomePageContent() {
        return (
            <span>Anrok is the modern sales tax solution for the Internet era. Built specifically for SaaS businesses,
                Anrok unifies sales tax monitoring, calculation and remittance across your financial stack.</span>
        )
    }
}

class HubSpotIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.hubspot.com/" }
    getDescription() {
        return "";
    }
    getWelcomePageContent() {
        return (
            <span>HubSpot CRM enables you to kickstart your sales process automatically by tracking customer interactions
                from a variety of sources such as email, social media, and so on. This lightweight CRM gives you the
                latest information on deals won and lost, appointments scheduled, and contracts. With the easy-to-use
                HubSpot CRM, you’ll never have to manually update reports or wonder how your team
                is tracking toward quotas. Because you have more important things to do.</span>
        )
    }
}

class StripeIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.stripe.com/" }
    getDescription() {
        return "";
    }
    getWelcomePageContent() {
        return (
            <span>Stripe is a payment services provider that lets merchants accept credit and debit cards or other payments.
                Stripe payments are best suited for businesses that make most of their sales online, as most of its unique
                features are primarily geared toward online sales.</span>
        )
    }
}

class XodoSignIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.eversign.com/" }
    getDescription() {
        return "";
    }
    getWelcomePageContent() {
        return (
            <span>Xodo Sign (formerly eversign) is a cloud-based digital signature solution that caters to businesses of
                all sizes across various industry verticals. It enables users to prepare, deliver and sign legally binding
                documents online. Send, manage, and sign documents from anywhere, on any device, or automate electronic
                signatures using their API.</span>
        )
    }
}

class AvalaraIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.avalara.com/" }
    getDescription() {
        return "Avalara software automates tax compliance and can help improve accuracy while reducing costs.";
    }
    getWelcomePageContent() {
        return (
            <span>Avalara makes tax compliance faster, easier, more accurate, and more reliable for 30,000+ business and
                government customers in over 90 countries. Tax compliance automation software solutions from Avalara
                leverage 1,200+ signed partner integrations across leading ecommerce, ERP, and other billing systems to
                power tax calculations, document management, tax return filing, and tax content access. Visit avalara.com
                to improve your compliance journey.</span>
        )
    }
    getApiInstructions(updateApiKey, initialFields) {
        return (
            <div>
                <BaseForm initialFormFields={initialFields} onSubmit={updateApiKey}>
                    <BaseForm.Input
                        type="text" name="api_id" label="Account ID" required
                    />
                    <BaseForm.Input
                        type="text" name="api_key" label="License Key" required
                    />
                    <br/>
                    <p className="mb-3">Please refer to <a href="https://knowledge.avalara.com/bundle/dqa1657870670369_dqa1657870670369/page/License_keys.html">this
                        article</a> to learn about where to find your Account ID and License Key.</p>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class DocuSignIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.docusign.com/" }
    getDescription() {
        return "DocuSign is an electronic signature app that enables you to sign and send documents online.";
    }
    getWelcomePageContent() {
        return (
            <span>More than a million customers and a billion users trust DocuSign with their critical and essential agreements.
                DocuSign helps organizations connect and automate how they navigate their systems of agreement. As part
                of its industry leading product lineup, DocuSign offers eSignature, the world's #1 way to sign
                electronically on practically any device, from almost anywhere, at any time.</span>
        )
    }
}

class AttioIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.attio.com/" }
    getDescription() {
        return "Attio is a cloud-based CRM that enables you to gain greater visibility into customers and prospects.";
    }
    getWelcomePageContent() {
        return (
            <span>Powerful, flexible and data-driven, Attio makes it easy to build the exact CRM your business needs.</span>
        )
    }
}

class DropboxSignIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.hellosign.com/" }
    getDescription() {
        return "Dropbox Sign is the easiest way to send, receive and manage legally binding electronic signatures.";
    }
    getWelcomePageContent() {
        return (
            <span>Dropbox Sign makes agreements easy with simple and secure eSignatures. With Dropbox Sign, anyone can
                set up a document for signature, Sign, and track the agreement process for important documents. Dropbox
                Sign works wherever you are, so any Dropbox user can send a document out for signature without the
                hassle of opening a separate app. Customers all over the world trust the Dropbox Sign platform—which
                includes eSignature, mobile-friendly forms, and electronic fax solutions with Dropbox Sign, Dropbox
                Forms, and Dropbox Fax—to automate and manage their most important business agreements.</span>
        )
    }
}

class MailchimpIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.mailchimp.com/" }
    getDescription() {
        return "Mailchimp is a leading all-in-one marketing platform providing small and large businesses powerful tools to engage with customers.";
    }
    getWelcomePageContent() {
        return (
            <span>Turn clustomers into customers. Personalize every email for every customer using real-time behavioral
                data and automated triggers with Intuit Mailchimp, the #1 email marketing and automation platform.</span>
        )
    }
}

class QuickbooksIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.quickbooks.com/" }
    getDescription() {
        return "QuickBooks is accounting software designed for keeping track of income and expenses.";
    }
    getWelcomePageContent() {
        return (
            <span>QuickBooks is the most popular small business accounting software used to manage income and expenses
                and keep track of financial health. You can invoice customers, pay bills, generate reports, and prepare
                taxes. The QuickBooks product line includes several solutions to support different business needs,
                including QuickBooks Online, QuickBooks Desktop, QuickBooks Payroll, QuickBooks Time, and QuickBooks
                Checking.</span>
        )
    }
}

class SlackIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() {
        return "https://www.slack.com/"
    }

    getDescription() {
        return "Stay on top of your billing and contracts with Maple’s Slack integration."
    }

    getWelcomePageContent() {
        return (
            <div className="flex flex-col">
                <span>Get real-time notifications directly in Slack for important billing events, so you can take immediate action or simply stay informed.</span><br/>
                <span>Seamless integration: Connect your Maple account with Slack in just a few clicks and choose which notifications to receive and where they should be sent within your Slack workspace.</span><br/>
                <span>Simplify your workflow and never miss a critical update again. Maple keeps you connected, informed, and efficient—all from the convenience of your favourite Slack channels!</span>
            </div>
        )
    }
}

class SnowflakeIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() {
        return "https://www.snowflake.com/"
    }

    getDescription() {
        return "Stay on top of your billing and contracts with Maple’s Slack integration."
    }

    getWelcomePageContent() {
        return (
            <div className="flex flex-col">
                <span>Get real-time notifications directly in Slack for important billing events, so you can take immediate action or simply stay informed.</span><br/>
                <span>Seamless integration: Connect your Maple account with Slack in just a few clicks and choose which notifications to receive and where they should be sent within your Slack workspace.</span><br/>
                <span>Simplify your workflow and never miss a critical update again. Maple keeps you connected, informed, and efficient—all from the convenience of your favourite Slack channels!</span>
            </div>
        )
    }
}

class TaxJarIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() {
        return "https://www.taxjar.com/"
    }

    getDescription() {
        return "TaxJar is a modern cloud platform that automates sales tax compliance";
    }
    getWelcomePageContent() {
        return (
            <div className="flex flex-col">
                <span>TaxJar was founded in 2013 to help e-commerce companies automate the entire sales tax lifecycle from
                    calculations and nexus tracking to reporting and filing. After growing to over 25,000 customers,
                    TaxJar was acquired by Stripe in 2021 to accelerate the future of commerce and compliance.</span><br/>
                <span>Today, new customers can manage their global sales tax compliance through Stripe Tax. With Stripe Tax,
                    businesses will not only be able to calculate and file sales tax globally, but also have access to an
                    array of other Stripe products like fraud protection, payments, billing, invoicing, and more.To learn
                    how Stripe Tax might fit your needs, we encourage you to explore Stripe Tax and connect with the Stripe
                    sales team here.</span><br/>
                <span>Existing TaxJar customers can continue to use the TaxJar platform.</span>
            </div>
        )
    }
}

class XeroIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.xero.com/" }
    getDescription() {
        return "Xero is entirely cloud-based accounting software for small businesses.";
    }
    getWelcomePageContent() {
        return (
            <span>Xero is a global small business platform with 3.5 million subscribers which includes a core accounting
                solution, payroll, workforce management, expenses and projects. Xero also has an extensive ecosystem of
                connected apps and connections to banks and other financial institutions helping small businesses access
                a range of solutions from within Xero’s open platform to help them run their business and manage their
                finances.</span>
        )
    }
}

class AuthorizeNetIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.authorize.net" }
    getDescription() {
        return "Authorize.net helps makes it simple to accept electronic and credit card payments in person, online or over the phone."
    }
    getWelcomePageContent() {
        return (
            <span>Authorize.net helps makes it simple to accept electronic and credit card payments in person, online
                or over the phone. Get solutions that go to work for your business and let you focus on what you love
                best. <br/><br/>Since 1996, Authorize.net has been a leading provider of payment gateway services, managing the
                submission of billions of transactions to processing networks on behalf of merchant customers.
                Authorize.net is a wholly owned subsidiary of Visa (NYSE: V). Authorize.net services are sold through
                a network of reseller partners including Independent Sales Organizations (ISOs), Merchant Service
                Providers (MSPs) and financial institutions that offer Authorize.net payment services to their
                merchant customers.</span>
        )
    }
    getApiInstructions(updateApiKey, initialFields) {
        return (
            <div>
                <BaseForm initialFormFields={initialFields} onSubmit={updateApiKey}>
                    <div className="text-base font-semibold mb-2">Authorize.net Settings</div>
                    <Image className="instructions" src="/images/integrations/authorize_net_instructions_1.png"/>
                    <br/>
                    <BaseForm.Input
                        type="text" name="api_key" label="API Key" required
                        description={
                            <span className="text-sm text-gray-700">API key allows Maple to make background payment transactions on your behalf. You can find the
                                API key in Settings -> "API Credentials & Key" -> "New Transaction Key" under "Create New Key(s)".</span>
                        }
                    />
                    <BaseForm.Input
                        type="text" name="api_id" label="Login ID" required
                        description={
                            <span className="text-sm text-gray-700">Login ID allows Maple to make background payment transactions on your behalf. You can find the
                                API key in Settings -> "API Credentials & Key" -> "API Login ID".</span>
                        }
                    />
                    <BaseForm.Input
                        type="text" name="client_secret" label="Public Client Key" required
                        description={
                            <span className="text-sm text-gray-700">Public client key allows Maple to capture payment information in a PCI-Compliant way. You can
                                find the public client key in Settings -> "Manage Public Client Key" -> "Create New Public Client Key".</span>
                        }
                    />
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class SalesforceIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.salesforce.com" }
    getDescription() {
        return "Salesforce is more than a way to manage your relationships with customers. It's a customer success platform."
    }
    getWelcomePageContent() {
        return (
            <div>
                <span>Salesforce is a company that makes cloud-based software designed to help businesses find more
                    prospects, close more deals, and wow customers with amazing service.</span><br/><br/>

                <span>Customer 360, our complete suite of products, unites your sales, service, marketing, commerce,
                    and IT teams with a single, shared view of customer information, helping you grow relationships
                    with customers and employees alike.</span>
            </div>
        )
    }
    getApiInstructions(updateApiKey) {
        return (
            <div>
                <BaseForm onSubmit={updateApiKey}>
                    <p>You can look at <a href="https://support.pipedrive.com/en/article/how-can-i-find-my-personal-api-key">this
                        article</a> to learn about where to find your personal API key.</p>
                    <p>If you ever need your personal API key, it can be found under <b>Settings > Personal preferences > API</b>.</p>
                    <Image className="instructions" src="/images/integrations/pipedrive-api.webp"/>
                    <br/><br/>
                    <BaseForm.Input type="text" name="api_key" label="API Key" required/>
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class RazorPayIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return "https://www.razorpay.com" }
    getDescription() {
        return "Razorpay Payments provide a range of products to accept payments and make payouts. It also offers solutions to add offers and assess risk associated with a customer order."
    }
    getWelcomePageContent() {
        return (
            <p>
                <div>Razorpay is the only payments solution in India that allows businesses to accept, process and
                disburse payments with its product suite. It gives you access to all payment modes including
                credit card, debit card, netbanking, UPI and popular wallets including JioMoney, Mobikwik,
                Airtel Money, FreeCharge, Ola Money and PayZapp.</div>
                <div className="mt-3">RazorpayX supercharges your business banking experience, bringing effectiveness, efficiency,
                and excellence to all financial processes. With RazorpayX, businesses can get access to
                fully-functional current accounts, supercharge their payouts and automate payroll compliance.</div>
                <div className="mt-3">Manage your marketplace, automate bank transfers, collect recurring payments, share invoices
                with customers and avail working capital loans - all from a single platform. Fast forward your
                business with Razorpay.</div>
            </p>
        )
    }
    getApiInstructions(updateApiKey) {
        return (
            <div>
                <BaseForm onSubmit={updateApiKey}>
                    <p className="mb-3">You can refer to <a href="https://razorpay.com/docs/payments/dashboard/account-settings/api-keys/">this
                        article</a> to learn about generating Razorpay API keys.</p>

                    <BaseForm.Input type="text" name="api_id" label="Key ID" required/>
                    <BaseForm.Input type="text" name="api_key" label="Key Secret" required/>
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class ManualTaxIntegrationInterface extends IntegrationInterface {
    getWebsiteUrl() { return null }
    getDescription() {
        return "Manual tax calculator allows you to specify a set of rules, based on which tax rates will be applied to each invoice if applicable."
    }
    getWelcomePageContent() {
        return (
            <span>Setting up custom tax rules in a billing product is a crucial step for businesses to ensure compliance
                with various tax regulations and to optimize their financial processes. By configuring tailored tax
                settings, companies can accurately calculate and apply taxes based on specific criteria, such as
                location, product type, or customer category. This not only helps in avoiding legal complications but
                also enhances the efficiency of the billing system, providing a seamless experience for both the business
                and its customers. Understanding the intricacies of tax rules and integrating them into your billing
                software can significantly streamline operations and improve overall financial accuracy.</span>
        )
    }
}
