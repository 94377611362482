import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import React, {createContext, useContext, useEffect, useState} from 'react';
import { renderChildren, UserContext } from '../../helpers/common';
import {XMarkIcon} from "@heroicons/react/24/outline";
import XClose from '@untitled-ui/icons-react/build/esm/XClose';
import _ from "lodash"
import classnames from 'classnames';

export const ModalContext = createContext();

const BaseModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = (event) => {
        if (event) {
            event.stopPropagation()
        }
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <ModalContext.Provider value={{ handleClose }}>
            <Modal centered={props.centered} show={show} onHide={handleClose} size={props.size || "md"} className={classnames("max-w-full !inline-flex", props.className)}>
                { show && renderChildren(props) }
            </Modal>
        </ModalContext.Provider>
    );
}

const Header = (props) => {
    const userContext = useContext(UserContext);
    const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

    const { handleClose } = useContext(ModalContext);
    return (
        <Modal.Header className={classnames({ "rounded-t-xl": isMapleUser })}>
            <div className={classnames("flex gap-3 w-full", isMapleUser ? "items-start" : "items-center" )}>
                <div className="grow flex flex-col items-start gap-1">
                    { props.children }
                </div>
                <div className="flex h-7 items-center">
                    <button
                        type="button"
                        className="relative text-[#A4A7AE] hover:text-gray5 transition"
                        onClick={() => handleClose()}
                    >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        {isMapleUser ? <XClose width={24} height={24} /> : <XMarkIcon className="h-6 w-6" aria-hidden="true" />}
                    </button>
                </div>
            </div>
        </Modal.Header>
    );
}
BaseModal.Header = Header;

const Body = (props) => {
    return (
        <Modal.Body className={props.className}>
            <div className="notification-modal ahide noprint hide">
                <Alert variant="warning">
                    Warning message
                </Alert>
            </div>
            { props.children }
        </Modal.Body>
    );
}
BaseModal.Body = Body;

const Title = (props) => {
    const userContext = useContext(UserContext);
    const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

    return (
        <Modal.Title className={classnames({ "text-dark-gray font-semibold text-xl": isMapleUser })}>
            {isMapleUser ? props.children : <h4>{ props.children }</h4>}
        </Modal.Title>
    );
}
BaseModal.Title = Title;

const Subtitle = (props) => {
    return (
        <div className="text-subtitle text-sm leading-6">
            { props.children }
        </div>
    );
}
BaseModal.Subtitle = Subtitle;

const Footer = (props) => {
    const userContext = useContext(UserContext);
    const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

    return (
        <Modal.Footer className={classnames({ "rounded-b-xl": isMapleUser })}>
            { props.children }
        </Modal.Footer>
    );
}
BaseModal.Footer = Footer;

export default BaseModal;