import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import Columns from "../../components/Columns";
const _ = require('lodash');

function CommissionReports() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [sort, setSort] = useState('startDateDesc');
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle('Commission Reports');
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            query: {},
            sort_key: sort || 'startDateDesc',
            pagination: {
                from_key: restart ? null : fromKey,
                limit: limit,
            },
            include_meta: restart,
        };
        serverPost(getApiUrl('/commission/reports/find'), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setReports(results);
                } else {
                    setReports(_.concat(reports, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }

                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch();
    }, [sort]);

    const handleRowClick = (reportPeriodId) => {
        navigate(getCompanySpecificUrl(`/commissions/event_logs?report_period_id=${reportPeriodId}`));
    };

    return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={reports.length}
				>
					<Section title='Commission Reports' loading={loading}>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} />
							<MapleTable.Content>
								<thead>
									<tr>
										<MapleTable.TH>Sales Member</MapleTable.TH>
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='startDateAsc'
											sortKeyDown='startDateDesc'
											currentSort={sort}
										>
											Start Date
										</SortableTableHeader>
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='endDateAsc'
											sortKeyDown='endDateDesc'
											currentSort={sort}
										>
											End Date
										</SortableTableHeader>
										<MapleTable.TH>Total Commissions Achieved</MapleTable.TH>
										<MapleTable.TH>Total Quota Achieved</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{reports.map((report, i) => (
										<tr
											key={i}
											onClick={() => handleRowClick(report.id)}
											className='cursor-pointer hover:bg-gray-50'
										>
											<td>
												<Columns.CompanyUserName companyUser={report.sales_user.company_user} />
											</td>
											<td>{moment(report.start_date).format('MMM D, YYYY')}</td>
											<td>{moment(report.end_date).format('MMM D, YYYY')}</td>
											<td>${report.commission_achieved}</td>
											<td>${report.quota_achieved}</td>
											{/*<td>*/}
											{/*	<Columns.CompanyUserName companyUser={report.sales_user.company_user} />*/}
											{/*</td>*/}
										</tr>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionReports;