import '../App.scss';
import React, { useState, useEffect } from 'react';
import { getPropsForMetric, currencyFormatFromPrice } from '../helpers/common';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import ContentBox from './ContentBox';
const _ = require('lodash');

function TopBarSummary(props) {
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		setEntries(props.entries);
	}, [props.entries]);

	const renderValue = (value, props) => {
		if (props.type === 'currency') {
			return currencyFormatFromPrice(value);
		} else if (props.type === 'percent') {
			return value.toFixed(2) + '%';
		} else if (props.type === 'integer') {
			return Math.round(parseFloat(value));
		} else {
			return value.toFixed(2);
		}
	};

	const renderCardEntry = (entry, i) => {
		return (
			<ContentBox key={i} noBottomMargin overflowHidden>
				<ContentBox.Body className='!px-[20px] py-3 relative flex flex-col overflow-hidden bg-white'>
					{renderCardContent(entry)}
				</ContentBox.Body>
			</ContentBox>
		);
	};

	const renderCardContent = (entry) => {
		const props = getPropsForMetric(entry.name);
		return (
			<>
				<dt className='text-sm font-normal text-gray-900'>{props.title}</dt>
				<dd className='mt-1 grow flex items-center justify-between md:block lg:flex'>
					<div className='flex items-baseline gap-x-2 text-2xl font-semibold text-indigo-600 flex-wrap'>
						<div className=''>{!_.isNil(entry.value) && renderValue(entry.value, props)}</div>
						{!_.isNil(entry.previous_value) && (
							<span className='text-sm font-normal text-gray-500'>
								from {renderValue(entry.previous_value, props)}
							</span>
						)}
					</div>
					{!_.isNil(entry.change) && (
						<div
							className={classnames(
								entry.change_type === 'increase'
									? 'bg-green-100 text-green-800'
									: 'bg-red-100 text-red-800',
								'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
							)}
						>
							{entry.change >= 0 ? (
								<ArrowUpIcon
									className={classnames(
										'-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center',
										entry.change_type === 'increase' ? 'text-green-500' : 'text-red-500',
									)}
									aria-hidden='true'
								/>
							) : (
								<ArrowDownIcon
									className={classnames(
										'-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center',
										entry.change_type === 'increase' ? 'text-green-500' : 'text-red-500',
									)}
									aria-hidden='true'
								/>
							)}

							<span className='sr-only'>
								{' '}
								{entry.change_type === 'increase' ? 'Increased' : 'Decreased'} by{' '}
							</span>
							{entry.change.toFixed(2) + '%'}
						</div>
					)}
				</dd>
			</>
		);
	};

	return (
		<div className={props.className}>
			<h3 className='body1 gray3 leading-6'>Last 30 days</h3>
			<dl className='mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'>
				{entries.map((entry, i) => renderCardEntry(entry, i))}
			</dl>
		</div>
	);
}

export default TopBarSummary;
