import { useMemo } from 'react';

// components
import { Switch } from '@headlessui/react';

// styles
import classnames from 'classnames';

const FilterSwitch = ({ selectedFilters, onFieldChange, filter }) => {
	const value = useMemo(() => selectedFilters[filter.name], [selectedFilters, filter.name]);

	return (
		<div>
			<Switch.Group as='div' className='flex items-center'>
				<Switch
					checked={value}
					onChange={() => onFieldChange(filter.name, !value)}
					className={classnames(
						value ? 'bg-primary-updated' : 'bg-gray-200',
						'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0',
					)}
				>
					<span
						aria-hidden='true'
						className={classnames(
							value ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
						)}
					/>
				</Switch>
				<Switch.Label as='span' className='ml-2 text-sm'>
					<span className='font-normal text-dark-gray'>{filter.label}</span>
				</Switch.Label>
			</Switch.Group>
		</div>
	);
};

export default FilterSwitch;
