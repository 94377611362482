import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseContext } from '../../helpers/common';
import { Row } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { serverPatch, serverFetch } from '../../helpers/server';
import moment from 'moment';
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import Section from "../../components/Section";
import ContentBox from "../../components/ContentBox";
import BaseForm from "../../components/BaseForm";

function CommissionSettings() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPageTitle(`Commission Settings`);
        fetchSettings();
    }, []);

    const fetchSettings = () => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            if (res) {
                setSettings(res);
                setLoading(false);
            }
        });
    };

    const periodOptions = [
        { value: 'MONTHLY', label: 'Monthly' },
        { value: 'QUARTERLY', label: 'Quarterly' },
        { value: 'YEARLY', label: 'Yearly' },
    ];

    const getInitialFormFields = () => {
        return {
            quota_period: settings?.commission_quota_period_config?.period || '',
            quota_anchor_date: settings?.commission_quota_period_config?.anchor_date ?
                moment(settings.commission_quota_period_config.anchor_date).format('YYYY-MM-DD') : '',
            report_period: settings?.commission_report_period_config?.period || '',
            report_anchor_date: settings?.commission_report_period_config?.anchor_date ?
                moment(settings.commission_report_period_config.anchor_date).format('YYYY-MM-DD') : '',
        };
    };

    const updateSettings = async (data) => {
        const payload = {
            commission_config: {
                commission_quota_period_config: {
                    period_type: data.quota_period,
                    anchor_date: data.quota_anchor_date
                },
                commission_report_period_config: {
                    period_type: data.report_period,
                    anchor_date: data.report_anchor_date
                }
            }
        };

        const result = await serverPatch(getApiUrl("/settings"), payload);

        if (result) {
            setSettings(result);
            Notification.Success("Successfully updated commission settings");
        } else {
            Notification.Danger("Error saving commission settings");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Commission Settings">
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm onSubmit={updateSettings} initialFormFields={getInitialFormFields()}>
                                <div className="space-y-6">
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Quota Period Settings</h3>
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="quota_period"
                                                label="Default Quota Period"
                                                type="select"
                                                options={periodOptions}
                                                showSearch={false}
                                                required
                                                description="Default quota period for sales team members"
                                            />
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="quota_anchor_date"
                                                label="Quota Period Anchor Date"
                                                type="date"
                                                required
                                                description="Start date for calculating quota periods"
                                            />
                                        </Row>
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Report Period Settings</h3>
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="report_period"
                                                label="Default Report Period"
                                                type="select"
                                                options={periodOptions}
                                                showSearch={false}
                                                required
                                                description="Default commission report period for sales team members"
                                            />
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="report_anchor_date"
                                                label="Report Period Anchor Date"
                                                type="date"
                                                required
                                                description="Start date for calculating report periods"
                                            />
                                        </Row>
                                    </div>

                                    <Row>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary">
                                                Save Settings
                                            </button>
                                        </div>
                                    </Row>
                                </div>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CommissionSettings;