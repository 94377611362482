import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';
import classnames from 'classnames';

function ContentBox(props) {
	return (
		<div
			{...props}
			className={classnames(
				{
					'mb-[20px]': !props.noBottomMargin,
					'overflow-hidden': props.overflowHidden,
				},
				'border border-gray2 shadow-card rounded-xl bg-white',
				props.className,
			)}
		>
			{renderChildren(props)}
		</div>
	);
}

const Body = (props) => {
	return (
		<div {...props} className={classnames('p-4', { "pt-2": props.contentBoxHasTitle}, props.className)}>
			{renderChildren(props)}
		</div>
	);
};
ContentBox.Body = Body;

const Title = (props) => {
	return (
		<div {...props} className={classnames('px-[20px] pt-[20px] pb-[12px] text-2xl text-dark-gray font-semibold flex justify-between items-center', props.className)}>
			{renderChildren(props)}
			{props.right}
		</div>
	);
};
ContentBox.Title = Title;

export default ContentBox;
