import '../App.scss';
import React, {useContext} from 'react';
import {serverPost} from '../helpers/server';
import {BaseContext, renderDescriptionForItemPricing, renderTags} from '../helpers/common';
import {useTranslation} from 'react-i18next';
import Select from "react-select/async";
import classnames from "classnames";

const _ = require('lodash');

function ProductPricingInput(props) {
    const {t} = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);

    const asyncLoadPrices = async (query) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            search_query: query,
            query: props.extraQueryFilters && {
                ...props.extraQueryFilters
            },
            pagination: {
                limit: limit
            },
        }
        const [productPricingResult, bundlePricingResult] = await Promise.all([
            serverPost(getApiUrl("/product_pricings/autocomplete"), params),
            props.excludeBundlePricing ? null: serverPost(getApiUrl("/bundle_pricings/autocomplete"), params)
        ])
        let combinedResults = [];
        if (bundlePricingResult) {
            const allResults = bundlePricingResult.results || [];
            _.each(allResults, r => {
                r.isBundlePricing = true;
            })
            const groupedPrices = _.groupBy(allResults, (p) => "Bundles");
            const results = _.map(groupedPrices, (values, key) => {
                return {
                    label: key,
                    options: values
                };
            })
            combinedResults = results;
        }
        if (productPricingResult) {
            const allResults = productPricingResult.results || [];
            const groupedPrices = _.groupBy(allResults, (p) => p.product.name);
            const results = _.map(groupedPrices, (values, key) => {
                return {
                    label: key,
                    options: values
                };
            })
            combinedResults = _.concat(combinedResults, results);
        }
        return combinedResults;
    }

    const loadPrices = (query, callback) => {
        asyncLoadPrices(query).then(res => {
            if (props.allowCreation) {
                res.unshift({ __new__: true, value: "new", label: <div><span className="text-sm font-semibold">Create New Product Pricing</span></div> })
            }
            callback(res);
        })
    }

    const pricingLabel = (pricing) => {
        if (pricing.__new__) {
            return (
                <div className="flex flex-col text-sm">
                    <span className="font-semibold text-primary-updated-dark">+ Create New Product Pricing</span>
                </div>
            )
        } else if (pricing.isBundlePricing) {
            return (
                <div className="flex flex-col text-sm">
                    <span>{ pricing.name }</span>
                    <span className="caption text-gray-500">{ pricing.bundle_product_pricings.length } prices</span>
                </div>
            )
        } else {
            const ppLabel = pricing.name || pricing.description || "";
            const tags = _.uniq(_.concat(pricing.tags, ...pricing.product.tags));
            return (
                <div>
                    { !_.isEmpty(ppLabel) && <span>{ ppLabel }</span> }
                    <div>
                        {
                            _.map(pricing.product_metric_pricings, (pmp, i) =>
                                <React.Fragment key={i}>
                                    <span key={i}>{renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, props.billableItems)}</span>
                                    {
                                        tags &&
                                        <div className="mt-1">{ renderTags(tags) }</div>
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            )
        }

    }

    const groupLabel = (data) => (
        <div className="d-flex flex-row gap-3" style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <span className="flex-grow-1 body1 gray4" style={{ textTransform: "initial" }}>{data.label}</span>
            <span className="flex-grow-0 body1 gray4" style={{ textTransform: "initial", whiteSpace: "nowrap"}}>{data.options.length} Prices</span>
        </div>
    );

    const selectedProductPricingIds = _.map(props.selectedProductPricings, (pp) => pp.id);
    return (
        <div className="form-group">
            <span className="body2 ml-1">Add a Product Pricing</span>
            <div className="form-input">
                <Select
                    menuPlacement={props.menuPlacement || "top"}
                    components={{ DropdownIndicator: null }}
                    isMulti={false}
                    className={classnames("select-container", props.menuPlacement)}
                    classNames={{
                        menu: (state) => {
                            return state.menuPlacement === 'top' ? "!bottom-dropdown-spacing-rs" : "!top-dropdown-spacing-rs"
                        },
                    }}
                    loadOptions={loadPrices}
                    defaultOptions
                    classNamePrefix="select2"
                    getOptionLabel={pricingLabel}
                    getOptionValue={(c) => c.id}
                    value={null}
                    placeholder="Add a Product Pricing or a Bundle..."
                    onChange={(value, meta) => {
                        if (value.__new__) {
                            if (props.onCreateProductPricing) {
                                props.onCreateProductPricing();
                            }
                        } else if (value.isBundlePricing) {
                            props.setSelectedProductPricings(prevSelectedProductPricings => {
                                const newSelectedProductPricings = [...prevSelectedProductPricings];
                                _.each(value.bundle_product_pricings, bpp => {
                                    newSelectedProductPricings.push(bpp.product_pricing);
                                });
                                return newSelectedProductPricings;
                            })
                        } else {
                            props.setSelectedProductPricings(prevSelectedProductPricings => {
                                const newSelectedProductPricings = [...prevSelectedProductPricings];
                                newSelectedProductPricings.push(value);
                                return newSelectedProductPricings;
                            })
                        }
                    }}
                    isOptionDisabled={(option) => {
                        // if (option.type === "ONETIME_PRICING") {
                        //     return false;
                        // } else {
                        return _.includes(selectedProductPricingIds, option.id);
                        // }
                    }}
                    isClearable={false}
                    noOptionsMessage={(inputValue) => { return `Start typing...` }}
                    formatGroupLabel={groupLabel}
                />
            </div>
        </div>
    )
}

export default ProductPricingInput;
