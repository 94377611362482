import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverFetch, serverPost} from '../../helpers/server';
import {
    BaseContext,
} from '../../helpers/common';
import {Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import variables from "../../App.scss";
import Link from "../../components/Link";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import BaseForm from "../../components/BaseForm";
import Loader from "../../components/Loader";
import PoweredByMaple from '../../components/PoweredByMaple';
const _ = require('lodash');

function CustomerPortalLogin() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [sentEmail, setSentEmail] = useState(null);

    useEffect(() => {
        setPageTitle(`Customer Portal - Login`);
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const res = await serverFetch(getApiUrl(`/settings`));
        if (res) {
            setSettings(res)
        }
        setLoading(false);
    }

    const sendPortalLink = async (data, defaultErrorHandler) => {
        const result = await serverPost(getApiUrl('/customer_portals/request_link'), data);
        if (result) {
            setSentEmail(data.email);
        }
    }

    let brandingLogo = null;
    let brandColor = variables.primaryColor;
    let brandBackgroundColor = variables.leftnavBackgroundColor;
    if (settings.branding) {
        // brandingLogo = settings.branding.logo_url || null;
        brandColor = settings.branding.color || variables.primaryColor;
        // brandBackgroundColor = settings.branding.color || variables.darkGrayColor;
    }

    let message = `${ company.name } partners with Maple for simplified billing.`;

    const renderLogin = () => {
        return (
            <div className="max-w-xl">
                <p className="mt-8 text-2xl font-semibold">Login to { company.name }'s billing portal</p>
                <p className="mt-6">
                    Please enter the email address associated with your { company.name } account.
                    For security reasons, we need to confirm you are really the owner of the account.
                </p>
                <BaseForm onSubmit={sendPortalLink}>
                    <Row className="mt-4">
                        <BaseForm.Input
                            colSpan="10"
                            type="text" name="email" label={t('common.email')} transformations={["lowercase"]}
                            validations={{ required: true, validEmail: true }}
                        />
                    </Row>
                    <div className="mt-3">
                        <SubmitButton color={brandColor}>Send Portal Link</SubmitButton>
                    </div>
                </BaseForm>
            </div>
        )
    }

    const renderPostLogin = () => {
        return (
            <div className="max-w-xl">
                <p className="mt-8 text-2xl font-semibold">Please check your email</p>
                <p className="mt-6">
                    If { sentEmail } is associated with an active account with { company.name }, you will receive a portal link shortly.
                </p>
                <Row className="mt-4">
                    <p>Email incorrect? <Link onClick={() => setSentEmail(null)}>Use a different email</Link></p>
                </Row>
                {
                    settings.support_email &&
                        <Row className="mt-4">
                            <p>Need support? Please contact <a href={`mailto:${settings.support_email}`} >{ settings.support_email }</a> for assistance.</p>
                        </Row>
                }
            </div>
        )
    }

    const renderInvalid = () => {
        return (
            <div className="max-w-xl">
                <p className="mt-8 text-2xl font-semibold">Billing Portal Link is Invalid</p>
                <p className="mt-6 text-sm">
                    Please reach out to the company for assistance.
                </p>
            </div>
        )
    }

    return (
        <Loader loading={loading}>
            {
                () =>
                    <div className="flex flex-col sm:flex-row h-screen">
                        <div className="sm:basis-1/4 sm:h-full p-6 sm:p-10 pr-7 text-white" style={{ backgroundColor: brandBackgroundColor }}>
                            <div className="flex flex-col h-full gap-1">
                                {
                                    settings.customer_portal_enabled &&
                                        <p className="mt-3 text-2xl font-semibold">{ message }</p>
                                }
                                <div className="grow"/>
                                <div className="flex flex-row gap-2 text-sm items-center">
                                    <PoweredByMaple light />
                                </div>
                            </div>
                        </div>
                        <div id="portal-wrapper" className="sm:basis-3/4 sm:h-full p-6 sm:p-10 pl-7 sm:overflow-scroll" style={{ backgroundColor: variables.backgroundColor }}>
                            {
                                settings.customer_portal_enabled ?
                                    (_.isNil(sentEmail) ? renderLogin(): renderPostLogin())
                                : renderInvalid()
                            }
                        </div>
                    </div>
            }
        </Loader>
    );
}

export default CustomerPortalLogin;
