import '../../../App.scss';
import React, { useState } from 'react';
import { Button as RBButton, Spinner } from 'react-bootstrap';
import classnames from 'classnames';

function Button(props) {
	const pprops = { ...props };
	delete pprops['onClick'];
	const [isLoading, setIsLoading] = useState(false);

	const onClick = async (e) => {
		setIsLoading(true);
		if (props.onClick) {
			await props.onClick(e);
		}
		setIsLoading(false);
	};

	return (
		<RBButton
			{...pprops}
			onClick={onClick}
			style={{ ...props.style, ...(props.color ? { background: props.color } : {}) }}
			className={classnames(
				'whitespace-nowrap',
				props.className,
				{
					"p-2": props.hasIconOnly
				}
			)}
		>
			<div className='d-flex flex-column align-items-center'>
				{isLoading ? (
					<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'>
						&nbsp;
					</Spinner>
				) : (
					<span className='submit-button-content'>{props.children}</span>
				)}
			</div>
		</RBButton>
	);
}

export default Button;
