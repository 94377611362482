import { useMemo } from 'react';
import classnames from 'classnames';

const Input = (props) => {
	const isNumberAndHideArrows = useMemo(() => {
		return props.type === 'number' && props.hideArrows;
	}, [props.type, props.hideArrows]);

	const handleOnChange = (e) => {
		if (isNumberAndHideArrows) {
			e.target.value = e.target.value.replace(/[^0-9]/g, '');
			props.onChange(e);
			return;
		}
		props.onChange(e);
	};
	const getInputType = (type) => {
		if (isNumberAndHideArrows) {
			return 'text';
		}
		return type;
	};

	return (
		<div className='form-group mb-0'>
			<div className={classnames('form-input', { disabled: props.disabled })}>
				<input
					id={props.id}
					name={props.name}
					type={getInputType(props.type)}
					step={props.step}
					min={props.min}
					max={props.max}
					value={props.value}
					onChange={handleOnChange}
					placeholder={props.placeholder}
					disabled={props.disabled}
					className={classnames('form-control', props.classNames)}
				/>
			</div>
		</div>
	);
};

export default Input;
