import { useMemo } from 'react';
import moment from 'moment';

// components
import X from '@untitled-ui/icons-react/build/esm/X';

const FilterTag = ({ filterTitle, filterType, filterValue, filterOptions, removeFilter }) => {
	const tagTitle = useMemo(() => {
		switch (filterType) {
			default:
				return filterTitle;
		}
	}, [filterType, filterTitle]);

	const tagValue = useMemo(() => {
		switch (filterType) {
			case 'select':
				if (!Array.isArray(filterOptions)) return null;
				if (filterValue === null) return null;
				return filterOptions.find((option) => option.value === filterValue).label;
			case 'multi-select':
				if (!Array.isArray(filterOptions) || !Array.isArray(filterValue)) return null;
				const selectedOptions = filterOptions.reduce((acc, option) => {
					if (filterValue.includes(option.value)) {
						return [...acc, option.label];
					}
					return acc;
				}, []);
				return selectedOptions.join(', ');
			case 'dropdown':
				if (!Array.isArray(filterOptions)) return null;
				if (filterValue === null) return null;
				return filterOptions.find((option) => option.value === filterValue).label;
			case 'date':
                if (filterValue === null) return null;
                if (filterValue.label === 'Custom Range' || !filterValue.label) {
                    return `${moment(filterValue.GTE).format('MM/DD/YYYY')} to ${moment(filterValue.LTE).format('MM/DD/YYYY')}`
                }
                return filterValue.label;
			case 'switch':
				if (filterValue === null) return null;
				return filterValue ? 'Yes' : 'No';
			case 'amount':
				if (!filterValue?.op) return null;
				switch (filterValue.op) {
					case 'EQ':
						return `= ${filterValue.eq}`;
					case 'GTE':
						return `>= ${filterValue.gte}`;
					case 'LTE':
						return `<= ${filterValue.lte}`;
					case 'BET':
						return `>= ${filterValue.gte} and <= ${filterValue.lte}`;
					default:
						return null;
				}
			default:
				return null;
		}
	}, [filterType, filterOptions, filterValue]);

	if (tagValue === '' || tagValue === null || !tagTitle) return null;
	return (
		<span className='relative inline-flex items-center px-[10px] py-1 leading-5 border rounded-full !border-primary3 bg-[#F9F5FF] text-sm'>
			<span
				className='absolute inline-flex items-center cursor-pointer before:content-[""] before:absolute before:-inset-1'
				onClick={removeFilter}
			>
				<X width={14} height={14} className='inline text-primary-updated-dark' />
			</span>
			<span className='ml-4 text-dark-gray'>{tagTitle}:</span>&nbsp;
			<span className='text-primary-updated-dark font-medium'>{tagValue}</span>
		</span>
	);
};

export default FilterTag;
