import $ from "jquery";
import moment from 'moment';
import classnames from 'classnames';
import Label from '../components/Label';
import React, { createContext } from 'react';
import {Link} from "react-router-dom";
import BaseForm from "../components/BaseForm";
import {Image} from "react-bootstrap";
var timezones = require('timezones-list');
const _ = require("lodash");

export const UserContext = createContext();
export const BaseContext = createContext();
export const AppContext = createContext();
export const CustomerContext = createContext();
export const IntegrationContext = createContext();
export const PaymentLinkContext = createContext();
export const ContractContext = createContext();
export const CustomerPortalContext = createContext();

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function escapeSelector(selector) {
    return _.replace(selector, ".", "\\.");
}

export function getCustomerNameOrEmail(customer) {
    if (!customer) {
        return "";
    }
    return customer.org_name || customer.name || customer.email || customer.identifier
}

export function percentageFormat(percent, includeSign=true, numDecimals=2) {
    if (_.isNil(percent)) {
        return "";
    }
    let numValue = parseFloat((percent * 100).toFixed(numDecimals))
    return `${numValue}%`;
}

export function currencyFormat(num, currency=null, includeSign=true, numDecimals=2) {
    if (_.isNil(num)) {
        return "";
    }
    let numValue = parseFloat(num.toFixed(numDecimals))
    return Intl.NumberFormat('en-US', {style: 'currency', currency: currency || "USD"}).format(numValue);
}

export function currencyFormatFromPrice(price, negate=false) {
    if (_.isNil(price.value_in_cents)) {
        return "";
    }
    let value = price.value_in_cents/100;
    let valueDescription = Intl.NumberFormat('en-US', {style: 'currency', currency: price.currency || "USD", maximumFractionDigits: 6}).format(value);
    if (negate) {
        valueDescription = `(${valueDescription})`;
    }
    return `${valueDescription}`;
}

export const getConfigActionTypeKey = (configActionType) => {
    let descriptionKey = "";
    if (configActionType === "payment_retry") {
        descriptionKey = 'invoices.config_actions.payment_retry';
    } else if (configActionType === "notification") {
        descriptionKey = 'invoices.config_actions.email';
    } else if (configActionType === "cancel_subscription") {
        descriptionKey = 'invoices.config_actions.cancel_subscription';
    } else if (configActionType === "unpaid_subscription") {
        descriptionKey = 'invoices.config_actions.unpaid_subscription';
    } else if (configActionType === "admin_notification") {
        descriptionKey = 'invoices.config_actions.admin_notification';
    }
    return descriptionKey;
}

export function getMonthName(t, month) {
    const monthNameMap = [
        "",
        t('month.january'),
        t('month.february'),
        t('month.march'),
        t('month.april'),
        t('month.may'),
        t('month.june'),
        t('month.july'),
        t('month.august'),
        t('month.september'),
        t('month.october'),
        t('month.november'),
        t('month.december'),
    ];
    return monthNameMap[month];
}

export function getShortMonthName(t, month) {
    const monthNameMap = [
        "",
        t('month.january_short'),
        t('month.february_short'),
        t('month.march_short'),
        t('month.april_short'),
        t('month.may_short'),
        t('month.june_short'),
        t('month.july_short'),
        t('month.august_short'),
        t('month.september_short'),
        t('month.october_short'),
        t('month.november_short'),
        t('month.december_short'),
    ];
    return monthNameMap[month];
}

export function timeOptions(interval=15) {
    const options = [];
    for (let i = 0; i < 24; i++) {
        const hour = (((i - 1) % 12) + 1);
        const hourString = String(i).padStart(2, '0');;
        for (let j = 0; j < 60/interval; j++) {
            const minute = String(interval * j).padStart(2, '0');;
            const idTime = hourString + ":" + minute + ":00";
            const labelTime = (hour > 0 ? hour : "12") + ":" + minute + (i >= 12 ? " PM":" AM");
            options.push({'value': idTime, 'label': labelTime});
        }
    }
    return options;
}

export function getAggregatorOptions(isUsageBased=false) {
    const options = [
        { value: "COUNT", label: "Count", isUsageBased: true },
        { value: "SUM", label: "Sum", isUsageBased: true },
        { value: "MAX", label: "Max", isUsageBased: true },
        { value: "AVG", label: "Average", isUsageBased: true },
        { value: "UNIQUE", label: "Unique Count", isUsageBased: true },
        { value: "UNIQUE_DAILY", label: "Max Daily Unique Count", isUsageBased: true },
        { value: "OBJ_COUNT", label: "Count", isUsageBased: false },
        { value: "OBJ_SUM", label: "Sum", isUsageBased: false },
        { value: "OBJ_MAX", label: "Max", isUsageBased: false },
        { value: "OBJ_UNIQUE", label: "Unique Count", isUsageBased: false },
        { value: "OBJ_PRORATED_COUNT", label: "Prorated Count", isUsageBased: false},
        { value: "CUSTOMER_FIXED", label: "Customer Fixed", isUsageBased: false },
        { value: "CUSTOMER_LAST_EVER", label: "Last Reported Value In History", isUsageBased: true },
        { value: "CUSTOMER_LAST_PERIOD", label: "Last Reported Value In Period", isUsageBased: true },
        { value: "LAST_EVER_SUM", label: "Historical Sum", isUsageBased: true},
    ]
    return _.filter(options, (i) => i.isUsageBased === isUsageBased);
}

export function getLabelForAggregator(aggregator) {
    const allOptions = _.concat(getAggregatorOptions(true), getAggregatorOptions(false));
    const selectedOption = _.find(allOptions, (o) => o.value === aggregator);
    return selectedOption.label;
}

export function getDescriptionForMetric(metric, item) {
    const rule = metric.metering_rule;
    let itemName = "item";
    if (item) {
        itemName = item.name;
    }

    let filteringDescription = "";
    const filteringRule = rule.rule;
    if (filteringRule === null) {
        filteringDescription = `all ${itemName}(s)`
    } else {
        filteringDescription = `${itemName}(s) with restrictions`
    }

    return (
        <div>{getLabelForAggregator(rule.aggregator)}(<span className="text-dark-gray font-medium">{rule.property || "*"}</span>) on {filteringDescription}</div>
    );
}

export function formatBillingFrequency(frequency) {
    const m = {
        "MONTHLY": "month",
        "YEARLY": "year",
        "ANNUALLY": "year",
        "QUARTERLY": "quarter",
        "HOUR": "hour",
        "DAY": "day",
        "WEEK": "week",
        "BI_MONTH": "bi-month",
        "MONTH": "month",
        "QUARTER": "quarter",
        "BI_ANNUAL": "6 months",
        "YEAR": "year",
    };
    if (_.has(m, frequency)) {
        return m[frequency];
    } else {
        return frequency;
    }
}

export function getItemTypeOptions() {
    return [
        { value: "CUSTOM_USAGE", label: "Usage-based" },
        { value: "CUSTOM_OBJ", label: "Object-based" }
    ]
}

export function getItemTypeDescription(type) {
    const options = getItemTypeOptions()
    const item = _.find(options, (op) => op.value === type);
    return item && item.label;
}

export function getCurrencyOptions() {
    return [
        { value: "USD", label: "USD" },
        { value: "CAD", label: "CAD" },
        { value: "GBP", label: "GBP" },
        { value: "EUR", label: "EUR" },
        { value: "AUD", label: "AUD" },
        { value: "SGD", label: "SGD" },
        { value: "INR", label: "INR" }
    ]
}

export function getTimezoneOptions() {
    const result = _.map(timezones.default, (tz) => {
        return {
            value: tz.tzCode,
            label: tz.label
        }
    });
    result.unshift({ value: "UTC", label: "UTC" })
    return result;
}

export function getCountryOptions() {
    return [
        { value: "US", label: "USA" },
        { value: "CA", label: "Canada" },
        { value: "GB", label: "United Kingdom" },
        { value: "AU", label: "Australia" },
        { value: "IN", label: "India"},
        { value: "SG", label: "Singapore" },
        { value: "DE", label: "Germany" },
    ]
}

export function getLabelForCountry(code) {
    const options = getCountryOptions();
    const option = _.find(options, (op) => op.value === code)
    return option ? option.label : null;
}

export function getCurrentCompanyUser(companyId, userInfo) {
    return _.find(userInfo.companies, (c) => String(c.company_id) === String(companyId));
}

export function hasAccessForCompany(companyId, feature, userInfo, mode="write") {
    if (_.isEmpty(userInfo)) {
        return false;
    }
    if (userInfo.is_super_user) {
        return true;
    }
    const companyUser = _.find(userInfo.companies, (c) => String(c.company_id) === String(companyId));
    if (!companyUser) {
        return false;
    }
    const accessInfo = companyUser.access[feature];
    if (mode === "write") {
        return accessInfo === "write";
    } else if (mode === "update" || mode === "delete") {
        return accessInfo === mode || accessInfo === "write";
    } else {
        return _.includes(["read", "write", "update", "delete"], accessInfo);
    }
}

export function isPartialOrFullAdmin(userInfo) {
    return !_.isNil(userInfo.accessLevel) && userInfo.accessLevel !== undefined && userInfo.accessLevel > 0;
}

export function isNotAdmin(userInfo) {
    return !isPartialOrFullAdmin(userInfo);
}

export function validateURL(url, isRequired) {
    if (isRequired) {
        if (!url || _.isEmpty(url.trim())) {
            return false;
        }
    } else {
        if (!url || _.isEmpty(url.trim())) {
            return true;
        }
    }

    try {
        const urlObject = new URL(url);
        if (urlObject.protocol === "https:" || urlObject.protocol === "http:") {
            return true;
        }
        return false;
    } catch(e) {
        return false;
    }
}

export function validateEmail(email, isRequired) {
    if (isRequired) {
        if (!email || _.isEmpty(email.trim())) {
            return false;
        }
    } else {
        if (!email || _.isEmpty(email.trim())) {
            return true;
        }
    }
    const result = String(email.trim())
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    return result !== null;
}

export function dateRangeClassCheck(date, startDate, endDate) {
    var sdate = startDate;
    var edate = endDate;
    var fdate = moment(date).format("YYYY-MM-DD");
    var className = ""
    if (sdate === fdate) {
        className = "ui-datepicker-start-date";
    } else if (edate === fdate) {
        className = "ui-datepicker-end-date";
    } else if (moment(edate).isAfter(sdate) && moment(sdate).isBefore(fdate, 'day') && moment(edate).isAfter(fdate, 'day')) {
        className = "ui-datepicker-mid-date";
    }
    return [true, className, null];
}

export function downloadBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function getRandomString(length) {
    var text = "";
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function conciseTimeDisplay(mtime, withMeridiem = true) {
    if (mtime.minutes() !== 0) {
        return mtime.format(`h:mm${withMeridiem ? "a" : ""}`);
    } else {
        return mtime.format(`h${withMeridiem ? "a" : ""}`);
    }
}

export function getNameForProviderType(type) {
    const m = {
        "STRIPE": "Stripe",
        "HUBSPOT": "Hubspot",
        "BRAINTREE": "Braintree",
        "BAMBORA": "Bambora",
        "SQUARE": "Square",
        "ADYEN": "Adyen",
        "MONERIS": "Moneris",
        "CASH": "Cash",
        "CHEQUE": "Check",
        "WIRE": "Wire",
        "CREDIT_CARD_OFFLINE": "Credit Card Offline",
        "BILL_COM": "Bill.com",
        "BANK_TRANSFER": "Bank Transfer",
        "RAZOR_PAY": "Razorpay",
        "INTERNAL": "Internal",
    }
    if (_.has(m, type)) {
        return m[type];
    } else {
        return type;
    }
}

export function renderChildren(props) {
    if (typeof props.children === 'function') {
        return props.children();
    } else {
        return props.children;
    }
}

export function renderComponents(components) {
    if (typeof components === 'function') {
        return components();
    } else {
        return components;
    }
}

export function renderProductPricing(productPricing, billableItems) {
    return (
        <>
            {
                _.map(productPricing.product_metric_pricings, (pmp, i) =>
                    <div key={i}>
                        { renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, billableItems) }
                    </div>
                )
            }
        </>
    )
}

/*
* Updates a field in a deeply-nested object structure.
* Ex. updateFormField({}, "a.b.c.d", "e") => { a: { b: { c: { d: "e" } } } }
* */
export const updateFormField = (fieldMap, key, value) => {
    let parts = key.split(".");
    let ob = fieldMap;
    for (let i = 0; i < parts.length - 1; i++) {
        if (!_.has(ob, parts[i]) || _.isNil(ob[parts[i]])) {
            ob[parts[i]] = {};
        }
        ob = ob[parts[i]];
    }
    ob[parts[parts.length - 1]] = value;
    return fieldMap;
}

export function renderDescriptionForItemPricing(pricing, includeName=false, item=null, allItems=[]) {
    let pricingDescriptions = [];
    let baseDescription = "";

    if (item && item.type === "ONETIME_ITEM") {
        pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.fixed_price.price_per_unit, currency: pricing.base_price.currency })}`];
        if (pricing.credit_price) {
            const creditType = pricing.credit_price.type;
            if (creditType === "UNITS") {
                const creditItem = _.find(allItems, (i) => String(i.id) === String(pricing.credit_price.item_id));
                if (creditItem) {
                    pricingDescriptions.push(`${pricing.credit_price.units} ${creditItem.name} in credits`);
                } else {
                    pricingDescriptions.push(`${pricing.credit_price.units} units of ${pricing.credit_price.item_id} in credits`);
                }
            } else if (creditType === "AMOUNT") {
                pricingDescriptions.push(`${currencyFormatFromPrice(pricing.credit_price.amount)} credit`);
            }
        }
    } else {
        let itemName = "units";
        if (item) {
            if (item.type === "LICENSE_ITEM") {
                itemName = ""
            } else if (item.type === "ONETIME_ITEM") {
                itemName = ""
            } else {
                itemName = item.name;
            }
        }

        let frequencyDescription = "";
        let trueUpFrequencyDescription = "";
        if (pricing.frequency !== "") {
            frequencyDescription = `/${formatBillingFrequency(pricing.frequency)}`;
        }
        if (pricing.true_up_frequency && pricing.true_up_frequency !== pricing.frequency) {
            trueUpFrequencyDescription = `True up ${getLabelForBillingFrequency(pricing.true_up_frequency)}`;
        }

        if (pricing.type === "FIXED") {
            pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.fixed_price.price_per_unit, currency: pricing.base_price.currency })}${itemName ? `/${itemName}` : ""}${frequencyDescription}`]
        } else if (pricing.type === "STEP") {
            pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.step_price.price_per_step, currency: pricing.base_price.currency })}/${pricing.step_price.step_size} ${itemName}${frequencyDescription}`]
        } else if (pricing.type === "GRADIENT") {
            pricingDescriptions = [`Varied Pricing${itemName ? `/${itemName}` : ""}${frequencyDescription}`] 
            _.each(pricing.gradient_price, (gp) => {
                let startDescription = gp.start;
                if (pricing.display_type === "AMOUNT") {
                    startDescription = currencyFormatFromPrice({ value_in_cents: gp.start*100, currency: pricing.base_price.currency })
                }
                let endDescription = gp.end;
                if (gp.end === 2147483648 || gp.end === 9223372036854776000) {
                    endDescription = "∞"
                } else if (pricing.display_type === "AMOUNT") {
                    endDescription = currencyFormatFromPrice({ value_in_cents: gp.end*100, currency: pricing.base_price.currency })
                }
                let gpDescription = `${startDescription} - ${endDescription} units →`;
                if (gp.is_custom_tier) {
                    gpDescription = `${gpDescription} Custom Pricing`
                } else {
                    if (gp.flat_price > 0 || gp.price_per_unit == 0) {
                        gpDescription = `${gpDescription} ${currencyFormatFromPrice({
                            value_in_cents: gp.flat_price,
                            currency: pricing.base_price.currency
                        })}${gp.price_per_unit > 0 ? " +" : ""}`
                    }
                    if (gp.price_per_unit > 0) {
                        gpDescription = `${gpDescription} ${currencyFormatFromPrice({
                            value_in_cents: gp.price_per_unit,
                            currency: pricing.base_price.currency
                        })}/unit`
                    }
                }
                pricingDescriptions.push(gpDescription);
            })
        } else if (pricing.type === "VOLUME") {
            pricingDescriptions = [`Volume Pricing${itemName ? `/${itemName}` : ""}${frequencyDescription}`];
            _.each(pricing.volume_price, (gp) => {
                let startDescription = gp.total_units_start;
                if (pricing.display_type === "AMOUNT") {
                    startDescription = currencyFormatFromPrice({ value_in_cents: gp.total_units_start*100, currency: pricing.base_price.currency })
                }
                let endDescription = gp.total_units_end;
                if (gp.total_units_end === 2147483648 || gp.total_units_end === 9223372036854776000) {
                    endDescription = "∞"
                } else if (pricing.display_type === "AMOUNT") {
                    endDescription = currencyFormatFromPrice({ value_in_cents: gp.total_units_end*100, currency: pricing.base_price.currency })
                }
                let gpDescription = `${startDescription} - ${endDescription} units →`;
                if (gp.is_custom_tier) {
                    gpDescription = `${gpDescription} Custom Pricing`
                } else {
                    if (gp.flat_price > 0 || gp.price_per_unit == 0) {
                        gpDescription = `${gpDescription} ${currencyFormatFromPrice({
                            value_in_cents: gp.flat_price,
                            currency: pricing.base_price.currency
                        })}${gp.price_per_unit > 0 ? " +" : ""}`
                    }
                    if (gp.price_per_unit > 0) {
                        gpDescription = `${gpDescription} ${currencyFormatFromPrice({
                            value_in_cents: gp.price_per_unit,
                            currency: pricing.base_price.currency
                        })}/unit`
                    }
                }

                pricingDescriptions.push(gpDescription);
            })
        } else if (pricing.type === "CUSTOM") {
            pricingDescriptions = [`Custom/period`]
        } else if (pricing.type === "SCHEDULED") {
            let total = _.sumBy(_.values(pricing.scheduled_price.price_per_unit_per_period), v => parseFloat(v));
            pricingDescriptions = [
                `${_.startCase(getLabelForBillingFrequency(pricing.frequency))} schedule`,
                `Total: ${currencyFormatFromPrice({value_in_cents: total, currency: pricing.base_price.currency})}`,
                `Term: ${formatTerm({count: pricing.term_count, frequency: pricing.frequency})}`
            ]
            _.map(pricing.scheduled_price.price_per_unit_per_period, (p, l) => {
                if (p > 0) {
                    const v = currencyFormatFromPrice({ value_in_cents: p, currency: pricing.base_price.currency })
                    let d = `${getLabelForSubscriptionLength(pricing.frequency)} ${l}`
                    if (l === 0) {
                        d = "Initial"
                    } else if (l === pricing.scheduled_price.price_per_unit_per_period.length-1) {
                        d = "End"
                    }
                    pricingDescriptions.push(`${d}: ${v}`)
                }
            })
        }

        let baseUnitsDescription = "";
        if (pricing.base_units) {
            baseUnitsDescription = `, ${pricing.base_units} ${itemName}(s) included`;
        }
        if (trueUpFrequencyDescription) {
            pricingDescriptions.push(trueUpFrequencyDescription)
        }

        if (pricing.base_price.value_in_cents > 0 || pricing.base_units > 0) {
            baseDescription = `${_.isEmpty(pricingDescriptions) ? "": "+"}${ currencyFormatFromPrice(pricing.base_price) } (base price${baseUnitsDescription})`
        }
    }

    let chargeTypeDescription = null;
    if (pricing.charge_type === "POST_ONLY") {
        chargeTypeDescription = "Billed at the end of period"
    }

    let minimumSpendDescription = null;
    if (pricing.minimum_spend && pricing.minimum_spend.value_in_cents > 0) {
        minimumSpendDescription = `Min: ${currencyFormatFromPrice(pricing.minimum_spend)} (evenly over ${formatTerm({ count: pricing.term_count, frequency: pricing.frequency })})`
    }

    return (
        <div className="item_pricing d-flex flex-column">
            {
                includeName &&
                    <div className="flex-grow-0 button-text gray4">{ pricing.name }</div>
            }
            {
                _.map(pricingDescriptions, (pd, i) =>
                    <span key={i} className={classnames(i !== 0 ? "text-subtitle": "text-dark-gray font-medium", "flex-grow-1 d-flex flex-column justify-content-center", i !== 0 ? "caption": "text-sm")}>{ pd }</span>
                )
            }
            {
                baseDescription &&
                    <div className="caption flex-grow-1 text-subtitle">{ baseDescription }</div>
            }
            {
                chargeTypeDescription && <div className="caption text-subtitle">{chargeTypeDescription}</div>
            }
            { minimumSpendDescription && <div className="caption text-subtitle">{minimumSpendDescription}</div> }
        </div>
    )
}

export function renderAddress(address) {
    if (_.isNil(address) || _.isEmpty(address)) {
        return <span/>
    }

    if (_.isEmpty(address.address_line_1) && _.isEmpty(address.address_line_2)
        && _.isEmpty(address.city) && _.isEmpty(address.state) && _.isEmpty(address.zip)) {
        return <span/>
    }

    const line1 = address.address_line_1;
    const line2 = address.address_line_2;
    const line3 = _.filter([address.city, address.state, address.zip], (a) => a !== null && a !== "").join(", ");
    const line4 = getLabelForCountry(address.country);

    return (
        <div>
            {
                line1 &&
                    <div>{ line1 }</div>
            }
            {
                line2 &&
                    <div>{ line2 }</div>
            }
            <span>{ line3 }</span><br/>
            <span>{ line4 }</span><br/>
        </div>
    );
}

export function getBrandName(brand) {
    const cardBrandNameMap = {
        visa: "Visa",
        amex: "American Express",
        mastercard: "Mastercard",
        discover: "Discover",
        jcb: "JCB"
    }
    if (_.includes(_.keys(cardBrandNameMap), brand)) {
        return cardBrandNameMap[brand];
    } else {
        return brand;
    }
}

const getBrandLogo = (brand) => {
    const cardBrandLogoMap = {
        visa: "/images/cards/visa.svg",
        amex: "/images/cards/american-express.svg",
        mastercard: "/images/cards/mastercard.svg",
        discover: "/images/cards/discover.svg",
        jcb: "/images/cards/jcb.svg"
    }
    const brandName = brand.toLowerCase();
    if (_.includes(_.keys(cardBrandLogoMap), brandName)) {
        return cardBrandLogoMap[brandName];
    } else {
        return null;
    }
}

export function renderPaymentMethod(t, method) {
    if (!method) {
        return null;
    }
    let title = `${getBrandName(method.brand) } ....${ method.last_4 }`
    if (method.type === "us_bank_account") {
        title = method.name
    } else if (method.type === "acss_debit") {
        title = method.name
    } else if (method.type === "link") {
        title = "Link"
    }

    let description = ""
    if (method.exp_month || method.exp_year) {
        description = `Expires on ${ getShortMonthName(t, method.exp_month) } ${ method.exp_year }`
    }
    if (method.type === "us_bank_account" ){
        description = `${method.routing_number} ....${ method.last_4 }`
    } else if (method.type === "acss_debit") {
        description = `${method.transit_number}-${method.institution_number}-....${ method.last_4 }`
    } else if (method.type === "link") {
        description = method.name
    } else if (method.type === "bank_transfer") {
        description = method.name
    }

    let profileIcon = <div className="profile-icon"/>
    if (method.type === "card" || method.type === "credit") {
        if (getBrandLogo(method.brand)) {
            profileIcon = <div className="h-10 w-10 flex items-center">
                <Image src={getBrandLogo(method.brand)}/>
            </div>
        }
    } else if (method.type === "us_bank_account") {
        profileIcon = <div className="profile-icon">
            <i className="fa fa-building-columns text-gray-600 text-base"/>
        </div>
    } else if (method.type === "acss_debit") {
        profileIcon = <div className="profile-icon">
            <i className="fa fa-building-columns text-gray-600 text-base"/>
        </div>
    } else if (method.type === "link") {
        profileIcon = <div className="h-10 w-10 flex items-center justify-content-center">
            <Image src={"/images/cards/stripe_link.svg"}/>
        </div>
    }

    return (
		<div className='d-flex flex-row align-items-center gap-3'>
			{profileIcon}
			<div className='d-flex flex-column'>
				<p className='title'>{title}</p>
				{description ? <p className='body1 gray3'>{description}</p> : <div/>}
			</div>
		</div>
	);
}

export function getPaymentMethodDescription(t, method) {
    if (!method) {
        return null;
    }
    return (
        <div className="d-flex flex-row gap-2 items-center">
            {
                (method.type === "us_bank_account" || method.type === "acss_debit") ?
                    <div key={"l"} className="profile-icon !h-7 !w-7 !min-w-[28px]">
                        <i className="fa fa-building-columns"/>
                    </div>
                : method.type === "card" && getBrandLogo(method.brand) ?
                    <div key={"l"} className="h-7 w-7 flex items-center">
                        <Image src={getBrandLogo(method.brand)}/>
                    </div>
                : <div key={"l"} className="h-7 w-7">

                </div>
            }
            {
                (method.type === "us_bank_account" || method.type === "acss_debit") ?
                    <p key={"t"} className="title">{ method.name } { method.routing_number } ....{ method.last_4 }</p>
                : method.type === "card" ?
                    <p key={"t"} className="title">{ getBrandName(method.brand) } ....{ method.last_4 }</p>
                : <p key={"t"} className="title">{ getBrandName(method.brand) } ....{ method.last_4 }</p>
            }
            {
                method.type === "card" ?
                    <p key={"d"} className="body1 gray3">Exp { getShortMonthName(t, method.exp_month) } { method.exp_year }</p>
                    : <p key={"d"} className="body1 gray3"></p>
            }
        </div>
    )
}

export function getPropsForMetric(metricKey, company, isMapleUser) {
    const metricLabelMap = {
        "MRR": { title: "Monthly Recurring Revenue", yLabel: "MRR", type: "currency", summaryAggregator: "last",
            description: "This is your booked revenue and is calculated by adding up the monthly recurring value of all of your active subscription customers, minus any non-expiring discounts.",
            showGrouping: isMapleUser || (company && company.id === "cmp_2hgs3fyh8s7WBBwZQYzgHRp0zUv"), groupings: [{ label: "None", value: null },{ label: "Products", value: "PRODUCT" },{ label: "Plans", value: "PRICING" }] },
        "ARR": { title: "Annual Recurring Revenue", yLabel: "ARR", type: "currency", summaryAggregator: "last",
            description: "This is the current value of your business projected out over the next year. This is calculated by multiplying MRR by 12.",
            showGrouping: isMapleUser || (company && company.id === "cmp_2hgs3fyh8s7WBBwZQYzgHRp0zUv"), groupings: [{ label: "None", value: null },{ label: "Products", value: "PRODUCT" },{ label: "Plans", value: "PRICING" }] },
        "NET_REVENUE": { title: "Net Revenue", yLabel: "Net Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is your revenue calculated by taking total revenue (recurring and non-recurring) minus any fees from your payment processor." },
        "TOTAL_REVENUE": { title: "Total Revenue", yLabel: "Total Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is the revenue you have collected. This is calculated by taking gross volume (recurring and non-recurring) minus any refunds and discounts.",
            showGrouping: true,
        },
        "FEES": { title: "Fees", yLabel: "Fees", type: "currency", summaryAggregator: "sum",
            description: "This includes the charge fees from your payment provider." },
        "MRR_GROWTH": { title: "MRR Growth", yLabel: "MRR Growth", type: "percent", summaryAggregator: "last",
            description: "This is calculated by taking ((Today's MRR / 30 days ago MRR) - 1) x 100."},
        "OTHER_REVENUE": { title: "Other Revenue", yLabel: "Other Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is the revenue from non-recurring charges."},
        "TOTAL_INVOICED_REVENUE": { title: "Invoiced Revenue", yLabel: "Invoiced Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is the revenue that has been invoiced during the period.",
            showGrouping: true,
        },
        "ACTIVE_SUBS": { title: "Active Subscriptions", yLabel: "Subscriptions", type: "integer", summaryAggregator: "last",
            description: "This is the current number of active subscriptions.", showGrouping: true },
        "NEW_SUBS": { title: "New Subscriptions", yLabel: "Subscriptions", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new subscriptions.", showGrouping: true},
        "UPGRADES": { title: "Upgrades", yLabel: "Upgrades", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers going from one plan to another more expensive plan."},
        "DOWNGRADES": { title: "Downgrades", yLabel: "Downgrades", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers going from one plan to another less expensive plan."},
        "FAILED_CHARGES": { title: "Failed Charges", yLabel: "Failed Charges", type: "integer", summaryAggregator: "sum",
            description: "This is the number of unsuccessful payment attempts by your payment provider." },
        "REFUNDS": { title: "Refunds", yLabel: "Refunds", type: "currency", summaryAggregator: "sum",
            description: "This is the total amount that is returned back to customers." },
        "NEW_REFUNDS": { title: "New Refunds", yLabel: "Refunds", type: "integer", summaryAggregator: "sum",
            description: "This is the total number of refunds being processed by your payment provider." },
        "DISCOUNTS_REDEEMED": { title: "Discounts Redeemed", yLabel: "Discounts", type: "currency", summaryAggregator: "sum",
            description: "This is the total amount given out as discounts to customers." },
        "ACTIVE_CUSTOMERS": { title: "Active Customers", yLabel: "Customers", type: "integer", summaryAggregator: "last",
            description:"This is the current number of customers with active subscriptions.", showGrouping: true },
        "NEW_CUSTOMERS": { title: "New Customers", yLabel: "Customers", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new customers." },
        "ARPU": { title: "Average Revenue Per User", yLabel: "ARPU", type: "currency", summaryAggregator: "last",
            description: "This is the average MRR per active customer. This is calculated by taking the MRR and dividing it by the number of active customers." },
        "ARPL": { title: "Average Revenue Per License", yLabel: "ARPL", type: "currency", summaryAggregator: "last",
            description: "This is the average MRR per license. This is calculated by taking the MRR and dividing it by the number of active licenses." },
        "HISTORICAL_ARPU": { title: "Total Revenue Per Customer", yLabel: "ARPU", type: "currency", summaryAggregator: "last",
            description: "This is the total historical revenue per customer (active now or historically). This is calculated by taking the total historical revenue and dividing it by the number of customers (active now or historically)." },
        "LTV": { title: "Lifetime Value", yLabel: "LTV", type: "currency", summaryAggregator: "last",
            description: "This is the expected value of a customer until they churn. This is calculated by taking the average MRR per active customer and dividing it by the user churn rate." },
        "ACTIVE_TRIALS": { title: "Active Trials", yLabel: "Subscriptions", type: "integer", summaryAggregator: "last",
            description:"This is the current number of active trial subscriptions.", showGrouping: true },
        "NEW_TRIALS": { title: "New Trials", yLabel: "Subscriptions", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new trial subscriptions.", showGrouping: true },
        "CONVERTED_TRIALS": { title: "Converted Trials", yLabel: "Subscriptions", type: "integer", summaryAggregator: "sum",
            description: "This is the number of trial subscriptions that have successfully converted to an active subscription.", showGrouping: true },
        "CHURNED_CUSTOMERS": { title: "Churned Customers", yLabel: "Customers", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers that stopped their subscription." },
        "USER_CHURN": { title: "User Churn", yLabel: "Churn", type: "percent", summaryAggregator: "last",
            description: "This is the percentage of customers who left in the previous 30-day period relative to your total customer count 30 days ago." },
        "REVENUE_CHURN": { title: "Revenue Churn", yLabel: "Churn", type: "percent", summaryAggregator: "last",
            description: "This is the percentage of MRR that has been lost in the last 30 days relative to your total MRR 30 days ago." },
        "NET_REVENUE_CHURN": { title: "Net Revenue Churn", yLabel: "Churn", type: "percent", summaryAggregator: "last",
            description: "This is total revenue churn minus expansion. This is calculated by taking (Churned MRR - expansion MRR) / starting MRR 30 days ago x 100." },
        "CHURNED_SUBS": { title: "Churned Subscriptions", yLabel: "Churned Subscriptions", type: "integer", summaryAggregator: "sum",
            description: "This is the number of canceled subscriptions.", showGrouping: true },
        "PAYMENTS": { title: "New Payments", yLabel: "Payments", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of payments being charged by your payment provider." },
        "NEW_INVOICES": { title: "New Invoices", yLabel: "Invoices", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new invoices generated for customers." },
        "PENDING_INVOICES": { title: "Pending Invoice Amount", yLabel: "Invoices", type: "currency", chartType: "bar", summaryAggregator: "sum",
            description: "This is the total amount left to collect on pending invoices." },
        "PAID_INVOICES": { title: "Paid Invoices", yLabel: "Invoices", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of invoices paid by customers." },
        "REACTIVATIONS": {title: "Reactivations", yLabel: "Reactivations", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is number of churned customers that reactivated into an active subscription." },
        "REVENUE_CONNECTED_ACCOUNTS": { title: "Total Connected Accounts Payments", yLabel: "Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is total payments processed for your connected accounts." },
        "APPLICATION_FEES_CONNECTED_ACCOUNTS": { title: "Application Fees", yLabel: "Fees", type: "currency", summaryAggregator: "sum",
            description: "This is the application fees collected from connected account payments." },
        "LICENSES": { title: "Licenses", yLabel: "Licenses", type: "integer", summaryAggregator: "last",
            description: "This is the total number of licenses for active subscriptions", showGrouping: true },
        "NEW_CONTRACTS": { title: "New Contracts", yLabel: "Contracts", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new contracts generated for customers." },
        "PENDING_TCV": { title: "Pending TCV Amount", yLabel: "Contracts", type: "currency", chartType: "bar", summaryAggregator: "sum",
            description: "This is the total amount left to collect on contracts pending signature." },
        "EXECUTED_CONTRACTS": { title: "Executed Contracts", yLabel: "Contracts", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of contracts signed by customers." },
        "CREDIT_AMOUNTS_CREATED": { title: "Credit Amounts Issued", yLabel: "Credit", type: "currency", summaryAggregator: "sum",
            description: "This is the total of credit amounts issued", showGrouping: false },
        "CREDIT_UNITS_CREATED": { title: "Credit Units Issued", yLabel: "Credit", type: "integer", summaryAggregator: "sum",
            description: "This is the total of credit units issued", showGrouping: true, groupings: [{ label: "None", value: null },{ label: "Billable Items", value: "BILLABLE_ITEM" }] },
        "CREDIT_AMOUNTS_CONSUMED": { title: "Credit Amounts Consumed", yLabel: "Credit", type: "currency", summaryAggregator: "sum",
            description: "This is the total amount of credit amounts consumed", showGrouping: false },
        "CREDIT_UNITS_CONSUMED": { title: "Credit Units Consumed", yLabel: "Credit", type: "integer", summaryAggregator: "sum",
            description: "This is the total of credit units consumed", showGrouping: true, groupings: [{ label: "None", value: null },{ label: "Billable Items", value: "BILLABLE_ITEM" }] },

        "RECOGNIZED_REVENUE": { title: "Recognized Revenue", yLabel: "Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is the total of credit units consumed", showGrouping: false, chartType: "bar"}
    }

    let result = {
        yLabel: metricKey,
        xAxisLabelFormat: "MMM D",
        chartType: "line",
        groupings: null
    }
    result = _.merge(result, metricLabelMap[metricKey])
    return result;
}

export function getLabelForSubscriptionLength(length) {
    if (length === "MONTH") {
        return "Month";
    } else if (length === "BI_MONTH") {
        return "Bi-Month";
    } else if (length === "YEAR") {
        return "Year";
    } else if (length === "DAY") {
        return "Day";
    } else if (length === "WEEK") {
        return "Week";
    } else if (length === "HOUR") {
        return "Hour";
    } else if (length === "QUARTER") {
        return "Quarter";
    } else if (length === "BI_ANNUAL") {
        return "Bi-Annual";
    } else if (length === "ONETIME") {
        return "One Time";
    } else {
        return length;
    }
}

export function getLabelForBillingFrequency(frequency) {
    if (frequency === "MONTH") {
        return "monthly";
    } else if (frequency === "BI_MONTH") {
        return "bi-monthly";
    } else if (frequency === "YEAR") {
        return "yearly";
    } else if (frequency === "DAY") {
        return "daily";
    } else if (frequency === "QUARTER") {
        return "quarterly";
    } else if (frequency === "BI_ANNUAL") {
        return "bi-annually";
    } else if (frequency === "WEEK") {
        return "weekly";
    } else {
        return frequency;
    }
}

export function getPlanLengthOptions() {
    return [
        { value: "WEEK", label: "Week" },
        { value: "BI_MONTH", label: "Bi-Month" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "BI_ANNUAL", label: "Bi-Annual" },
        { value: "YEAR", label: "Year" },
    ]
}

export function getFrequencyOptions() {
    return [
        { value: "WEEK", label: "Weekly" },
        { value: "BI_MONTH", label: "Bi-Monthly" },
        { value: "MONTH", label: "Monthly" },
        { value: "QUARTER", label: "Quarterly" },
        { value: "BI_ANNUAL", label: "Bi-Annually" },
        { value: "YEAR", label: "Yearly" },
    ]
}

export function getLabelForTaxCategory(category) {
    if (category) {
        return `${category.name} (${category.product_tax_code})`;
    } else {
        return null;
    }
}

export function renderContractStatusLabel(contract) {
    if (contract.status === "PENDING_PAYMENT") {
        return (
            <Label.Warning>Pending Payment Method</Label.Warning>
        )
    } else if (contract.status === "EXECUTED") {
        return (
            <Label.Success>Executed</Label.Success>
        )
    } else if (contract.status === "DECLINED") {
        return (
            <Label.Danger>Declined</Label.Danger>
        )
    } else if (contract.status === "DRAFT") {
        return (
            <Label.Info>Draft</Label.Info>
        )
    } else if (contract.status === "NEEDS_APPROVAL") {
        return (
            <Label.Info>Waiting for Approval</Label.Info>
        )
    } else if (contract.status === "EXPIRED") {
        return (
            <Label.Danger>Expired</Label.Danger>
        )
    } else if (contract.status === "PENDING_SIGNATURES") {
        return (
            <Label.Danger>Pending Signatures</Label.Danger>
        )
    } else if (contract.status === "REJECTED") {
        return (
            <Label.Danger>Rejected</Label.Danger>
        )
    } else if (contract.status === "COMPLETE") {
        return (
            <Label.Success>Pending Execution</Label.Success>
        )
    } else if (contract.status === "AWAITING_MANUAL_ACTION") {
        return (
            <Label.Neutral>Awaiting Manual Confirmation</Label.Neutral>
        )
    } else if (contract.status === "PENDING_COUNTER_SIGNATURES") {
        return (
            <Label.Danger>Pending Counter Signatures</Label.Danger>
        )
    }
}

export function renderFileImportStatusLabel(fileImport) {
    if (fileImport.status === "PENDING") {
        return (
            <Label.Neutral>Pending</Label.Neutral>
        )
    } else if (fileImport.status === "IN_PROGRESS") {
        return (
            <Label.Warning>In Progress</Label.Warning>
        )
    } else if (fileImport.status === "COMPLETED") {
        return (
            <Label.Success>Completed</Label.Success>
        )
    } else if (fileImport.status === "FAILED") {
        return (
            <Label.Danger>Failed</Label.Danger>
        )
    }
}

export function renderCampaignStatusLabel(campaign) {
    if (campaign.status === "RECOVERED") {
        return <Label.Success>Recovered</Label.Success>;
    } else if (campaign.status === "ACTIVE") {
        return <Label.Warning>Active</Label.Warning>;
    } else if (campaign.status === "COMPLETED") {
        return <Label.Danger>Completed</Label.Danger>;
    } else if (campaign.status === "STOPPED") {
        return <Label.Info>Stopped</Label.Info>
    }
}

export function getStatusForStep(step) {
    if (step.status === "COMPLETED") {
        return <Label.Success>Completed</Label.Success>
    } else if (step.status === "ENQUEUED") {
        return <Label.Neutral>Scheduled</Label.Neutral>
    } else if (step.status === "IGNORED") {
        return <Label.Info>Ignored</Label.Info>
    } else if (step.status === "FAILED") {
        return <Label.Danger>Ignored</Label.Danger>
    } else if (step.status === "STOPPED") {
        return <Label.Warning>Stopped</Label.Warning>
    }
}

export function getCampaignTypeLabel(type) {
    switch (type) {
        case "payment_recovery":
            return "Payment Recovery";

        case "payment_method_expired":
            return "Card Expiry Reminders";

        case "invoice_reminder":
            return "Invoice Reminders";

        case "proposal_sign_reminder":
            return "Contract Reminders";

        default:
            return ""
    }
}

export function renderProductMetric(pmp, configItem) {
    if (pmp.metric.type === "ONETIME_METRIC") {
        if (configItem) {
            if (!_.isNil(configItem.aggregate)) {
                return <span className="text-dark-gray">{ configItem.aggregate }</span>
            } else {
                return <span className="text-dark-gray">{ configItem.num_licenses }</span>
            }
        } else {
            return (<span className="text-dark-gray">0</span>);
        }
    } else if (pmp.metric.type === "LICENSE_METRIC") {
        if (pmp.item_pricing && pmp.item_pricing.type === "SCHEDULED") {
            return (<span className="text-dark-gray">N/A</span>);
        } else if (configItem) {
            return (
                <span className="text-dark-gray">{ configItem.num_licenses }</span>
            );
        } else {
            return (<span className="text-dark-gray">0</span>);
        }
    } else {
        return (
            <>
                <span className="text-dark-gray">{ pmp.metric.name }</span>
                <span className="caption text-subtitle">{ getDescriptionForMetric(pmp.metric) }</span>
                {
                    configItem && configItem.minimum_units > 0 &&
                        <span className="text-subtitle text-xs">Minimum: { configItem && configItem.minimum_units }</span>
                }
            </>
        )
    }
}

export function renderTags(tags) {
    if (!tags || _.isEmpty(tags)) {
        return null;
    }
    return (
        <div className="flex flex-row gap-1">
            {
                _.map(tags, (tag, i) =>
                    <Label.Info key={i}>{tag}</Label.Info>
                )
            }
        </div>
    )
}

export function getLabelForAction(action) {
    const labelMap = {
        "contract.created": "Contract Created",
        "contract.updated": "Contract Updated",
        "contract.deleted": "Contract Deleted",
        "contract.signed": "Contract Signed",
        "contract.executed": "Contract Executed",

        "invoice.created": "Invoice Created",
        "invoice.updated": "Invoice Updated",
        "invoice.cancelled": "Invoice Cancelled",
        "invoice.payment.success": "Invoice Payment Succeeded",
        "invoice.payment.failed": "Invoice Payment Failed",
        "invoice.payment.reminder.sent": "Invoice Reminder Sent",
        "invoice.emailed": "Invoice Email Sent",

        "subscription.created": "Subscription Created",
        "subscription.updated": "Subscription Updated",
        "subscription.renewed": "Subscription Renewed",
        "subscription.cancelled": "Subscription Cancelled",
        "subscription.revoked": "Subscription Revoked",
        "subscription.extended": "Subscription Extended",
        "subscription.changed": "Subscription Plan Changed",

        "customer.created": "Customer Created",
        "customer.updated": "Customer Updated",
        "customer.deleted": "Customer Deleted",

        "pricing.created": "Pricing Created",
        "pricing.updated": "Pricing Updated",
        "pricing.deleted": "Pricing Deleted",

        "credit.created": "Credit Created",
        "credit.updated": "Credit Updated",
        "credit.deleted": "Credit Deleted",

        "discount.created": "Discount Created",
        "discount.updated": "Discount Updated",
        "discount.revoked": "Discount Revoked",
    }
    if (_.has(labelMap, action)) {
        return labelMap[action];
    } else {
        return action;
    }
}

export function getRoleDescription(row) {
    if (!row) {
        return <span></span>
    }
    if (row.role === "default_admin") {
        return <span>Full Admin</span>
    } else if (row.role === "default_sales_rw") {
        return <span>Sales Admin</span>
    } else if (row.role === "default_read_only") {
        return <span>Read Only</span>
    } else if (row.role === "default_accountant") {
        return <div className="flex flex-col gap-1">
            <span className="text-sm text-gray-900">Account Role</span>
            <span className="text-sm text-gray-400">Read-only, with write on accounting integrations.</span>
        </div>
    } else {
        return <span></span>
    }
}

export function getAddressFromGooglePlace(place) {
    const addressComponentMap = {};
    _.each(place.address_components, (c) => {
        _.each(c.types, (t) => {
            if (t === "country" || t === "administrative_area_level_1") {
                addressComponentMap[t] = c.short_name;
            } else {
                addressComponentMap[t] = c.long_name;
            }
        })
    });
    const address = {
        formatted_address: place.formatted_address,
        place_id: place.place_id,
        country: addressComponentMap.country,
        zip: addressComponentMap.postal_code,
        state: addressComponentMap.administrative_area_level_1,
        city: addressComponentMap.locality || addressComponentMap.administrative_area_level_2,
        address_line_1: `${addressComponentMap.street_number || ""} ${addressComponentMap.route || ""}`.trim(),
        address_line_2: addressComponentMap.subpremise,
    }
    return address;
}

export function getFormattedAddressForAddress(address) {
    if (!address) {
        return null;
    } else if (!_.isEmpty(address.formatted)) {
        return address.formatted;
    } else {
        return _.filter([
            address.address_line_1,
            address.address_line_2,
            address.city,
            address.state,
            address.zip,
            address.country
        ], (a) => a !== null && a !== "").join(", ");
    }
}

export function getLabelForCouponType(type) {
    if (type === "DISCOUNT_PERCENT_COUPON") {
        return "Discount Percent";
    } else if (type === "DISCOUNT_AMOUNT_COUPON") {
        return "Discount Amount";
    } else if (type === "CREDIT_AMOUNT_COUPON") {
        return "Credit Amount";
    } else if (type === "CREDIT_UNITS_COUPON") {
        return "Credit Units";
    }
    return type;
}

export function getDescriptionForCoupon(coupon, products, productPricings, billableItems) {
    let couponDescription = coupon.name;
    if (coupon.type === "DISCOUNT_AMOUNT_COUPON") {
        couponDescription = `${ currencyFormatFromPrice(coupon.amount) }`;
    } else if (coupon.type === "DISCOUNT_PERCENT_COUPON") {
        const capDescription = coupon.cap.value_in_cents > 0 ? ` (Max ${currencyFormatFromPrice(coupon.cap)})` : "";
        couponDescription = `${ coupon.percent * 100 }%${capDescription}`;
    } else if (coupon.type === "CREDIT_AMOUNT_COUPON") {
        couponDescription = `${ currencyFormatFromPrice(coupon.amount) }`;
    } else if (coupon.type === "CREDIT_UNITS_COUPON") {
        const item = _.find(billableItems, (i) => i.id === coupon.item_id);
        const itemDescription = _.isNil(item) ? "" : ` (${item.name})`;
        couponDescription = `${coupon.units} Units${itemDescription}`;
    }
    let restrictionDescription = null;
    if (!_.isNil(coupon.product_id)) {
        const product = _.find(products, (p) => p.id === coupon.product_id);
        if (product) {
            restrictionDescription = `Applies to product: ${product.name}`;
        }
    }
    if (!_.isNil(coupon.product_pricing_id)) {
        const pricing = _.find(productPricings, (p) => p.id === coupon.product_pricing_id);
        if (pricing) {
            restrictionDescription = `Applies to pricing: ${pricing.name}`;
        }
    }
    return (<div>
        <span>{ couponDescription }</span><br/>
        {
            !_.isNil(restrictionDescription) &&
                <span className="caption">{ restrictionDescription }</span>
        }
    </div>)
}

export function getDescriptionForDiscount(discount, getCompanySpecificUrl, hideAdditionalDetails=false, displayExpiration=false) {
    let label = discount.type;
    let additionalDescription = null;
    if (discount.item_pricing_id) {
        if (discount.item_pricing) {
            additionalDescription = <span className="italic">{discount.item_pricing.name || discount.item_pricing.description}</span>
        }
    } else if (discount.product_pricing_id) {
        if (discount.product_pricing) {
            additionalDescription = <span className="italic">{discount.product_pricing.name || discount.product_pricing.description}</span>
        }
    } else if (discount.product_id) {
        if (discount.product) {
            additionalDescription = <span className="italic">{discount.product.name}</span>
        }
    }
    let restrictionDescription = null;
    if (discount.subscription_id && additionalDescription) {
        restrictionDescription = <span>for {additionalDescription} in <Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    } else if (discount.subscription_id) {
        restrictionDescription = <span>for <Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    } else if (additionalDescription) {
        restrictionDescription = <span>for {additionalDescription}<Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    }
    if (hideAdditionalDetails) {
        restrictionDescription = null;
    }

    if (discount.type === "DISCOUNT_AMOUNT") {
        return (
            <div className="flex flex-col gap-2">
                <span className="flex items-center text-nowrap leading-5 font-medium">
                    <div className="grow-0 py-1 px-2 align-middle text-gray6 border border-gray3 rounded-lg flex items-center gap-1">
                        {currencyFormatFromPrice(discount.amount)}
                    </div>
                </span>
                {restrictionDescription && (
                    <>
                        <span>{restrictionDescription}</span>
                    </>
                )}
                {displayExpiration && discount.expiration_date && (
                    <>
                        <span className="caption">Expires on {moment(discount.expiration_date).format("MMM D, YYYY h:mma")}</span>
                    </>
                )}
            </div>
        );
    } else if (discount.type === "DISCOUNT_PERCENT") {
        return (
            <div className="flex flex-col gap-2">
                <span className="flex items-center text-nowrap leading-5 font-medium">
                    <div className="grow-0 py-1 px-2 align-middle text-gray6 border border-gray3 rounded-lg flex items-center gap-1">
                        {discount.percent * 100}%
                    </div>
                </span>
                {discount.cap.value_in_cents > 0 && (
                    <>
                        <span className="caption">Max: {currencyFormatFromPrice(discount.cap)}</span>
                    </>
                )}
                 {restrictionDescription && (
                    <>
                        <span>{restrictionDescription}</span>
                    </>
                )}
                {displayExpiration && discount.expiration_date && (
                    <>
                        <span className="caption">Expires on {moment(discount.expiration_date).format("MMM D, YYYY h:mma")}</span>
                    </>
                )}
            </div>
        );
    }
    return <span>{ label }</span>
}

export function getDescriptionForCreditState(state) {
    if (state === "ACTIVE") {
        return (
            <Label.Success>Active</Label.Success>
        )
    } else if (state === "CONSUMED") {
        return (
            <Label.Warning>Consumed</Label.Warning>
        )
    } else if (state === "REVOKED") {
        return (
            <Label.Danger>Revoked</Label.Danger>
        )
    } else if (state === "EXPIRED") {
        return (
            <Label.Danger>Expired</Label.Danger>
        )
    }
}

export function getDescriptionForCreditLogAction(action) {
    if (action == "ISSUED") {
        return (
            <Label.Success>{_.capitalize(action)}</Label.Success>
        )
    } else if (action == "CONSUMED") {
        return (
            <Label.Warning>{_.capitalize(action)}</Label.Warning>
        )
    } else if (action == "EXPIRED") {
        return (
            <Label.Danger>{_.capitalize(action)}</Label.Danger>
        )
    } else if (action == "REVOKED") {
        return (
            <Label.Danger>{_.capitalize(action)}</Label.Danger>
        )
    }
}

export function getColorOptions() {
    return [
        { "value" : "#6d8bbf", "label" : "Blue", "isDefault" : true },
        { "value" : "#5484ed", "label" : "Bold blue"  },
        { "value" : "#a4bdfc", "label" : "Blue" },
        { "value" : "#afeeee", "label" : "Pale Aquamarine" },
        { "value" : "#7fffd4", "label" : "Aquamarine" },
        { "value" : "#46d6db", "label" : "Turquoise" },
        { "value" : "#add6a7", "label" : "Dry Green" },
        { "value" : "#7bd148", "label" : "Green" },
        { "value" : "#51b749", "label" : "Bold green" },
        { "value" : "#f9e79f", "label" : "Chiffon" },
        { "value" : "#fbd75b", "label" : "Yellow" },
        { "value" : "#ffb878", "label" : "Orange" },
        { "value" : "#ffa500", "label" : "Dark Orange" },
        { "value" : "#ff887c", "label" : "Red" },
        { "value" : "#dc2127", "label" : "Bold red" },
        { "value" : "#e1e1e1", "label" : "Gray" },
        { "value" : "#6d5cae", "label" : "Blueberry" },
        { "value" : "#4b0082", "label" : "Indigo" },
        { "value" : "#48b0f7", "label" : "Peacock" },
        { "value" : "#0da899", "label" : "Basil" },
        { "value" : "#c9a843", "label" : "Sun" },
        { "value" : "#fdbeb9", "label" : "Flamingo" },
        { "value" : "#f7460f", "label" : "Tangerine" },
        { "value" : "#b03e3b", "label" : "Maroon" },
        { "value" : "#626c75", "label" : "Graphite" },
        { "value" : "#e8daef", "label" : "Light Purple" },
        { "value" : "#ff00ff", "label" : "Fushia" },
        { "value" : "#808000", "label" : "Olive" },
    ];
}

const getConfigJson = (type, days, action) => {
    return {
        "interval_reference_date_type": type,
        "interval_from_date": days * 86400,
        "action_type": action
    }
}

export function getConfigForPaymentRetrySchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 6, "payment_retry"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 7, "payment_retry"),
            getConfigJson("invoice.due_at", 14, "payment_retry"),
            getConfigJson("invoice.due_at", 21, "payment_retry"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 3, "payment_retry"),
            getConfigJson("invoice.due_at", 7, "payment_retry"),
            getConfigJson("invoice.due_at", 10, "payment_retry"),
            getConfigJson("invoice.due_at", 12, "payment_retry"),
            getConfigJson("invoice.due_at", 14, "cancel_subscription"),
        ]
    }
    return [];
}

export function getConfigForInvoiceReminderSchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("invoice.due_at", -3, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 1, "notification"),
            getConfigJson("invoice.due_at", 3, "notification"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("invoice.due_at", -7, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 7, "notification"),
            getConfigJson("invoice.due_at", 14, "notification"),
            getConfigJson("invoice.due_at", 21, "notification"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("invoice.due_at", -7, "notification"),
            getConfigJson("invoice.due_at", -3, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 1, "notification"),
            getConfigJson("invoice.due_at", 3, "notification"),
            getConfigJson("invoice.due_at", 7, "notification"),
            getConfigJson("invoice.due_at", 14, "notification"),
            getConfigJson("invoice.due_at", 20, "notification"),
            getConfigJson("invoice.due_at", 21, "cancel_subscription"),
        ]
    }
    return [];
}

export function getConfigForContractReminderSchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("proposal.finalized_at", 3, "notification"),
            getConfigJson("proposal.finalized_at", 6, "notification"),
            getConfigJson("proposal.finalized_at", 9, "notification"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("proposal.finalized_at", 5, "notification"),
            getConfigJson("proposal.finalized_at", 10, "notification"),
            getConfigJson("proposal.finalized_at", 15, "notification"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("proposal.finalized_at", 3, "notification"),
            getConfigJson("proposal.finalized_at", 6, "notification"),
            getConfigJson("proposal.finalized_at", 9, "notification"),
            getConfigJson("proposal.finalized_at", 12, "notification"),
            getConfigJson("proposal.finalized_at", 15, "notification"),
        ]
    }
    return [];
}

export function getConfigForCardExpirySchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 2, "notification"),
            getConfigJson("expired.start_of_month", 6, "notification"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 1, "notification"),
            getConfigJson("expired.start_of_month", 7, "notification"),
            getConfigJson("expired.start_of_month", 14, "notification"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 1, "notification"),
            getConfigJson("expired.start_of_month", 3, "notification"),
            getConfigJson("expired.start_of_month", 7, "notification"),
            getConfigJson("expired.start_of_month", 10, "notification"),
            getConfigJson("expired.start_of_month", 14, "notification"),
            getConfigJson("expired.start_of_month", 17, "notification"),
            getConfigJson("expired.start_of_month", 20, "notification"),
        ]
    }
    return [];
}

export function renderCampaignConfigRow(row) {
    let description = "";
    if (row.action_type === "payment_retry") {
        description = "Retry, with email"
    } else if (row.action_type === "notification") {
        description = "Email customer"
    } else if (row.action_type === "cancel_subscription") {
        description = "Cancel subscription"
    } else if (row.action_type === "unpaid_subscription") {
        description = "Mark as unpaid"
    } else if (row.action_type === "admin_notification") {
        description = "Notify account owner"
    }

    if (row.infinite_recurring) {
        return `Every ${row.interval_from_date/86400} days, ${description}`
    } else {
        if (row.interval_from_date >= 0) {
            return `Day ${row.interval_from_date/86400}, ${description}`
        } else {
            return `${-1*row.interval_from_date/86400} day(s) before, ${description}`
        }
    }
}

export function returnHTMLForVariable(fullString, variable, one, two) {
    if (_.isEmpty(fullString)) {
        return "";
    }
    let name = variable.trim();
    let extraClass = "";
    let extraContent = ``;
    if (variable === "Pricing") {
        extraClass = "maple-variable-pricing";
        name = "Pricing Table";
        extraContent = `<img src="/images/grid.svg"></img>`;
    } else if (variable === "SignatoryInitials") {
        extraClass = "maple-variable-initials";
        name = "Initials";
    } else if (variable === "SignatorySignature") {
        extraClass = "maple-variable-signature";
        name = "Sign Here";
        extraContent = `<br/><i class="fa fa-signature"></i>`;
    } else if (variable === "Custom") {
        let matches = fullString.match(' *index *.Custom *&quot;(.*)&quot; *')
        if (!matches) {
            matches = fullString.match(' *index *.Custom *"(.*)" *')
        }
        if (matches) {
            name = `Custom:${matches[1]}`
        } else {
            name = `Custom`
        }
        variable = name
    }
    return `<span style="color:black" contenteditable="false" class="fr-deletable maple-replace maple-variable ${extraClass}" data-key="${variable}" data-show-language-popup="false">${name}${extraContent}</span>`
    // return `<span class="maple-replace"><abbr contenteditable="false" class="maple-variable fr-deletable ${extraClass}" data-key="${variable}" data-show-language-popup="false">${name}${extraContent}</abbr></span>`
}

export function convertVariablesIntoHtml(data, counterSignatories, signatories) {
    let convertedData = data;
    if (convertedData) {
        convertedData = data.replaceAll(/{{[^.]*.([A-Za-z0-9]*)[^.]*}}/g, returnHTMLForVariable);
    } else {
        return convertedData;
    }

    let currSigner = 1
    if (signatories) {
        for (let i = 0; i < signatories.length; i++) {
            signatories[i].position = signatories[i].position ? signatories[i].position : 0;
            switch(signatories[i].position) {
                case 0:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("SignatoryInitials", "SignatoryInitials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("SignatorySignature", "SignatorySignature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("SignatorySignatureDate", "SignatorySignatureDate"))
                    currSigner += 1
                    break
                case 1:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("Signatory2Initials", "Signatory2Initials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("Signatory2Signature", "Signatory2Signature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("Signatory2SignatureDate", "Signatory2SignatureDate"))
                    currSigner += 1
                    break
                case 2:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("Signatory3Initials", "Signatory3Initials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("Signatory3Signature", "Signatory3Signature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("Signatory3SignatureDate", "Signatory3SignatureDate"))
                    currSigner += 1
                    break
                default:
                    break
            }
        }
    } else {
        convertedData = convertedData.replaceAll("[initial|req|signer1]", returnHTMLForVariable("SignatoryInitials", "SignatoryInitials"))
        convertedData = convertedData.replaceAll("[sig|req|signer1]", returnHTMLForVariable("SignatorySignature", "SignatorySignature"))
        convertedData = convertedData.replaceAll("[date|req|signer1]", returnHTMLForVariable("SignatorySignatureDate", "SignatorySignatureDate"))
    }

    if (counterSignatories) {
        for (let i = 0; i < counterSignatories.length; i++) {
            switch (counterSignatories[i].position) {
                case 0:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory1Initials", "CounterSignatory1Initials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory1Signature", "CounterSignatory1Signature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory1SignatureDate", "CounterSignatory1SignatureDate"))
                    currSigner += 1
                    break
                case 1:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory2Initials", "CounterSignatory2Initials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory2Signature", "CounterSignatory2Signature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory2SignatureDate", "CounterSignatory2SignatureDate"))
                    currSigner += 1
                    break
                case 2:
                    convertedData = convertedData.replaceAll(`[initial|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory3Initials", "CounterSignatory3Initials"))
                    convertedData = convertedData.replaceAll(`[sig|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory3Signature", "CounterSignatory3Signature"))
                    convertedData = convertedData.replaceAll(`[date|req|signer${currSigner}]`, returnHTMLForVariable("CounterSignatory3SignatureDate", "CounterSignatory3SignatureDate"))
                    currSigner += 1
                    break
                default:
                    break
            }
        }
    }
    return convertedData;
}

export function convertHtmlIntoVariables(data) {
    let wrappedData = `<div>${data}</div>`;
    let result = wrappedData;
    const jdata = $(wrappedData);
    jdata.find(".maple-replace").each(function(index) {
        let vvv = $(this).attr('data-key');
        let processedValue = vvv;
        if (_.startsWith(vvv, "Custom:")) {
            processedValue = `index .Custom "${vvv.substring(7)}"`
        } else {
            processedValue = `.${vvv}`
        }
        result = result.replace($(this).prop('outerHTML'), "{{"+processedValue+"}}")
    });
    result = result.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>', "")
    return result;
}

export function findCustomVariables(content) {
    if (!content) {
        return []
    }
    const matches = content.matchAll("{{([^}]*)}}");
    let customVariables = [];
    if (matches) {
        for (const m of matches) {
            if (m[1].includes(".Custom ")) {
                const vMatch = m[1].match(" *index *\.Custom[ ]*[\\\"]*([^\\\"]+)[\\\"]+ *")
                if (vMatch) {
                    customVariables.push(vMatch[1])
                }
            }
        }
    }
    return _.uniq(customVariables)
}

export function findNumberOfCounterSignatories(content) {
    if (!content) {
        return 0
    }
    let count = 0

    const matchesOne = content.match(/{{\.(CounterSignatory1\w+)}}/g);
    if (matchesOne && matchesOne.length > 0) {
        count += 1
    }

    const matchesTwo = content.match(/{{\.(CounterSignatory2\w+)}}/g);
    if (matchesTwo && matchesTwo.length > 0) {
        count += 1
    }

    const matchesThree = content.match(/{{\.(CounterSignatory3\w+)}}/g);
    if (matchesThree && matchesThree.length > 0) {
        count += 1
    }

    return count
}

export function findNumberOfSignatories(content) {
    if (!content) {
        return 0
    }

    let count = 0

    const matchesOne = content.match(/{{\.(Signatory\w+)}}/g);
    if (matchesOne && matchesOne.length > 0) {
        count += 1
    }

    const matchesTwo = content.match(/{{\.(Signatory2\w+)}}/g);
    if (matchesTwo && matchesTwo.length > 0) {
        count += 1
    }

    const matchesThree = content.match(/{{\.(Signatory3\w+)}}/g);
    if (matchesThree && matchesThree.length > 0) {
        count += 1
    }

    return count
}

export function validateSignatories(signatories) {
    if (_.isEmpty(signatories)) {
        return "At least one signatory must be specified."
    }

    for (const key in signatories) {
        if (!signatories[key].name || !signatories[key].email) {
            return "All provided signatories must have both a name and email address."
        }
    }
    return null
}

export function renderCustomVariableInput(cv, i, availableCustomVariables) {
    const variable = _.find(availableCustomVariables, acv => acv.key ===  cv);
    if (variable) {
        if (variable.type === "string") {
            return <BaseForm.Input key={i} colSpan={"5"} type={"text"} name={`custom_variables.${cv}`} label={variable.name}/>
        } else if (variable.type === "enum") {
            const options = _.map(variable.possible_values, (c) => {
                return {
                    value: c,
                    label: c
                }
            })
            return <BaseForm.Input
                key={i} colSpan={"5"} type={"select"} name={`custom_variables.${cv}`} label={variable.name}
                options={options} showSearch={false}
            />
        }
    }
}

export function isPaymentMethodExpiring(method) {
    if ((moment().month() + 1) === method.exp_month &&
        moment().year() === method.exp_year) {
        return true;
    }
    return false;
}

export function isPaymentMethodExpired(method) {
    if (moment().year() > method.exp_year) {
        return true;
    }
    if (moment().year() === method.exp_year && (moment().month() + 1) > method.exp_month) {
        return true;
    }
    return false;
}

export function getInvoicePaymentTermOptions() {
    return [
        { value: 0, label: "Due Immediately" },
        { value: 30, label: "Net 30" },
        { value: 45, label: "Net 45" },
        { value: 60, label: "Net 60" },
        { value: 90, label: "Net 90" },
        { value: "custom", label: "Custom" },
    ]
}

export function getInvoicePaymentTermDescription(dueDateFrom) {
    if (_.isNil(dueDateFrom)) {
        return null;
    }
    const options = getInvoicePaymentTermOptions();
    const selectedOption = _.find(options, (op) => String(op.value) === String(dueDateFrom));
    if (!selectedOption) {
        return `${dueDateFrom} days`;
    } else {
        return selectedOption.label;
    }
}

export function getInvoicePaymentTermFromDueDateFromCreation(value) {
    if (!value) {
        return null;
    }
    const options = getInvoicePaymentTermOptions();
    const selectedOption = _.find(options, (op) => String(op.value) === String(value));
    if (!selectedOption) {
        return "custom"
    } else {
        return value;
    }
}

export function getPaymentLinkUrl(paymentLink, getCompanySpecificUrl) {
    const relativeUrl = getCompanySpecificUrl(`/buy/${paymentLink.id}`);
    const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
    return `${baseUrl}${relativeUrl}`
}

function getDescriptionForItem(item, comparatorType, propertyMap, valueMap) {
    if (item.item_type === "VALUE") {
        if (comparatorType === "IN") {
            // Treat this as a comma separate list.
            const values = item.value.split("|");
            return <div>
                {
                    _.map(values, (v, i) =>
                        <span key={i} className="font-normal">{ i !== 0 ? ", ": ""}{ _.isUndefined(valueMap[v]) ? v: valueMap[v] }</span>
                    )
                }
            </div>
        } else {
            return <span className="font-normal">{ _.isUndefined(valueMap[item.value]) ? item.value: valueMap[item.value] }</span>;
        }
    } else if (item.item_type === "PROPERTY") {
        return <span className="font-semibold">{ _.isUndefined(propertyMap[item.property]) ? item.property: propertyMap[item.property] }</span>;
    } else if (item.item_type === "COMPARATOR") {
        return <span>({ getDescriptionForComparator(item.comparator, propertyMap, valueMap) })</span>;
    }
}

function getDescriptionForComparator(comparator, propertyMap, valueMap) {
    const comparatorTypeDescription = {
        "OR": "Or",
        "AND": "And",
        "NOR": "Nor",
        "XOR": "Xor",
        "NOT": "Not",
        "EQUAL": "=",
        "IN": "in",
        "NOT_EQUAL": "!=",
        "GREATER_THAN": ">",
        "GREATER_THAN_EQUAL": ">=",
        "LESS_THAN": "<",
        "LESS_THAN_EQUAL": "<=",
        "HAS_SUBSTRING": "contains",
        "NOT_HAS_SUBSTRING": "doesn't contain",
        "DAY_EQUAL": "is same day",
        "DAY_MONTH": "is same month",
    }

    const leftItem = getDescriptionForItem(comparator.left_item, comparator.type, propertyMap, valueMap);
    const rightItem = getDescriptionForItem(comparator.right_item, comparator.type, propertyMap, valueMap);
    const ctype = comparatorTypeDescription[comparator.type];
    return <>{leftItem} {ctype} {rightItem}</>
}

export function getDescriptionForRule(rule, propertyMap={}, valueMap={}, index) {
    const ruleJson = rule;
    return getDescriptionForComparator(ruleJson.comparator, propertyMap, valueMap);
}

export function getApprovalRulePropertyValues() {
    return [
        { value: "ACV", label: "ACV" },
        { value: "TCV", label: "TCV" },
        { value: "MAX_DISCOUNT_PERCENTAGE", label: "Max % discount" },
        { value: "MAX_AMOUNT_PERCENTAGE", label: "Max $ discount" },
        { value: "CONTRACT_CONTENT_CHANGED", label: "Contract Content Changed", type: "BOOL" },
    ]
}

export function getApprovalRulePropertyKeys() {
    return [
        { Key: "ACV", Type: "Number" },
        { Key: "TCV", Type: "Number" },
        { Key: "MAX_DISCOUNT_PERCENTAGE", Type: "Number" },
        { Key: "MAX_AMOUNT_PERCENTAGE", Type: "Number" },
        { Key: "CONTRACT_CONTENT_CHANGED", Type: "Boolean" },
    ]
}

export function getApprovalRulePropertyMap() {
    const propertyValues = getApprovalRulePropertyValues();
    const propertyMap = {};
    _.each(propertyValues, (pv) => {
        propertyMap[pv.value] = pv.label;
    })
    return propertyMap
}

export function getErrorMessageForTaxCalculationErrors(reason) {
    if (reason === "ERROR_INVALID_CUSTOMER_ADDRESS") {
        return "Customer address invalid"
    } else if (reason === "MISSING_CUSTOMER_ADDRESS") {
        return "Customer address missing"
    } else if (reason === "MISSING_COMPANY_ADDRESS") {
        return "Company address missing"
    } else if (reason === "ERROR_PRODUCT_TAX_CODE_MISSING") {
        return "Product tax code(s) are missing on some products"
    } else {
        return "Error calculating taxes"
    }
}

export function formatTerm(term) {
    const isPlural = term.count > 1;
    if (term.frequency === "ONETIME") {
        return "One Time";
    }
    return `${ term.count } ${ getLabelForSubscriptionLength(term.frequency) }${ isPlural ? "s": "" }`;
}

export function getUnitOfTimeFromFrequency(f) {
    const m = {
        "YEAR": "years",
        "MONTH": "months",
        "WEEK": "weeks",
        "QUARTER": "quarters",
        "BI_ANNUAL": "6 months",
        "DAY": "days",
        "HOUR": "hours",
    }
    return m[f];
}



export function cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
};

export function getPaymentDataFromPaymentMethod(method) {
    if (!method) {
        return null;
    }

    if (method.payment_provider_key === "stripe") {
        return {
            payment_provider_key: method.payment_provider_key,
            stripe: {
                payment_method_id: method.id,
                mandate_id: method.mandate_id
            }
        }
    } else if (method.payment_provider_key === "razorpay") {
        return {
            payment_provider_key: method.payment_provider_key,
            razorpay: {
                token_id: method.id
            }
        }
    }
}

export function removeHtmlTags(text) {
    if (!text) {
        return "";
    }
    return text.replace(/<[^>]*(>|$)| |‌|»|«|>|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt/g, '');
}

export function getCustomTaxRuleCustomerKeys() {
    return [
        { Key: "address_state", Type: "Text" },
        { Key: "address_country", Type: "Text" },
        { Key: "address_zip", Type: "Text" },
    ]
}

export function getCustomTaxRuleCustomerValues() {
    return [
        { value: "address_state", label: "Customer State/Province" },
        { value: "address_country", label: "Customer Country" },
        { value: "address_zip", label: "Customer Zip/Postal Code" },
    ]
}

export function getCustomTaxRuleProductValues() {
    return [
        { value: "id", label: "Product" },
    ]
}

export function getCustomTaxRulePropertyMap() {
    const propertyValues = [...getCustomTaxRuleCustomerValues(), ...getCustomTaxRuleProductValues()];
    const propertyMap = {};
    _.each(propertyValues, (pv) => {
        propertyMap[pv.value] = pv.label;
    })
    return propertyMap
}

export function formatEventString(str) {
    if (str === "contract.signed") {
        return "Contract Externally Signed"
    }
    let formatted = _.split(str, ".").join(" ")
    formatted = _.split(formatted, "_").join(" ")
    formatted = _.startCase(formatted)
    return formatted;
}

export function getCsvImportColumns(type) {
	if (type === 'CUSTOMER') {
		return [
			{
				name: 'Contact Name',
				key: 'name',
				required: false,
				example: 'John',
			},
			{
				name: 'Contact Email',
				key: 'email',
				required: true,
				example: 'john@example.com',
			},
			{
				name: 'Organization Name',
				key: 'organization_name',
				required: false,
				example: "John's Org",
			},
			{
				name: 'External Identifier',
				key: 'external_identifier',
				required: true,
				example: '1111-1111',
			},
			{
				name: 'Billing Emails',
				key: 'billing_emails',
				required: false,
				example: 'billing@example.com',
			},
			{
				name: 'Phone Number',
				key: 'phone_number',
				required: false,
				example: '123-456-7890',
			},
		];
	} else if (type === 'BILLABLE_EVENT') {
		return [
			{
				name: 'Transaction ID',
				key: 'transaction_id',
				required: true,
				example: '1111-1111',
			},
			{
				name: 'Maple Customer ID',
				key: 'customer_id',
				required: true,
				example: 'cus_123456789',
			},
			// {
			//     name: "External Customer Identifier",
			//     key: "customer_identifier",
			//     required: false,
			// },
			{
				name: 'Item ID',
				key: 'item_id',
				required: true,
				example: 'item_123456789',
			},
			{
				name: 'Event Timestamp',
				key: 'event_timestamp',
				required: true,
				example: '2024-11-21T15:37:44-05:00',
			},
		];
	}
}

export function debounceAwaitFunction(f, interval) {
    let timer = null;

    return (...args) => {
        clearTimeout(timer);
        return new Promise((resolve) => {
            timer = setTimeout(
                () => resolve(f(...args)),
                interval,
            );
        });
    };
}

export function renderDescriptionForRecognitionSchedule(itemPricing) {
    const recognitionSchedule = itemPricing.recognition_schedule;
    if (recognitionSchedule.recognition_schedule_type === "IMMEDIATE") {
        return <span>Immediately</span>
    } else if (recognitionSchedule.recognition_schedule_type === "EVENLY") {
        return <span>Evenly</span>
    } else if (recognitionSchedule.recognition_schedule_type === "CUSTOM") {
        return <div className="flex flex-col gap-1">
            <span>Custom Schedule</span>
            <span>
                {
                    _.map(recognitionSchedule.amount_per_period, (p, a) =>
                        <span key={a}>
                            { currencyFormatFromPrice({ value_in_cents: p, currency: itemPricing.base_price.currency }) }
                            { a !== recognitionSchedule.amount_per_period.length - 1 ? ", ": "" }
                        </span>
                    )
                }
            </span>
        </div>
    }
}

export function getNumberOfMonths(frequency, count) {
    const frequencyToMonths = {
        "DAY": 1 / 30,
        "WEEK": 1 / 4,
        "BI_MONTH": 1 / 2,
        "MONTH": 1,
        "QUARTER": 3,
        "YEAR": 12,
        "BI_ANNUAL": 6,
        "ONETIME": 0
    };

    if (!frequencyToMonths.hasOwnProperty(frequency)) {
        throw new Error("Invalid frequency");
    }

    return frequencyToMonths[frequency] * count;
}
