import $ from "jquery";
import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import React, {createRef, Fragment} from 'react';
import classnames from 'classnames';
import {Listbox, Transition} from '@headlessui/react'
import { useState, useEffect, useMemo } from 'react';
import { FormControl } from 'react-bootstrap';
const _ = require("lodash");

function SingleSelectDropdown(props) {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [preselectedId, setPreselectedId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [query, setQuery] = useState('');
    const [alignDropdown, setAlignDropdown] = useState("end");
    const searchRef = createRef();
    const idField = props.idField || 'id';
    const labelField = props.labelField || 'label';

    useEffect(() => {
        const pitems = [...props.items];
        setItems(_.filter(pitems, (pi) => !_.isUndefined(pi)));
    }, [props.items, idField, labelField, props.allId, props.showAll]);

    useEffect(() => {
        setSelectedId(props.selectedId);
        if (!props.menuOnly && props.onSelect && (!_.isEqual(props.selectedId, selectedId))) {
            const sid = props.selectedId;
            setTimeout((originalSelectedId) => {
                setSelectedId((prevSelectedId) => {
                    if (originalSelectedId === prevSelectedId) {
                        setPreselectedId(prevSelectedId);
                    }
                    return prevSelectedId;
                })
            }, 1000, sid);
        }
    }, [props.selectedId]);

    useEffect(() => {
        if (preselectedId) {
            props.onSelect(preselectedId);
        }
    }, [preselectedId]);

    useEffect(() => {
        if (!_.isNil(props.alignDropdown)) {
            setAlignDropdown(props.alignDropdown);
        }
    }, [props.alignDropdown]);

    useEffect(() => {
        if (_.isEmpty(items)) {
            return;
        }
        setSelectedId((prevSelectedId) => {
            const selectedItem = _.find(items, (i) => ""+i[idField] === ""+prevSelectedId);
            setSelectedItem(selectedItem);
            return prevSelectedId;
        });
    }, [selectedId, items, idField]);

    useEffect(() => {
        setFilteredItems(_.filter(items, (item) => {
            if (!_.isNil(query) && !_.isEmpty(query)) {
                if (item[labelField].toLowerCase().includes(query.toLowerCase())) {
                    return true
                }
                if (item[idField] && item[idField].toLowerCase().includes(query.toLowerCase())) {
                    return true
                }
                return false
            } else if (item) {
                return true
            } else {
                return false
            }
        }))
    }, [items, query]);

    const onSelect = (eventKey) => {
        if (!props.menuOnly) {
            setSelectedId(eventKey);
        }
        if (props.onSelect) {
            props.onSelect(eventKey);
        }
    }

    const onToggle = (show, event, one, two) => {
        if (event && event.originalEvent) {
            event.originalEvent.stopPropagation();
        }
        if (show) {
            setTimeout(() => {
                const currentTop = $('.dropdown-menu.show ul').scrollTop();
                const firstActive = $('.dropdown-menu.show .dropdown-item.active').first();
                if (firstActive.length > 0) {
                    const offset = firstActive.position().top;
                    $('.dropdown-menu.show ul').scrollTop(currentTop + offset - 100);
                }
            }, 30);
        }
    }

    const getMenuPlacementClass = () => {
        return props.menuPlacement === "top" ? "bottom-dropdown-spacing" : "top-dropdown-spacing";
    };

    return (
        <Listbox name={props.name} as="div" className={classnames("relative inline-block text-left text-sm select-no-outline", props.className)}
                 value={selectedItem && selectedItem[idField]} onChange={onSelect} disabled={props.disabled}>
            {({ open, close }) => (
                <div className="relative w-full">
                    <Listbox.Button className={classnames("h-full px-3 flex flex-row items-center text-gray-700 hover:text-gray-900 focus:outline-none outline-none w-full", props.buttonClassName)} disabled={props.disabled}>
                        <span className="sr-only">Open options</span>
                        <div className={classnames("grow truncate", props.align === "end" ? "text-end": "text-start")}>
                            { selectedItem && selectedItem[labelField] }
                        </div>
                        {!props.removeextraspace && <div className="w-[20px]"/>}
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className={getMenuPlacementClass()}
                    >
                        <Listbox.Options className={classnames(props.fullWidth ? "w-full": "max-w-xl", alignDropdown === "end" ? "right-0": "left-0", "mt-0.5 absolute z-10 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm")}>
                            {
                                props.showSearch &&
                                    <div className="border-b border-gray-100 mb-1">
                                        <FormControl autoFocus className="select-search px-3" placeholder="Start typing"
                                             onChange={(e) => setQuery(e.target.value)} value={query} />
                                    </div>
                            }
                            {
                                props.showSearch && filteredItems.length === 0 &&
                                    <div className="px-3 py-2">
                                        <span className="text-gray-400">No matches</span>
                                    </div>
                            }
                            {filteredItems.map((item, index) => {
                                if (item.isDivider) {
                                    return (<hr key={index} className="my-1 bg-gray-400"/>);
                                } else {
                                    return (<Listbox.Option
                                        key={index}
                                        className={({active}) =>
                                            `relative cursor-default select-none ${
                                                active ? 'bg-gray-100 text-gray-900 hover:text-gray-900' : (item.disabled ? 'text-gray-400' : 'text-gray-700 hover:text-gray-700')
                                            }`
                                        }
                                        disabled={item.disabled}
                                        value={item[idField]}>
                                        {({selected}) => (
                                            <div
                                                className={classnames("py-2 px-3", selected ? 'bg-gray-100 text-gray-900 hover:text-gray-900' : '')}>
                                            <span className={classnames("block truncate font-normal")}>
                                                {item[labelField]}
                                            </span>
                                            </div>
                                        )}
                                    </Listbox.Option>)
                                }
                            })}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}

export default SingleSelectDropdown;
