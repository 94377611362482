import classnames from "classnames";

const PoweredByMaple = ({ light }) => {
    return (
        <span className={classnames("flex items-center font-medium", light ? "text-white" : "text-[#414651]")}>
            Powered by&nbsp;
            <img className="w-[76px] pb-[2px] inline" src={light ? "/logo-medium-light.svg" : "/logo-medium.svg" } alt="maple-logo-small" />
        </span>
    )  
};

export default PoweredByMaple;