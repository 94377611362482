import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import CommissionPlanCreate from './CommissionPlanCreate';
import classnames from "classnames";
const _ = require('lodash');

function CommissionPlans() {
	const [plans, setPlans] = useState([]);
	const [loading, setLoading] = useState(true);
	const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const [sort, setSort] = useState('createdAtDesc');
	const [fromKey, setFromKey] = useState(null);
	const [meta, setMeta] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		setPageTitle(`Commission Plans`);
	}, []);

	const onSearch = (restart = true) => {
		const limit = 50;
		const params = {
			sort_key: sort || 'createdAtDesc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			include_meta: restart,
		};
		serverPost(getApiUrl('/commission/plan/find'), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setPlans(results);
				} else {
					setPlans(_.concat(plans, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setLoading(false);
				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
		});
	};

	useEffect(() => {
		onSearch();
	}, []);

	const getPlanTypeLabel = (type) => {
		const types = {
			FLAT_PERCENTAGE: 'Flat Percentage',
			QUOTA_BASED: 'Quota Based',
			VALUE_BASED: 'Value Based',
		};
		return types[type] || type;
	};

	const getPayoutEventLabel = (event) => {
		const events = {
			CONTRACT_EXECUTED: 'On Contract Execution',
			FIRST_INVOICE_PAID: 'On First Invoice Paid',
			INVOICE_PAID: 'On Invoice Paid',
		};
		return events[event] || event;
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={plans.length}
				>
					<Section
						title='Commission Plans'
						loading={loading}
						actions={[
							{
								variant: 'primary',
								icon: 'fa-plus',
								label: 'Create Plan',
								link: getCompanySpecificUrl('/commissions/plans/create'),
							},
						]}
					>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} />
							<MapleTable.Content>
								<thead>
									<tr>
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='createdAtAsc'
											sortKeyDown='createdAtDesc'
											currentSort={sort}
										>
											Created Date
										</SortableTableHeader>
										<MapleTable.TH>Name</MapleTable.TH>
										<MapleTable.TH>Plan Type</MapleTable.TH>
										<MapleTable.TH>Payout Event</MapleTable.TH>
										<MapleTable.TH>Commission Rules</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{plans.map((plan, i) => (
										<MapleTable.TR
											key={i}
											className={classnames('cursor-pointer', 'hover:bg-gray-100',)}
											href={getCompanySpecificUrl(`/commissions/plan/${plan.id}`)}
										>
											<td>{moment(plan.created_at).format('MMM D, YYYY')}</td>
											<td>{plan.name}</td>
											<td>{getPlanTypeLabel(plan.plan_type)}</td>
											<td>{getPayoutEventLabel(plan.payout_event)}</td>
											<td>
												{`${plan.rules.length} rules configured`}
											</td>
										</MapleTable.TR>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
			{/*<CommissionPlanCreate*/}
			{/*    show={showCreateModal}*/}
			{/*    onClose={() => setShowCreateModal(false)}*/}
			{/*    onSuccess={() => {*/}
			{/*        setShowCreateModal(false);*/}
			{/*        onSearch(true);*/}
			{/*    }}*/}
			{/*/>*/}
		</BaseContainer>
	);
}

export default CommissionPlans;
