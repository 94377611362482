import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseModal from '../modals/BaseTailwindModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useTranslation } from 'react-i18next';
import DealInput from "../DealInput";
const _ = require('lodash');

function SearchDealsModal(props) {
    const { t } = useTranslation('common');

    const onSubmit = (data) => {
        if (props.onDealSelected) {
            props.onDealSelected(data.deal);
        }
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header title={"Search deals from integrations"}/>
            <BaseForm onSubmit={onSubmit}>
                <BaseModal.Body>
                    <DealInput colSpan="12" required allowCreation />
                </BaseModal.Body>
                <BaseModal.Footer>
                    <SubmitButton variant={props.buttonVariant || "primary"} className={props.buttonClassName}>{ props.buttonTitle || "Confirm" }</SubmitButton>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default SearchDealsModal;
