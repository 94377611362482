import '../App.scss';
import React, {useContext, useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {getDefaultContractTemplates} from "../helpers/contracts";
import {PlusIcon} from "@heroicons/react/20/solid";
import {serverPost} from "../helpers/server";
import {useNavigate} from "react-router-dom";
import {BaseContext} from "../helpers/common";
import classnames from "classnames";
const _ = require("lodash");

function ExistingContractTemplates(props) {
    const navigate = useNavigate();
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showAddNew, setShowAddNew] = useState(true);
    const [existingTemplates, setExistingTemplates] = useState([]);

    useEffect(() => {
        setShowAddNew(props.showAddNew)
    }, [props.showAddNew]);

    useEffect(() => {
        setExistingTemplates(props.templates);
    }, [props.templates]);

    const onCreateNewTemplate = () => {
        navigate(getCompanySpecificUrl("/contract/template/create"));
    }

    const onSelectExistingTemplate = (template) => {
        let name = template.title;
        let count = 0;
        let nameAlreadyExists = false;
        do {
            // code block to be executed
            if (count > 0) {
                name = `${template.title} - (${count})`
            }
            nameAlreadyExists = !_.every(existingTemplates, (te) => {
                return te.name !== name
            })
            count = count + 1
        }
        while (nameAlreadyExists);

        navigate(getCompanySpecificUrl(`/contract/template/create?pid=${template.id}&name=${encodeURIComponent(name)}&next=${props.next}`))
    }

    return (
        <div className={classnames("flex flex-col", props.className)}>
            <div className="items-center justify-content-center text-center">
                <Row>
                    <span className="text-lg font-semibold">{ props.title }</span>
                </Row>
            </div>
            <div>
                <div className="mt-4 flex flex-row flex-wrap gap-4">
                    {
                        showAddNew &&
                            <div className="flex flex-col gap-1">
                                <div className="pt-1 cursor-pointer rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white" onClick={onCreateNewTemplate}>
                                    <div className="pointer-events-none p-2">
                                        <div className="contract-template-preview-wrapper">
                                            <div className="flex flex-row h-full justify-center items-center">
                                                <PlusIcon className="h-10 w-10 text-gray-400" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-sm font-normal text-gray-700">Create New</span>
                            </div>
                    }
                    {
                        _.map(getDefaultContractTemplates(), (template, j) =>
                            <div key={j} className="flex flex-col gap-1">
                                <div className="pt-1 cursor-pointer rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white" onClick={() => onSelectExistingTemplate(template)}>
                                    <div className="pointer-events-none p-2">
                                        <div className="contract-template-preview-wrapper">
                                            <iframe className="contract-template-preview-content" srcDoc={ template.template }/>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-sm text-center font-normal text-gray-700">{ template.title }</span>
                            </div>
                        )
                    }
                </div>
            </div>
            <Row>

            </Row>
        </div>
    );
}

export default ExistingContractTemplates;
