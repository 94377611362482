import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../common/buttons/SubmitButton';
import ProductPricingSelection from '../ProductPricingSelection';
import React, { useState, useEffect, useContext, useRef } from 'react';
import {BaseContext, getPlanLengthOptions, UserContext} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "../CustomerInput";
import {getAllProductPricingIdsInContract, getProductPricingIdsInPhase} from "../../helpers/subscriptions";
const _ = require('lodash');

function UpdateSubscriptionModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const { isSuperUser } = useContext(UserContext);
    const [subscription, setSubscription] = useState(null);
    const [isTrial, setIsTrial] = useState(false);
    const [initialFields, setInitialFields] = useState({
        start_date: moment(),
        term: {
            count: 1,
            frequency: "YEAR"
        }
    });
    const [productPricings, setProductPricings] = useState([]);
    const [preselectedProductPricingIds, setPreselectedProductPricingIds] = useState([]);
    const [timing, setTiming] = useState("IMMEDIATE");
    const [error, setError] = useState(null);
    const [nonItemPricingDiscounts, setNonItemPricingDiscounts] = useState([]);
    const [sort, setSort] = useState("createdAtDesc");
    const pricingsRef = useRef();

    useEffect(() => {
        if (_.isNil(props.subscription) || _.isEmpty(props.subscription) || !props.show) {
            setSubscription(null);
            return;
        }
        setSubscription(props.subscription);
    }, [props.subscription, props.discounts, props.show])

    useEffect(() => {
        if (!_.isNil(subscription)) {
            const fields = {
                ...subscription,
                phases: [{
                    product_pricing_ids: getProductPricingIdsInPhase(subscription),
                    config: _.keyBy(subscription.config_items, 'product_metric_pricing_id'),
                    discounts: {},
                    minimum_spend: subscription.minimum_spend && subscription.minimum_spend.value_in_cents/100,
                }],
            };
            if (subscription.minimum_spend && subscription.minimum_spend.value_in_cents > 0) {
                fields['minimum_spend'] = subscription.minimum_spend.value_in_cents/100
            }
            if (pricingsRef.current) {
                pricingsRef.current.setInitialData(fields);
            }
            if (props.discounts) {
                let xdiscounts = _.filter(props.discounts, d => _.isNil(d.item_pricing_id));
                _.each(xdiscounts, d => {
                    delete d['subscription']
                    delete d['product_pricing']
                })
                setNonItemPricingDiscounts(xdiscounts);
                fields.phases[0].discounts = _.mapValues(_.keyBy(props.discounts, "item_pricing_id"), (v) => {
                    return {
                        percentage: v.percent * 100,
                        amount: v.amount.value_in_cents / 100,
                        type: v.type,
                        expiration_date: v.expiration_date,
                    };
                });
            }
            if (subscription.trial) {
                if (subscription.auto_renews) {
                    fields.num_trial_days = moment(subscription.change_date).diff(moment(subscription.start_date), 'days');
                } else {
                    fields.num_trial_days = moment(subscription.end_date).diff(moment(subscription.start_date), 'days');
                }
            }
            setIsTrial(subscription.trial);
            setInitialFields(fields);
            setPreselectedProductPricingIds(getAllProductPricingIdsInContract(subscription));
        } else {
            setInitialFields({});
        }
    }, [subscription])

    useEffect(() => {
        if (props.show) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.update_date = moment();
                return newFields;
            });

            serverPost(getApiUrl(`/product_pricings/batch`), { ids: preselectedProductPricingIds }).then((res) => {
                if (res) {
                    setProductPricings(res)
                }
            });
        } else {
            setError(null);
        }
    }, [props.show, company, getApiUrl, preselectedProductPricingIds])

    const getNextInvoiceDate = () => {
        if (_.isNil(subscription) || _.isNil(subscription.next_invoice_date)) {
            return ""
        } else {
            return ` - ${moment(subscription.next_invoice_date).format("MMM D, YYYY h:mm:ssa")}`;
        }
    }

    const timingOptions = [
        { value: "IMMEDIATE", label: "Immediate" },
        { value: "PERIOD_END", label: `End of the period${ getNextInvoiceDate() }` },
        isSuperUser && { value: "CUSTOM", label: "Custom Date" }
    ]

    const prorateOptions = [
        { value: "NEXT", label: "Charge proration amount in next invoice" },
        { value: "IMMEDIATE", label: "Charge proration amount immediately" },
        { value: "NONE", label: `Do not prorate any charges` }
    ]

    const onFieldChange = (name, value) => {
        if (name === "timing") {
            setTiming(value);
        }
        pricingsRef.current.onFieldChange(name, value);
    }

    const updateSubscription = async (data, errorHandler) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
            pricingData.discounts = pricingData.discounts.concat(nonItemPricingDiscounts);
        }
        const subscriptionData = {
            ...pricingData,
            term: {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            },
            auto_renews: true,
            timing: data.timing,
            custom_change_date: data.custom_change_date,
            proration_type: data.proration_type,
            reset_billing_anchor: data.reset_billing_anchor,
        }
        if (data.trial) {
            subscriptionData.trial = data.trial;
            subscriptionData.trial_term = {
                frequency: 'DAY',
                count: data.num_trial_days,
            };
        }
        const res = await serverPost(getApiUrl(`/subscriptions/${subscription.id}/change`), subscriptionData, {}, errorHandler)
        if (res) {
            props.onClose(true);
        }
    }

    return (
        <BaseSideModal size="lg" {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateSubscription} >
                <BaseSideModal.Header title="Update Subscription"/>
                <BaseSideModal.Body>
                    <div>
                        {
                            !_.isNil(subscription) && _.isNil(subscription.change_config) && !_.isNil(subscription.cancel_config) &&
                                <Alert variant="warning">
                                    <p className="body2">Pending Cancellation</p>
                                    <p className="body1">Changing the subscription will remove the pending cancellation. Please review carefully before making a change.</p>
                                </Alert>
                        }
                        {
                            !_.isNil(subscription) && !_.isNil(subscription.change_config) &&
                                <Alert variant="warning">
                                    <p className="body2">Pending Change</p>
                                    <p className="body1">Changing the subscription will remove the pending change. Please review carefully before making a change.</p>
                                </Alert>
                        }
                        <Row>
                            <CustomerInput colSpan="6" defaultCustomer={subscription && subscription.customer} required />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" type="select" name="timing" label="Change Time" options={timingOptions} showSearch={false}/>
                            {
                                timing === "CUSTOM" &&
                                    <BaseForm.Input colSpan="6" type="date" name="custom_change_date" label="Change Date" minDate={moment()} includeTime={true} />
                            }
                        </Row>
                        {
                            timing === "IMMEDIATE" &&
                                <Row>
                                    <BaseForm.Input colSpan="6" type="switch" name="reset_billing_anchor" label="Reset Billing Anchor?" />
                                </Row>
                        }
                        <Row>
                            <BaseForm.Input colSpan="6" type="select" name="proration_type" label="Proration" options={prorateOptions} showSearch={false}/>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <BaseForm.InputGroup label="Term">
                                    <BaseForm.Number name="term.count" min="1" />
                                    <BaseForm.Divider />
                                    <BaseForm.SingleSelect name="term.frequency" options={getPlanLengthOptions()} showSearch={false}/>
                                </BaseForm.InputGroup>
                            </Col>
                            {
                                subscription && subscription.trial &&
                                    <Col lg="3" className="flex items-end">
                                        <BaseForm.Input
                                            type="switch" name="trial" label={"Free trial days"} outerInputClassName="grow"
                                        />
                                    </Col>
                            }
                            {
                                isTrial &&
                                <BaseForm.Input
                                    colSpan={3} type="number" name="num_trial_days" label="Trial Days"
                                    step="1" min="0" validations={{ required: true, gt: 0 }} />
                            }
                        </Row>
                        <br/>
                        <ProductPricingSelection
                            ref={pricingsRef} productPricings={productPricings} allowDiscounts={true}
                            requireRecurring allowMinimumSpend initialFields={initialFields}
                            setInitialFields={setInitialFields}/>
                        {
                            error &&
                                <div className="form-error-message">{ error }</div>
                        }
                    </div>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Update</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionModal;
