import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, downloadBlob} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import moment from "moment";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import Breadcrumb from "../../components/Breadcrumb";
import DateRangePicker from "../../components/common/daterangepicker/DateRangePicker";
import {serverPost} from "../../helpers/server";
import Columns from "../../components/Columns";
import {Row} from "react-bootstrap";
import Link from "../../components/Link";
import Notification from "../../components/Notification";
const _ = require('lodash');

function RecognizedRevenue(props) {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [selectedDateRange, setSelectedDateRange] = useState({
        start: moment().startOf('year'),
        end: moment().endOf('year'),
    });
    const [monthsToDisplay, setMonthsToDisplay] = useState([]);
    const [processedGrouping, setProcessedGrouping] = useState([]);
    const [processedRecords, setProcessedRecords] = useState([]);
    const [totals, setTotals] = useState([]);
    const [products, setProducts] = useState([]);
    const initialFields = useMemo(() => {
        return {
            reference_date: moment(),
            currency: company.preferred_currency,
            pivot: ["CUSTOMER_ID"]
        }
    }, []);

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        const pLimit = 200
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: pLimit
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache: true }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });
    }, []);

    useEffect(() => {
        runReport(initialFields);
    }, [])

    const exportResults = async () => {
        let fields = ["customer.display_name"];
        if (_.includes(processedGrouping, "PRODUCT_ID")) {
            fields.push("product.name");
        }
        fields.push(...["breakdown", "total_amount", "total_unrecognized_amount"]);

        const reportData = {
            period: {
                start_date: selectedDateRange.start.utcOffset(0, true),
                end_date: selectedDateRange.end.utcOffset(0, true)
            },
            groupings: processedGrouping,
            fields: fields
        };
        const result = await serverPost(getApiUrl(`/journal_entries/${props.journalAccountType}/export`), reportData);
        if (result) {
            Notification.Success("The CSV export will be emailed to you shortly", 8000)
        }
    }

    const getMonthsToDisplay = (dateRange) => {
        let start = dateRange.start.clone().startOf('month').utcOffset(0, true);
        let end = dateRange.end.clone().startOf('month').utcOffset(0, true);
        const dates = [
            start.utcOffset(0, true).clone()
        ];

        while (start.isBefore(end)) {
            start = start.add(1, 'month');
            dates.push(start.clone())
        }
        return dates;
    }

    const fetchRecords = async (data, from_key) => {
        const reportData = {
            period: {
                start_date: selectedDateRange.start.utcOffset(0, true),
                end_date: selectedDateRange.end.utcOffset(0, true)
            },
            groupings: data.pivot ? _.filter(_.values(data.pivot), v => v !== null): null,
            pagination: {
                from_key: from_key,
                limit: 200
            }
        };
        if (data.product_id) {
            reportData.query = {
                product_id: data.product_id
            };
        }
        return await serverPost(getApiUrl(`/journal_entries/${props.journalAccountType}`), reportData);
    }

    const runReport = async (data) => {
        setLoading(true);
        const grouping = data.pivot ? _.filter(_.values(data.pivot), v => v !== null): null;
        setProcessedGrouping(grouping);

        const maxPages = 3;
        let currentOffset = 0;
        let result = await fetchRecords(data, null);
        let totalRecords = result.total_record_count;
        let newRecords = result.records || [];
        let allRecords = newRecords;
        while((currentOffset + 200) < totalRecords) {
            currentOffset += 200;
            result = await fetchRecords(data, ""+currentOffset);
            newRecords = result.records || [];
            allRecords = _.concat(allRecords, newRecords);
        }

        setLoading(false);
        setMonthsToDisplay(getMonthsToDisplay(selectedDateRange));
        processRecords(allRecords)
    };

    const processRecords = (fetchedRecords) => {
        let precords = [];
        let ptotals = {}
        _.each(fetchedRecords, r => {
            let record = {
                ...r,
                breakdown: {}
            };
            _.each(r.breakdown, b => {
                const key = moment(b.period.start_date).utc().format("MMM, YY");
                record.breakdown[key] = b.amount || { value_in_cents: 0, currency: company.preferred_currency };
                ptotals[key] = ptotals[key] || { value_in_cents: 0, currency: company.preferred_currency };
                ptotals[key].value_in_cents += b.amount.value_in_cents;
            });
            ptotals["unrecognized"] = ptotals["unrecognized"] || { value_in_cents: 0, currency: company.preferred_currency };
            ptotals["unrecognized"].value_in_cents += r.total_unrecognized.value_in_cents;
            ptotals["total"] = ptotals["total"] || { value_in_cents: 0, currency: company.preferred_currency };
            ptotals["total"].value_in_cents += r.total.value_in_cents;
            precords.push(record);
        })
        setProcessedRecords(_.sortBy(precords, r => r.customer.id));
        setTotals(ptotals);
    }

    let reportTitle = "";
    switch (props.journalAccountType) {
        case "revenue":
            reportTitle = "Recognized Revenue";
            break;
        case "deferred_revenue":
            reportTitle = "Deferred Revenue";
            break;
        case "unbilled_revenue":
            reportTitle = "Unbilled Revenue";
            break;
    }

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: 'Revenue Recognition', href: getCompanySpecificUrl(`/reports/revenue_recognition`), current: false },
        { name: reportTitle, current: true }
    ]

    const isRowEmpty = (row) => {
        if (row.total.value_in_cents === 0) {
            return false;
        }
        const hasValue = _.some(row.breakdown, b => {
            return b.value_in_cents === 0;
        })
        return !hasValue;
    }

    const getPivotOptions = (i) => {
        let options = [];
        if (i !== 0) {
            options.push({ value: null, label: "None" })
        } else {
            options.push({ value: "CUSTOMER_ID", label: "Customer" });
        }
        options.push({ value: "PRODUCT_ID", label: "Product" });
        return options;
    }

    const pivotMap = {
        "CUSTOMER_ID": "Customer",
        "PRODUCT_ID": "Product"
    }

    const renderGroupingDescription = (row, grouping, i) => {
        if (grouping === "CUSTOMER_ID") {
            return (
                <td key={i} className="border-e border-gray-200">
                    <Link href={getCompanySpecificUrl(`/customer/${row.customer.id}`)} newTab>
                        <Columns.CustomerNameOneLine customer={row.customer} hideAvatar />
                    </Link>
                </td>
            )
        } else if (grouping === "PRODUCT_ID") {
            return (
                <td key={i} className="border-e border-gray-200">
                    { row.product && row.product.name }
                </td>
            )
        }
    }

    const productOptions = _.map(products, (p) => {
        return { value: p.id, label: p.name }
    })
    productOptions.unshift({ value: null, label: "All" })

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title={`${reportTitle} Report`}>
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={runReport}>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="3" name="product_id" type="select" label="Product" options={productOptions}
                                        showSearch={false}
                                    />
                                </Row>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="3" name="pivot.0" type="select" label="Pivot By" options={getPivotOptions(0)}
                                        showSearch={false} disabled
                                    />
                                    <BaseForm.Input
                                        colSpan="3" name="pivot.1" type="select" label="Then" options={getPivotOptions(1)}
                                        showSearch={false}
                                    />
                                </Row>
                                <div className="flex flex-row gap-2">
                                    <div className="grow">
                                        <div className="space-y-0 w-48">
                                            <div className="form-group inline">
                                                <div className="form-label">Time Period</div>
                                                <div className="form-input">
                                                    <DateRangePicker
                                                        iconOnLeft
                                                        clearable={false}
                                                        defaultValue={selectedDateRange}
                                                        name={"date_range"}
                                                        onDatesChange={(start, end) => {
                                                            setSelectedDateRange({
                                                                start: start,
                                                                end: end
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-end">
                                        <SubmitButton>Run</SubmitButton>
                                    </div>
                                </div>

                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section loading={loading} className="mt-4">
                    <MapleTable>
                        <div className="flex flex-row items-center">
                            <div className="grow">
                                <span className="text-base font-semibold text-gray-700">Revenue Recognition Report</span><br/>
                                <span className="text-gray-500"></span>
                            </div>
                            <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                                <span className="text-slate-900">Export</span>
                            </div>
                        </div>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    {
                                        _.map(processedGrouping, (g, i) =>
                                            <MapleTable.TH key={i} className={"border-e border-gray-200"}>
                                                { pivotMap[g] }
                                            </MapleTable.TH>
                                        )
                                    }
                                    {
                                        _.map(monthsToDisplay, (m, c) =>
                                            <MapleTable.TH key={c} className="text-end w-px whitespace-nowrap">{ m.utc().format("MMM, YY") }</MapleTable.TH>
                                        )
                                    }
                                    <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Total</MapleTable.TH>
                                    <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Unrecognized</MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(processedRecords, (row, i) =>
                                    <React.Fragment key={i}>
                                    {
                                        !isRowEmpty(row) &&
                                            <tr>
                                                { _.map(processedGrouping, (g, i) => renderGroupingDescription(row, g, i)) }
                                                {
                                                    _.map(monthsToDisplay, (m, c) =>
                                                        <td key={c} className="text-end w-px whitespace-nowrap">
                                                            {
                                                                (row["breakdown"][m.format("MMM, YY")] && row["breakdown"][m.format("MMM, YY")].value_in_cents !== 0) ?
                                                                    currencyFormatFromPrice(row["breakdown"][m.utc().format("MMM, YY")])
                                                                    : <span className="text-sm text-gray-400">-</span>
                                                            }
                                                        </td>
                                                    )
                                                }
                                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(row.total) }</td>
                                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(row.total_unrecognized) }</td>
                                            </tr>
                                    }
                                    </React.Fragment>
                                )
                            }
                            {
                                !_.isEmpty(totals) &&
                                    <tr>
                                        <td className="border-e border-gray-200" colSpan={processedGrouping.length}>
                                            <span className="font-semibold">Totals</span>
                                        </td>
                                        {
                                            _.map(monthsToDisplay, (m, c) =>
                                                <td key={c} className="text-end w-px whitespace-nowrap">
                                                    <span className="text-sm">
                                                        {
                                                            totals[m.format("MMM, YY")].value_in_cents !== 0 ?
                                                                currencyFormatFromPrice(totals[m.utc().format("MMM, YY")])
                                                                : <span className="text-sm text-gray-400">-</span>
                                                        }
                                                    </span>
                                                </td>
                                            )
                                        }
                                        <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(totals["total"]) }</td>
                                        <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(totals["unrecognized"]) }</td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default RecognizedRevenue;
