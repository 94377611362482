import '../App.scss';
import '../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, subscribeToEvent } from '../helpers/server';
import { BaseContext, ContractContext } from '../helpers/common';
import Loader from '../components/Loader';
import { useParams, Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomerContractDeclined from './CustomerContractDeclined';
import CustomerContractNotFound from './CustomerContractNotFound';
const _ = require('lodash');

function ContractComponent() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const outletContext = useOutletContext();

    const { getApiUrl } = useContext(BaseContext);
    const [contract, setContract] = useState(null);
    const [contractLoading, setContractLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [settingsLoading, setSettingsLoading] = useState(true);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
        subscribeToEvent("contract", (data) => { fetchData(true) });
    }, [uuid])

    const fetchData = (skipCache=false) => {
        setContractLoading(true);
        setSettingsLoading(true);

        serverFetch(getApiUrl(`/proposals/${uuid}`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setContractLoading(false);
            if (res && !_.includes(["DRAFT", "NEEDS_APPROVAL"], res.status)) {
                setContract(res);
            } else {
                setContract(null);
            }
        })

        serverFetch(getApiUrl(`/settings`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setSettingsLoading(false);
            if (res) {
                setSettings(res);
            } else {
                setSettings(null);
            }
        })
    }

    return (
        <ContractContext.Provider value={{
            contract,
            settings
        }}>
            <Loader loading={contractLoading || settingsLoading}>
                {
                    () => {
                        if (_.isNil(contract)) {
                            return <CustomerContractNotFound />;
                        } else if (contract.status === "DECLINED") {
                            return <CustomerContractDeclined />;
                        } else {
                            return (<Outlet context={outletContext} />);
                        }
                    }
                }
            </Loader>
        </ContractContext.Provider>
    )
}

export default ContractComponent;
