import '../App.scss';
import React from 'react';
import Header from './layout/header/Header';
import LeftNav from './LeftNav';
import Notification from './Notification';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

function BaseContainer(props) {
	return (
		<Container fluid className={classnames('page-wrapper', props.containerClassName)}>
			<Notification />
			<LeftNav />
			<div className='right-wrapper'>
				<Header /> 
				{props.header && props.header}
				<div className='content-wrapper' id='content-wrapper'>
					{props.children}
				</div>
			</div>
		</Container>
	);
}

export default BaseContainer;
