import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import {useNavigate, useSearchParams} from "react-router-dom";
const _ = require('lodash');

function CommissionEventLogs() {
	const navigate = useNavigate();
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const [sort, setSort] = useState('createdAtDesc');
	const [fromKey, setFromKey] = useState(null);
	const [meta, setMeta] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const { t } = useTranslation();
	const [ searchParams ] = useSearchParams();
	let report_period_id = searchParams.get('report_period_id');

	useEffect(() => {
		setPageTitle('Commission Event Logs');
	}, []);

	const onSearch = (restart = true) => {
		const limit = 50;
		const params = {
			query: {
				report_period_id: report_period_id,
			},
			sort_key: sort || 'createdAtDesc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			include_meta: restart,
		};
		serverPost(getApiUrl('/commission/event_logs/find'), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setEvents(results);
				} else {
					setEvents(_.concat(events, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setLoading(false);
				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
		});
	};

	useEffect(() => {
		onSearch();
	}, [sort]);

	useEffect(() => {
		if (!report_period_id) {
			return null;
		}

	}, [report_period_id]);

	const getEventLabel = (event) => {
		const events = {
			CONTRACT_EXECUTED: 'Contract Executed',
			FIRST_INVOICE_PAID: 'First Invoice Paid',
			INVOICE_PAID: 'Invoice Paid',
		};
		return events[event] || event;
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={events.length}
				>
					<Section title='Commission Event Logs' loading={loading}>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} />
							<MapleTable.Content>
								<thead>
									<tr>
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='createdAtAsc'
											sortKeyDown='createdAtDesc'
											currentSort={sort}
										>
											Created Date
										</SortableTableHeader>
										<MapleTable.TH>Event Type</MapleTable.TH>
										<MapleTable.TH>Commission Deal</MapleTable.TH>
										<MapleTable.TH>Event Value Base Type</MapleTable.TH>
										<MapleTable.TH>Event Value</MapleTable.TH>
										<MapleTable.TH>Commission Amount</MapleTable.TH>
										<MapleTable.TH>Is Adjustment</MapleTable.TH>
										<MapleTable.TH>Adjustment Reason</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{events.map((event, i) => (
										<MapleTable.TR
											key={i}
											onClick={() => navigate(getCompanySpecificUrl(`/commissions/event_logs/${event.id}`))}
											className='cursor-pointer hover:bg-gray-50'
										>
											<td>{moment(event.created_at).format('MMM D, YYYY')}</td>
											<td>{getEventLabel(event.event_type)}</td>
											<td>{event.commission_deal_id}</td>
											<td>{event.event_value_base_type}</td>
											<td>${event.commission_event_value}</td>
											<td>${event.commission_amount}</td>
											<td>{event.is_adjustment ? "Yes" : "No"}</td>
											<td>
												{event.is_adjustment ? (
													<span className='text-yellow-600'>{event.adjustment_reason}</span>
												) : (
													<span className='text-gray-500'>-</span>
												)}
											</td>
										</MapleTable.TR>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionEventLogs;
