import ArrowRight from '@untitled-ui/icons-react/build/esm/ArrowRight';

const CustomerContractFooter = () => {
    return (
        <div className="sticky bottom-0 p-6 bg-white border border-gray3 rounded-t-2xl flex justify-center items-center gap-2 text-sm shadow-converting-footer">
            <span className="text-[#535862]">Loved this experience?</span>
            <a className="flex justify-center items-center text-primary-updated font-semibold hover:text-primary-updated-dark transition" href="https://maplebilling.com/" target='_blank' rel="noopener noreferrer" >
                Learn more about contracts on&nbsp;<img className="w-[76px] pb-[2px]" src="/logo-medium.svg" alt="maple-logo-small" />
                <ArrowRight width={20} height={20} className="inline ml-[6px]" />
            </a>
        </div>
    );
};

export default CustomerContractFooter;