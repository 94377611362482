import { useContext } from "react";
import MapleTable from "./MapleTable";
import { BaseContext, currencyFormatFromPrice, getDescriptionForCreditLogAction } from "../helpers/common";
import { useTranslation } from "react-i18next";
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import Link from "./Link";
import Columns from "./Columns";
import moment from "moment";
import Loader from "./Loader";
import SortableTableHeader from "./SortableTableHeader";

function CreditLogsTable({
    showTableActions,
    creditLogsData: { creditLogs, totalUnpaginatedCreditLogsCount, isCreditLogsLoading, hasMoreCreditLogs },
    filterData: {
        creditLogFilterOptions,
        defaultSelectedCreditLogsFilters,
        cachedCreditLogsSelectedFilters,
        onSelectedCreditLogsFiltersChange,
    },
    sortData: { creditLogsSortPref, setCreditLogsSortPref },
}) {
    const { t } = useTranslation("common");
    const { getCompanySpecificUrl } = useContext(BaseContext);
    return (
        <MapleTable>
            {showTableActions && (
                <MapleTable.Actions>
                    <MapleTableHeaderWithActions
                        showSearch
                        searchPlaceholder={t("credit_logs.table.search_placeholder")}
                        showFilters
                        filters={creditLogFilterOptions}
                        meta={{ total: totalUnpaginatedCreditLogsCount }}
                        defaultSelectedFilters={defaultSelectedCreditLogsFilters}
                        cachedSelectedFilters={cachedCreditLogsSelectedFilters}
                        onParamsChange={onSelectedCreditLogsFiltersChange}
                    />
                </MapleTable.Actions>
            )}
            <MapleTable.Content>
                <thead>
                    <tr>
                        <MapleTable.TH>{t("credit_logs.table.header.customer")}</MapleTable.TH>
                        <MapleTable.TH>{t("credit_logs.table.header.action_type")}</MapleTable.TH>
                        <MapleTable.TH>{t("credit_logs.table.header.quantity")}</MapleTable.TH>
                        <MapleTable.TH className="d-none d-md-table-cell">
                            {t("credit_logs.table.header.invoice")}
                        </MapleTable.TH>
                        <MapleTable.TH className="d-none d-lg-table-cell">
                            {t("credit_logs.table.header.note")}
                        </MapleTable.TH>
                        <SortableTableHeader
                            sortKeyUp="createdAtAsc"
                            sortKeyDown="createdAtDesc"
                            currentSort={creditLogsSortPref}
                            onSortChange={setCreditLogsSortPref}
                        >
                            {t("credit_logs.table.header.date")}
                        </SortableTableHeader>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {isCreditLogsLoading && !hasMoreCreditLogs ? (
                        <tr>
                            <td colSpan="5">
                                <Loader loading={true} />
                            </td>
                        </tr>
                    ) : creditLogs.length ? (
                        creditLogs.map((creditLog) => (
                            <tr key={creditLog.id}>
                                <td>
                                    <Link to={getCompanySpecificUrl(`/customer/${creditLog.customer.id}`)}>
                                        <Columns.CustomerName customer={creditLog.customer} />
                                    </Link>
                                </td>
                                <td>{getDescriptionForCreditLogAction(creditLog.action_type)}</td>
                                <td className="tabular-nums">
                                    <span className="text-sm text-gray-500 whitespace-nowrap">
                                        {creditLog.type === "AMOUNT"
                                            ? currencyFormatFromPrice(creditLog.amount_applied)
                                            : `${creditLog.units_applied} ${creditLog.item?.name}(s)`}
                                    </span>
                                </td>
                                <td className="d-none d-md-table-cell">
                                    {creditLog.invoice ? (
                                        <span className="text-sm text-gray-500">
                                            <Link
                                                newTab
                                                to={getCompanySpecificUrl(
                                                    `/invoice/view?uuid=${creditLog.invoice.uuid}`
                                                )}
                                            >
                                                {creditLog.invoice.number}
                                            </Link>
                                        </span>
                                    ) : (
                                        <span className="text-sm text-gray-300">-</span>
                                    )}
                                </td>
                                <td className="d-none d-lg-table-cell break-all max-w-md">
                                    {creditLog.note ? (
                                        <span className="text-sm text-gray-500">{creditLog.note}</span>
                                    ) : (
                                        <span className="text-sm text-gray-300">-</span>
                                    )}
                                </td>
                                <td>
                                    <span className="text-sm text-gray-500">
                                        {moment(creditLog.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="5">
                                <span className="text-sm text-gray-500">
                                    {t("credit_logs.table.empty_filtered_results")}
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </MapleTable.Content>
        </MapleTable>
    );
}

export default CreditLogsTable;
