import PoweredByMaple from "./PoweredByMaple";

const PoweredByMapleFooter = ({ light }) => {
    return (
        <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
            <PoweredByMaple light={light} />
            <a className="text-primary-updated hover:text-primary-updated-dark transition font-semibold" href="https://www.maplebilling.com/terms" target="_blank" rel="noreferrer">Terms</a>
            <a className="text-primary-updated hover:text-primary-updated-dark transition font-semibold" href="https://www.maplebilling.com/privacy" target="_blank" rel="noreferrer">Privacy</a>
        </div>
    );  
};

export default PoweredByMapleFooter;