import '../App.scss';
import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef
} from 'react';
import {
    BaseContext,
    UserContext,
    currencyFormatFromPrice,
    getNameForProviderType, formatTerm
} from '../helpers/common';
import { getStartDateForSubscription, getRenewalDateForSubscription, getSubscriptionStatus } from '../helpers/subscriptions';
import {serverFetch, serverPost} from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import Columns from './Columns';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from 'react-i18next';
import Section from './Section';
import moment from 'moment';
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import Link from "./Link";
import SortableTableHeader from "./SortableTableHeader";
import { RectangleGroupIcon} from "@heroicons/react/20/solid";
import EmptyState from "./EmptyState";
import TopBarSummary from "./TopBarSummary";
import {BuildingOfficeIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import BaseOverlayTrigger from "./BaseOverlayTrigger";
import {getPaymentExportFields, getSubscriptionExportFields} from "../helpers/exportFields";
import BaseCSVExportModal from "./modals/BaseCSVExportModal";
import useGetMembers from "../helpers/hooks/api/useGetMembers";
const _ = require('lodash');

const SubscriptionListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [includeCustomer, setIncludeCustomer] = useState(true);
    const [includeDescription, setIncludeDescription] = useState(false);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);
    // const [teamMembers, setTeamMembers] = useState([]);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const { members: teamMembers, fetchMembers } = useGetMembers(false);

    const [subscriptions, setSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [cancelledSubscriptions, setCancelledSubscriptions] = useState([]);
    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState({});
    const [showAllPricingsMap, setShowAllPricingsMap] = useState([]);
    const [products, setProducts] = useState([]);
    const currentSearchRef = useRef(null);

    const defaultSelectedFilters = useMemo(() => {
        return (showTableActions && !props.disableInitialFilters) ? {"statuses":["ACTIVE"]}: props.defaultSelectedFilters || {}
    }, [showTableActions, props.disableInitialFilters]);
    const cachedSelectedFilters = useMemo(() => {
        if (props.disableInitialFilters) {
            return null;
        }
        const cachedString = localStorage.getItem(company.id + "_filter_cache_subscriptions");
        if (cachedString && showTableActions) {
            return JSON.parse(cachedString);
        } else {
            return null;
        }
    }, [showTableActions, props.disableInitialFilters])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    const hasReadPermissions = hasAccess("subscriptions", userInfo, "read");
    const hasWritePermissions = hasAccess("subscriptions", userInfo, "write") && (!props.customer || !props.customer.managed_externally);

    useEffect(() => {
        fetchMembers();
    }, [])

    useEffect(() => {
        setSelectedFilters(cachedSelectedFilters || defaultSelectedFilters);
    }, [cachedSelectedFilters, defaultSelectedFilters])

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.includeDescription)) {
            setIncludeDescription(props.includeDescription);
        }
    }, [props.includeDescription])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        const metricsExclusionOptions = [
            { value: null, label: "All" },
            { value: true, label: "Excluded from Metrics" },
            { value: false, label: "Included in Metrics" },
        ]

        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatic" },
            { value: false, label: "Manual Invoice" },
        ]

        const trialStateOptions = [
            { value: null, label: "All" },
            { value: true, label: "Trial" },
            { value: false, label: "Not Trial" },
        ]

        const statusOptions = [
            { value: "ACTIVE", label: "Active" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "UNPAID", label: "Unpaid" }
        ]

        const teamMemberOptions = _.map(teamMembers, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamMemberOptions.unshift({ value: "unassigned", label: "Unassigned" })
        teamMemberOptions.unshift({ value: null, label: "All" })

        const productOptions = _.map(products, (p) => {
            return { value: p.id, label: p.name }
        })

        setFilters(_.filter([
            { title: "Status", type: "multi-select", name: "statuses", options: statusOptions},
            { title: "Payment Method", type: "select", name: "auto_charges", options: paymentMechanismOptions },
            !_.isEmpty(productOptions) && { title: "Product", type: "multi-select", name: "product_ids", options: productOptions },
            { title: "Trial", type: "select", name: "trial", options: trialStateOptions },
            { title: "Metrics", type: "select", name: "exclude_from_metrics", options: metricsExclusionOptions },
            { title: "Owner", type: "dropdown", name: "owner_id", options: teamMemberOptions },
            { title: "MRR", type: "amount", name: "mrr" },
        ], a => !!a))
    }, [teamMembers, products])

    const fetchProducts = () => {
        if (!showTableActions) {
            return;
        }
        const fetchParams = {
            pagination: {
                limit: 100
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), fetchParams,{ skipCache: false }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });
    }

    const getQueryParams = () => {
        const query = {
            statuses: _.isEmpty(selectedFilters.statuses) ? null: selectedFilters.statuses,
            search: selectedFilters.search,
            auto_charges: !_.isNil(selectedFilters.auto_charges) ? selectedFilters.auto_charges: null,
            exclude_from_metrics: !_.isNil(selectedFilters.exclude_from_metrics) ? selectedFilters.exclude_from_metrics: null,
            trial: !_.isNil(selectedFilters.trial) ? selectedFilters.trial: null,
            ...props.extraQueryFilters,
            mrr: selectedFilters.mrr,
            product_ids: selectedFilters.product_ids
        }
        if (!_.isNil(selectedFilters.owner_id)) {
            if (selectedFilters.owner_id === "unassigned") {
                query.owner_id = null
            } else {
                query.owner_id = selectedFilters.owner_id
            }
        }
        if (query.product_ids) {
            query.product_ids = {
                condition: "OR",
                values: query.product_ids
            }
        }
        return query;
    }

    const onSearch = (restart = true) => {
        const limit = 50;
        const query = getQueryParams();
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: query,
            include_meta: restart
        }
        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;
        serverPost(getApiUrl("/subscriptions/find"), params).then((res) => {
            setLoading(false);
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSubscriptions(results);
                    setMeta(res.meta);
                } else {
                    setSubscriptions(_.concat(subscriptions, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        if (subscriptions) {
            setCancelledSubscriptions(_.filter(subscriptions, (s) => s.status === "CANCELLED"));
        } else {
            setCancelledSubscriptions([])
        }
        if (props.hideCancelled && subscriptions.length > 1) {
            setFilteredSubscriptions(_.filter(subscriptions, (s) => s.status !== "CANCELLED"))
        } else {
            setFilteredSubscriptions(subscriptions);
        }
        setShowAllPricingsMap(prevMap => {
            const newMap = new Array(subscriptions.length);
            _.each(newMap, (m, i) => {
                if (i < prevMap.length) {
                    newMap[i] = prevMap[i];
                } else {
                    newMap[i] = false;
                }
            })
            return newMap
        })
    }, [subscriptions])

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters, props.extraQueryFilters, props.disableInitialFilters]);

    const updateShowAllPricingMap = (rowIndex) => {
        setShowAllPricingsMap(prevMap => {
            const newMap = [...prevMap];
            newMap[rowIndex] = true;
            return newMap;
        })
    }

    const onParamsChange = (params) => {
        if (!_.isEqual({...selectedFilters, sort}, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            if (!props.disableInitialFilters) {
                localStorage.setItem(company.id + "_filter_cache_subscriptions", JSON.stringify(newFilters));
            }
        }
    }

    const getSubscriptionCreationUrl = () => {
        let url = getCompanySpecificUrl("/subscription/create");
        if (props.customer) {
            let urlParser = new URL(window.location.origin + url);
            urlParser.searchParams.set('customer', props.customer.id);
            url = urlParser.pathname + urlParser.search;
        }
        return url;
    }

    const renderCancelledCountAndViewAll = () => {
        if (!props.customer) {
            return null;
        }
        if (!props.hideCancelled) {
            return null;
        }
        if (cancelledSubscriptions.length > 0 && subscriptions.length > 1) {
            return <span className="inline-flex flex-row gap-3 mr-3 items-center">
                <span className="text-gray-500">{ cancelledSubscriptions.length } cancelled</span>
                <Link href={getCompanySpecificUrl(`/subscriptions?customer_id=${props.customer && props.customer.id}`)}>View all</Link>
            </span>
        } else {
            return null;
        }
    }

    if (!hasReadPermissions) {
        return null;
    }

    const columns = [
        includeCustomer && "customer",
        includeDescription && "description",
        "term",
        "mrr",
        "status",
        "start_date",
        "renewal_date",
        "next_invoice",
        "managed",
        "parent_billed",
    ];

    const renderColumnHeader = (key, j) => {
        if (!key) {
            return;
        }
        if (key === "customer") {
            return <MapleTable.TH key={j}>Customer</MapleTable.TH>
        } else if (key === "description") {
            return <MapleTable.TH key={j}>Description</MapleTable.TH>
        } else if (key === "term") {
            return <MapleTable.TH key={j} className="d-none d-md-table-cell">Term</MapleTable.TH>
        } else if (key === "mrr") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="mrrAsc" sortKeyDown="mrrDesc"
                currentSort={sort} innerClassName="justify-end">
                MRR
            </SortableTableHeader>
        } else if (key === "status") {
            return <MapleTable.TH key={j}>Status</MapleTable.TH>
        } else if (key === "start_date") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="startDateAsc" sortKeyDown="startDateDesc"
                currentSort={sort} className="no-stretch d-none d-lg-table-cell">
                Since
            </SortableTableHeader>
        } else if (key === "renewal_date") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="renewalDateAsc" sortKeyDown="renewalDateDesc"
                currentSort={sort} className="d-none d-md-table-cell whitespace-nowrap">
                Renews
            </SortableTableHeader>
        } else if (key === "next_invoice") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="nextInvoiceAsc" sortKeyDown="nextInvoiceDesc"
                currentSort={sort} className="d-none d-md-table-cell">
                Next Invoice
            </SortableTableHeader>
        } else if (key === "managed") {
            return <MapleTable.TH key={j} className="!pl-0"/>
        } else if (key === "parent_billed") {
            return <MapleTable.TH key={j} className="!pl-0"/>
        }
    }

    const renderStatusImage = (subscription) => {
        let imageUrl = null
        if (subscription.imported_from === "HUBSPOT") {
            imageUrl = "/icons/hubspot.png"
        } else if (subscription.imported_from === "STRIPE") {
            imageUrl = "/icons/stripe.png"
        }

        const providerName = getNameForProviderType(subscription.imported_from);

        if (imageUrl && subscription.customer.managed_externally) {
            return <BaseOverlayTrigger content={`Subscription is currently managed in ${providerName}`}>
                <div className="status-image">
                    <img src={imageUrl} />
                </div>
            </BaseOverlayTrigger>
        }
    }

    const renderColumn = (key, row, j, rowIndex) => {
        if (!key) {
            return;
        }
        if (key === "customer") {
            return <td key={j}><Columns.CustomerName customer={row.customer} /></td>
        } else if (key === "description") {
            const baseCount = 2;
            let pricingList = row.product_pricings;
            if (!showAllPricingsMap[rowIndex]) {
                pricingList = row.product_pricings.slice(0, baseCount);
            }
            return <td key={j}>
                <div>
                    {
                        _.map(pricingList, (pp, j) =>
                            <div key={j}>
                                <span className="text-sm font-semibold text-gray-700">{pp.product.name}</span><br/>
                                <span className="text-sm font-normal text-gray-500">{pp.external_name || pp.name}</span>
                            </div>
                        )
                    }
                    {
                        row.product_pricings.length > baseCount && !showAllPricingsMap[rowIndex] &&
                            <Link onClick={() => updateShowAllPricingMap(rowIndex)}>+ { row.product_pricings.length-baseCount } more</Link>
                    }
                </div>
            </td>
        } else if (key === "term") {
            return <td key={j} className="d-none d-md-table-cell whitespace-nowrap">{ formatTerm(row.term) }</td>
        } else if (key === "mrr") {
            return <td key={j} className="text-end">
                <div className="flex flex-row gap-1 justify-end items-center">
                    {
                        row.customer.exclude_from_metrics &&
                        <div>
                            <BaseOverlayTrigger content={"Customer is excluded from metrics calculation"}>
                                <ExclamationTriangleIcon className="h-5 w-5"/>
                            </BaseOverlayTrigger>
                        </div>
                    }
                    { currencyFormatFromPrice(row.mrr) }
                </div>
            </td>
        } else if (key === "status") {
            return <td key={j}>{getSubscriptionStatus(row)}</td>
        } else if (key === "start_date") {
            return <td key={j} className="d-none d-lg-table-cell">{getStartDateForSubscription(row)}</td>
        } else if (key === "renewal_date") {
            return <td key={j} className="d-none d-md-table-cell">
                {
                    !row.end_date ?
                        getRenewalDateForSubscription(row)
                        : <span></span>
                }
            </td>
        } else if (key === "next_invoice") {
            return <td key={j} className="d-none d-md-table-cell">
                {
                    row.next_invoice_date &&
                    <span>{ moment(row.next_invoice_date).format("MMM D, YYYY hh:mma") }</span>
                }
            </td>
        } else if (key === "managed") {
            return <td key={j} className="!pl-0 d-none d-md-table-cell">
                {
                    row.customer.managed_externally && renderStatusImage(row)
                }
            </td>
        } else if (key === "parent_billed") {
            return <td key={j} className="!pl-0 w-px whitespace-nowrap">
                {
                    row.parent_billed &&
                    <BaseOverlayTrigger content={`Subscription billed to parent`}>
                        <BuildingOfficeIcon className={"h-5 w-5"} />
                    </BaseOverlayTrigger>
                }
            </td>
        }
    }

    const showEmptyState = subscriptions.length === 0 && !hasMore && props.showEmptyState && _.isEmpty(selectedFilters);
    return (
        <InfiniteScroll
            dataLength={filteredSubscriptions.length}
            next={() => onSearch(false)}
            hasMore={hasMore && !props.hideMore}
            scrollableTarget="content-wrapper"
        >
            <Section title={props.title || "Subscriptions"} variant={props.variant} loading={loading} actions={hasWritePermissions && !showEmptyState && !loading && !props.hideCreation && [{
                    variant: "primary",
                    icon: "fa-plus",
                    label: "Create Subscription",
                    link: getSubscriptionCreationUrl()
                }]}
                     right={renderCancelledCountAndViewAll()}>
                {
                    props.metricsSummary &&
                        <TopBarSummary className="mt-1" entries={props.metricsSummary}/>
                }
                {
                    showEmptyState ?
                        <EmptyState
                            icon={RectangleGroupIcon}
                            title={"No subscriptions."}
                            subtitle={"Get started by creating a new one."}
                            buttonLabel={"New Subscription"}
                            hasWritePermissions={hasWritePermissions}
                            onClick={() => navigate(getSubscriptionCreationUrl())}
                        />
                    : <MapleTable className={ props.metricsSummary ? "mt-4": "" }>
                            {
                                showTableActions &&
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Subscriptions"
                                        showFilters={true}
                                        filters={filters}
                                        showExport={true}
                                        onExport={() => setShowExportModal(true)}
                                        meta={meta}
                                        defaultSelectedFilters={defaultSelectedFilters}
                                        cachedSelectedFilters={cachedSelectedFilters}
                                        onParamsChange={onParamsChange}
                                    />
                            }
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                        { _.map(columns, (c, j) => renderColumnHeader(c, j)) }
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(_.slice(filteredSubscriptions, 0, props.limit), (row, i) =>
                                         <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/subscription/${row.id}`)}>
                                             { _.map(columns, (c, j) => renderColumn(c, row, j, i)) }
                                         </MapleTable.TR>
                                    )
                                }
                                {
                                    hasMore && !props.hideMore &&
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                {
                                    (hasMore || (props.limit && filteredSubscriptions.length > props.limit)) && props.hideMore &&
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <Link href={getCompanySpecificUrl(`/subscriptions?customer_id=${props.customer && props.customer.id}`)}>View all</Link>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                }
            </Section>
            <BaseCSVExportModal
                show={showExportModal}
                onClose={setShowExportModal}
                sort={sort}
                useQuery={true}
                query={getQueryParams()}
                title={"Subscriptions CSV Export"}
                fields={getSubscriptionExportFields()}
                filenamePrefix="Subscriptions-CSV"
                cacheKey={company.id + "_csv_subscriptions"}
                exportUrl={'/subscriptions/export'} />
        </InfiniteScroll>
    );
})

export default SubscriptionListSection;
