import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useContext } from 'react';
import { BaseContext, ContractContext } from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import { useParams } from 'react-router-dom';
import FileCheck02 from '@untitled-ui/icons-react/build/esm/FileCheck02';
import Hourglass03 from '@untitled-ui/icons-react/build/esm/Hourglass03';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import CustomerContractFooter from '../../components/CustomerContractFooter';
import { isContractExecuted, isContractExpired } from '../../helpers/contracts';
import classnames from 'classnames';
import CustomerContractExpired from '../../components/CustomerContractExpired';
import Status from '../../components/common/Status';

function ContractDone() {
    const { uuid } = useParams();

    const { company, setPageTitle } = useContext(BaseContext);
    const { contract } = useContext(ContractContext);

    useEffect(() => {
        setPageTitle(`Contract - ${contract.title} - Done`);

        if (contract.options && contract.options.completion_action === "REDIRECT" && contract.options.redirect_url) {
            let urlParser = new URL(contract.options.redirect_url);
            urlParser.searchParams.set('customer_id', contract.customer_id);
            window.location = urlParser.href
        }
    }, [contract]);

    useEffect(() => {
        localStorage.removeItem(`proposal_${uuid}_customer_token`);
    }, [uuid]);

    const renderContract = () => {
        const numberOfSignedSignatures = contract.signatories.filter(signatory => signatory.signed).length;
        const signaturesCompleted = isContractExecuted(contract);
        return (
            <div className="contract-wrapper">
                <div className="flex flex-col flex-grow justify-center items-center gap-8">
                    <FileCheck02 width={104} height={104} className="text-[#067647]"/>
                    <div className="flex flex-col justify-center items-center gap-3">
                        <h3 className="text-dark-gray text-3xl font-semibold">
                            {signaturesCompleted
                                ? "Your contract has been successfully signed!"
                                : "Thanks for signing! Just waiting for others to sign."
                            }
                        </h3>
                        {
                            (contract.status === "EXECUTED" || contract.status === "AWAITING_MANUAL_ACTION") &&
                                <span className="text-gray6" style={{ maxWidth: "400px" }}>
                                    A copy of the signed contract has been sent to your email.
                                </span>
                        }
                        <div className="text-gray6">
                            {company.name} will reach out to you shortly with the next steps.
                        </div>
                        {
                            contract.options.confirmation_message &&
                                <span className="body1 text-center gray3 mt-2">{ contract.options.confirmation_message }</span>
                        }
                    </div>
                    <Status variant={signaturesCompleted ? "check" : "hourglass"}>
                        {signaturesCompleted ? "All signatures completed" : `${numberOfSignedSignatures} of ${contract.signatories.length} signatures completed`}
                    </Status>
                </div>
                <CustomerContractFooter />
            </div>
        );
    }

    return (
        <ContentContainer className="full" bodyClassName="full">
            {
                isContractExpired(contract) ?
                    <CustomerContractExpired />
                : renderContract()
            }
        </ContentContainer>
    )
}

export default ContractDone;
