import '../../../App.scss';
import React from 'react';
import {IntegrationContext} from '../../../helpers/common';
import { useEffect, useContext } from "react";
import {useOutletContext, useNavigate, useSearchParams} from "react-router-dom";
const _ = require('lodash');

function OauthCallback(props) {
    const navigate = useNavigate();
    const { setShowNext, setShowProgress, setShowPrev, next } = useOutletContext();
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const [ searchParams ] = useSearchParams();
    const error = searchParams.get("error");

    useEffect(() => {
        if (integration && integration.auth_type === "api") {
            next();
        }
    }, [])

    useEffect(() => {
        setShowProgress(false);
        setShowNext(false);
        setShowPrev(false);
    }, []);

    useEffect(() => {
        if (!_.isNil(error)) {
            setTimeout(() => {
                navigate(getIntegrationSpecificUrl(`/setup/welcome`));
            }, 2000)
        }
    }, [error])

    useEffect(() => {
        if (integration) {
            if (integration.status === "ACTIVE") {
                window.location.href = getIntegrationSpecificUrl("/settings");
            } else if (integration.status === "SETUP_INCOMPLETE") {
                navigate(getIntegrationSpecificUrl("/setup/data"));
            }
        }
    }, [integration]);

    if (!_.isNil(error)) {
        return (
            <div className="flex flex-col justify-center items-center w-full h-full gap-3">
                <div className="spinner-border text-secondary"/>
                <span className="text-gray-700 text-base">Unable to complete OAuth Connection...</span>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col justify-center items-center w-full h-full gap-3">
                <div className="spinner-border text-secondary"/>
                <span className="text-gray-700 text-base">Finalizing OAuth Connection... { error }</span>
            </div>
        );
    }
}

export default OauthCallback;
