import { useMemo } from 'react';
import _ from 'lodash';

// components
import SingleSelectDropdown from '../../SingleSelectDropdown';
import Input from '../../common/Input';

// styles
import classnames from 'classnames';

const options = [
	{ value: null, label: 'All' },
	{ value: 'GTE', label: 'is at least' },
	{ value: 'LTE', label: 'is at most' },
	{ value: 'EQ', label: 'is equal to' },
	{ value: 'BET', label: 'is between' },
];

/**
 * FilterAmount is a component that wraps a SingleSelectDropdown and 1 or 2 input fields
 * for filtering on a numeric field. It is used in the FilterModal component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {(name: string, value: any) => void} props.onFieldChange - Callback function for handling field changes.
 * @param {Object} props.selectedFilters - The current filter values.
 * @param {Object} props.filter - The filter item (with name and label).
 * @param {boolean} [props.disabled=false] - Flag to disable the component.
 *
 * @returns {JSX.Element} A div element containing the SingleSelectDropdown and 1 or 2 input fields.
 */
const FilterAmount = ({ onFieldChange, selectedFilters, filter, disabled = false }) => {
	const existingValue = useMemo(() => selectedFilters[filter.name] ?? null, [selectedFilters, filter.name]);

	return (
		<div className='space-y-0'>
			<div className='form-group'>
				<div className='form-input'>
					<SingleSelectDropdown
						items={options}
						selectedId={existingValue && existingValue.op}
						showAll={false}
						idField={'value'}
						align={'left'}
						showSearch={false}
						className={classnames('form-select', disabled ? 'disabled' : '')}
						alignDropdown={'left'}
						onSelect={(newValue) => {
							if (newValue) {
								onFieldChange(filter.name, {
									...existingValue,
									op: newValue,
									eq: newValue === 'EQ' && existingValue ? existingValue.eq : null,
									gte:
										_.includes(['GTE', 'BET'], newValue) && existingValue
											? existingValue.gte
											: null,
									le:
										_.includes(['LTE', 'BET'], newValue) && existingValue
											? existingValue.lte
											: null,
								});
							} else {
								onFieldChange(filter.name, null);
							}
						}}
					/>
				</div>
			</div>
			{existingValue && existingValue.op === 'EQ' && (
				<Input
					type='number'
					id={filter.name + '.eq'}
					name={filter.name}
					step='0.01'
					value={existingValue && existingValue.eq}
					onChange={(event) => {
						existingValue.eq = parseFloat(event.target.value);
						onFieldChange(filter.name, existingValue);
					}}
					placeholder='100'
				/>
			)}
			{existingValue && existingValue.op === 'GTE' && (
				<Input
					type='number'
					id={filter.name + '.gte'}
					name={filter.name}
					step='0.01'
					value={existingValue && existingValue.gte}
					onChange={(event) => {
						existingValue.gte = parseFloat(event.target.value);
						onFieldChange(filter.name, existingValue);
					}}
					placeholder='100'
				/>
			)}
			{existingValue && existingValue.op === 'LTE' && (
				<Input
					type='number'
					id={filter.name + '.lte'}
					name={filter.name}
					step='0.01'
					value={existingValue && existingValue.lte}
					onChange={(event) => {
						existingValue.lte = parseFloat(event.target.value);
						onFieldChange(filter.name, existingValue);
					}}
					placeholder='100'
				/>
			)}
			{existingValue && existingValue.op === 'BET' && (
				<div className='form-group'>
					<div className='form-input'>
						<input
							id={filter.name + '.gte'}
							name={filter.name}
							type='number'
							step='0.01'
							value={existingValue && existingValue.gte}
							onChange={(event) => {
								existingValue.gte = parseFloat(event.target.value);
								onFieldChange(filter.name, existingValue);
							}}
							placeholder='100'
							className='form-control h-3 w-3 border-gray-300 text-gray-500 focus:ring-indigo-600 grow rounded-md ring-1 ring-black ring-opacity-5 !pr-1'
						/>
						<span>to</span>
						<input
							id={filter.name + '.lte'}
							name={filter.name}
							type='number'
							step='0.01'
							value={existingValue && existingValue.lte}
							onChange={(event) => {
								existingValue.lte = parseFloat(event.target.value);
								onFieldChange(filter.name, existingValue);
							}}
							placeholder='100'
							className='form-control h-3 w-3 border-gray-300 text-gray-500 focus:ring-indigo-600 grow rounded-md ring-1 ring-black ring-opacity-5 !pr-1'
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default FilterAmount;
