import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import CopyableComponent from '../CopyableComponent';
import Columns from '../Columns';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Table } from 'react-bootstrap';
import Button from '../common/buttons/Button';
import Loader from "../Loader";
import Section from "../Section";
const _ = require("lodash");
const sha1 = require('js-sha1');

function ShareContractLinkModal(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [contract, setContract] = useState({});
    const [signatoryUrls, setSignatoryUrls] = useState({});
    const [counterSignatoryUrls, setCounterSignatoryUrls] = useState({});

    useEffect(() => {
        setInitialFields({
            email: props.contract && props.contract.customer && props.contract.customer.email
        })
        setContract(props.contract)

        const fetchUrls = async () => {
            const urls = {};
            const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
            const generatedUrls = _.map(props.contract.signatories, signatory => {
                const postData = {
                    signatory_email: signatory.email,
                }
                return serverPost(getApiUrl(`/proposals/${props.contract.id}/generate_sign_link`), postData).then((res) => {
                    if (res) {
                        urls[signatory.email] = `${baseUrl}${res.url}`
                    }
                })
            })

            const counterSignUrls = {}
            const generatedCounterUrls = _.map(props.contract.counter_signatories, signatory => {
                const postData = {
                    signatory_email: signatory.company_user.user.email,
                }
                return serverPost(getApiUrl(`/proposals/${props.contract.id}/generate_sign_link`), postData).then((res) => {
                    if (res) {
                        counterSignUrls[signatory.company_user.user.email] = `${baseUrl}${res.url}`
                    }
                })
            })
            await Promise.all([generatedUrls, generatedCounterUrls])
            setSignatoryUrls(urls);
            setCounterSignatoryUrls(counterSignUrls)
        };
        fetchUrls();
    }, [props.contract])

    const getShortenedLink = (url) => {
        return (
            <span className="text-primary-updated hover:text-primary-updated-dark">{url.slice(0, 90)}{url.length > 90 && <span>...</span>}</span>
        )
    }

    const getPaymentUrl = () => {
        const relativeUrl = getCompanySpecificUrl(`/contract/${contract.id}/pay`);
        const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
        return `${baseUrl}${relativeUrl}`
    }

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Send Contract Links</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                {
                    contract.status === "PENDING_PAYMENT" &&
                    <Row>
                        <p className="body2">Payment Link</p>
                        <p className="body1">The contract has been signed by all customer signatories. Below is the link to enter payment details for the contract.</p>
                        <p className="my-4">
                            <CopyableComponent
                                branded
                                value={getPaymentUrl()}
                                copyableValue={getPaymentUrl()}
                            />
                        </p>
                    </Row>
                }
                {
                    contract.status === "PENDING_SIGNATURES" &&
                    <Row>
                        <p className="body1">
                            Links are unique for each recipient. Make sure that the intended recipients/signers are the
                            only ones accessing the document through their link.
                        </p><br/><br/><br/>
                        <p className="body2">Customer Signatory Links</p>
                        <Loader loading={_.isEmpty(signatoryUrls)}>
                            <Table borderless>
                                <tbody>
                                {
                                    _.map(contract.signatories, (signatory, i) =>
                                        <tr key={i}>
                                            <td className="no-stretch"><Columns.CustomerName customer={signatory}/></td>
                                            <td>
                                                <CopyableComponent
                                                    branded
                                                    value={signatoryUrls[signatory.email] && getShortenedLink(signatoryUrls[signatory.email])}
                                                    copyableValue={signatoryUrls[signatory.email]}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Loader>
                    </Row>
                }
                {contract.status === "PENDING_SIGNATURES" && !_.isEmpty(counterSignatoryUrls) && <br/>}
                {
                    !_.isEmpty(counterSignatoryUrls) && !contract.signed_offline && !props.hasEveryoneSigned &&
                    <Row>
                        <p className="body2">Counter Signatory Links</p>
                        {contract.status === "PENDING_PAYMENT" && <p>Counter signatories may not have signed yet. Below are their individual sign links.</p>}
                        {contract.status === "PENDING_COUNTER_SIGNATURES" &&
                            <p className="body1">
                                Links are unique for each recipient. Make sure that the intended recipients/signers are the only ones accessing the document through their link.
                            </p>
                        }
                        <Table borderless>
                            <tbody>
                            {
                                _.map(contract.counter_signatories, (signatory, i) =>
                                    <tr key={i}>
                                        <td className="no-stretch"><Columns.CustomerName
                                            customer={signatory.company_user.user}/></td>
                                        <td>
                                            <CopyableComponent
                                                branded
                                                value={counterSignatoryUrls[signatory.company_user.user.email]
                                                    && getShortenedLink(counterSignatoryUrls[signatory.company_user.user.email])}
                                                copyableValue={counterSignatoryUrls[signatory.company_user.user.email]}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </Row>
                }
            </BaseModal.Body>
            <BaseModal.Footer>
                <div className="d-flex flex-row align-items-end">
                    <Button variant="primary" onClick={() => props.onClose(false)}>Done</Button>
                </div>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default ShareContractLinkModal;
