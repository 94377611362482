import '../App.scss';
import {BaseContext, CustomerPortalContext} from '../helpers/common'
import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import Link from "./Link";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import variables from "../App.scss";
import {serverFetch} from "../helpers/server";
import Loader from "./Loader";
import PoweredByMaple from './PoweredByMaple';
const _ = require('lodash');

function CustomerPortalContainer(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const outletContext = useOutletContext();
    const { portalId } = useParams();
    const { company, getApiUrl } = useContext(BaseContext);
    const [portal, setPortal] = useState(null);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (skipCache=false) => {
        const portalInfo = await serverFetch(getApiUrl(`/customer_portals/${portalId}`));
        if (!portalInfo) {
            return;
        }
        setPortal(portalInfo);
        const res = await serverFetch(getApiUrl(`/settings`));
        if (res) {
            setSettings(res)
        }
        setLoading(false);
    }

    const onBack = () => {
        if (portal && portal.redirect_url) {
            window.location = portal.redirect_url
        } else {
            navigate(-1)
        }
    }

    let brandingLogo = null;
    let brandColor = variables.primaryColor;
    let brandBackgroundColor = variables.leftnavBackgroundColor;
    if (settings.branding) {
        // brandingLogo = settings.branding.logo_url || null;
        brandColor = settings.branding.color || variables.primaryColor;
        // brandBackgroundColor = settings.branding.color || variables.darkGrayColor;
    }

    let message = `${ company.name } partners with Maple for simplified billing.`;
    if (portal && portal.business_info && portal.business_info.message) {
        message = portal.business_info && portal.business_info.message
    }

    const canGoBack = (portal && portal.redirect_url) || (location.key !== "default");
    return (
        <CustomerPortalContext.Provider value={{
            portal,
            settings
        }}>
            <Loader loading={loading}>
            {
                () =>
                <div className="flex flex-col sm:flex-row h-screen">
                    <div className="sm:basis-1/4 sm:h-full p-6 sm:p-10 pr-7 text-white" style={{ backgroundColor: brandBackgroundColor }}>
                        <div className="flex flex-col h-full gap-1">
                            {
                                canGoBack &&
                                    <Link className="flex flex-row gap-1 text-sm" onClick={onBack}>
                                        <ArrowLeftIcon className="h-5 w-5"/> Back
                                    </Link>
                            }
                            <p className="mt-3 text-2xl font-semibold">{ message }</p>
                            <div className="grow"/>
                            <div className="flex flex-row gap-2 text-sm items-center">
                                <PoweredByMaple light />
                                {
                                    portal.business_info && portal.business_info.terms_of_service_url &&
                                    <Link href={portal.business_info.terms_of_service_url} newTab isExternalLink>Terms</Link>
                                }
                                {
                                    portal.business_info && portal.business_info.privacy_policy_url &&
                                    <Link href={portal.business_info.privacy_policy_url} newTab isExternalLink>Privacy</Link>
                                }
                            </div>
                        </div>
                    </div>
                    <div id="portal-wrapper" className="sm:basis-3/4 sm:h-full p-6 sm:p-10 pl-7 sm:overflow-scroll" style={{ backgroundColor: variables.backgroundColor }}>
                        <Outlet context={outletContext} />
                    </div>
                </div>
            }
            </Loader>
        </CustomerPortalContext.Provider>
    );
}

export default CustomerPortalContainer;
