import '../App.scss';
import '../css/invoice.scss';
import React, { useContext, useMemo, useState } from 'react';
import { BaseContext, IntegrationContext } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseForm from './BaseForm';
import SubmitButton from './common/buttons/SubmitButton';
import Toggle from './common/Toggle';
import { notifyEvent, serverPatch } from '../helpers/server';
import Notification from './Notification';
import {Col, Row} from "react-bootstrap";

const _ = require('lodash');

function SnowflakeDestinationSetupComponent(props) {
	const { t } = useTranslation('common');
	const { integration } = useContext(IntegrationContext);
	const { getApiUrl } = useContext(BaseContext);
	const [authMethod, setAuthMethod] = useState('PASSWORD');
	const [loading, setLoading] = useState(true);

	function onSubmit(data) {
		setLoading(true);
		console.log(data);
		const updateData = {
			feature_data: {
				destination_data: {
					snowflake: data,
				},
			},
		};
		// TODO: HOW is error handling happening here
		serverPatch(
			getApiUrl(`/integrations/${integration.id}`, {}, (err) => {
				console.log('failed to update server error:', err);
				Notification.Danger(`${integration.name} failed to update.`);
			}),
			updateData,
		).then((res) => {
			console.log(res);
			Notification.Success(`${integration.name} updated successfully.`);
			notifyEvent('integration_settings');
			if (props.onUpdate) {
				props.onUpdate();
			}
		});
	}

	return (
		<div className='flex flex-row gap-12 items-center max-w-2xl '>
			<Row className="grow">
				<BaseForm onSubmit={onSubmit}>
					<BaseForm.Input colSpan={12} type='text' name='host' label='Host' required />
					<BaseForm.Input colSpan={12} type='text' name='role' label='Role' required />
					<BaseForm.Input colSpan={12} type='text' name='warehouse' label='Warehouse' required />
					<BaseForm.Input colSpan={12} type='text' name='database' label='Database' required />
					<BaseForm.Input colSpan={12} type='text' name='default_schema' label='Default Schema' required />
					<BaseForm.Input colSpan={12} type='text' name='username' label='Username' required />
					<Col md={6} className="pb-2">
						<Toggle
							className="w-full"
							label='Authentication Method'
							toggleValues={[
								{ value: 'PRIVATE_KEY_AUTHENTICATION', label: 'Private Key' },
								{ value: 'PASSWORD', label: 'Password' },
							]}
							value={authMethod}
							onClick={(value) => {
								setAuthMethod(value);
							}}
						/>
					</Col>
					{(authMethod === 'PASSWORD' && (
						<BaseForm.Input type='password' name='password' label='Password' required />
					)) ||
						(authMethod === 'PRIVATE_KEY_AUTHENTICATION' && (
							<div>
								<BaseForm.Input type='text' name='private_key' label='Private Key' required />
								<BaseForm.Input
									type='text'
									name='private_key_passphrase'
									label='Private Key Password'
									required
								/>
							</div>
						))}
					<br />
					<SubmitButton>Update</SubmitButton>
				</BaseForm>
			</Row>
		</div>
	);
}

export default SnowflakeDestinationSetupComponent;
