import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, useContext, createRef} from 'react';
import BaseModal from './BaseModal';
import { useTranslation } from 'react-i18next';
import {serverFetch, serverPost} from "../../helpers/server";
import {
    BaseContext,
    currencyFormatFromPrice,
    getBrandName,
    getPaymentMethodDescription
} from "../../helpers/common";
import BaseForm from "../BaseForm";
import StripeSetupPaymentMethod from "../StripeSetupPaymentMethod";
import SubmitButton from "../common/buttons/SubmitButton";
import Loader from "../Loader";
import Button from "../common/buttons/Button";
import {useNavigate} from "react-router-dom";
const _ = require('lodash');

function AdminInvoicePaymentModal(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [paymentConfig, setPaymentConfig] = useState({});
    const [invoice, setInvoice] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(true);
    const setupRef = createRef();

    useEffect(() => {
        setPaymentConfig(props.paymentConfig);
    }, [props.paymentConfig])

    useEffect(() => {
        setInvoice(props.invoice);
    }, [props.invoice]);

    useEffect(() => {
        if (props.show && invoice.customer) {
            serverFetch(getApiUrl(`/customers/${invoice.customer.id}/payment_methods`), { skipCache: true }).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                    if (res.methods.length > 0) {
                        setSelectedPaymentMethod(res.methods[0].id)
                    }
                }
                setLoading(false);
            });
        }
        if (!props.show) {
            setLoading(true);
        }
    }, [props.show, invoice])

    if (!props.show) {
        return null;
    }

    const onFieldChange = (name, value) => {
        if (name === "payment_method") {
            setSelectedPaymentMethod(value);
        }
    }

    const onSubmit = async (data, defaultErrorHandler) => {
        const errorHandler = async (res) => {
            const errorResponse = await res.json();
            defaultErrorHandler(res, errorResponse);
        }

        if (!_.isNil(data.payment_method) && data.payment_method !== "add_new") {
            const selectedPaymentMethod = _.find(paymentMethods, p => p.id === data.payment_method);
            if (selectedPaymentMethod) {
                const payData = {
                    payment_data: {}
                }
                payData.payment_data[selectedPaymentMethod.payment_provider_key] = {
                    payment_method_id: data.payment_method
                }

                const payResult = await serverPost(getApiUrl(`/invoices/${invoice.uuid}/pay`), payData, {}, errorHandler);
                if (payResult) {
                    if (props.onClose) {
                        props.onClose(true);
                    }
                }
            }
        } else {
            if (setupRef.current) {
                await setupRef.current.onSubmit(data);
            }
        }
    }

    const existingPaymentMethodOptions = _.map(paymentMethods, (method) => {
        return {
            label: getPaymentMethodDescription(t, method),
            label1: getBrandName(method.brand),
            value: method.id
        }
    })
    existingPaymentMethodOptions.push({
        label: "Add new payment Method",
        value: "add_new"
    })

    const domain = window.location.origin;
    const redirectUrl = `${domain}/c/${invoice.company_id}/invoice/${invoice.uuid}`
    if (!paymentConfig) {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>Provider not configured</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <span>No payment provider has been configured. Please set up a payment provider before trying a payment.</span>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Button variant="text" onClick={() => props.onClose()}>Close</Button>
                    <Button onClick={() => navigate(getCompanySpecificUrl("/integrations"))}>Set up payment provider</Button>
                </BaseModal.Footer>
            </BaseModal>
        )
    }
    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Process Invoice Payment</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onSubmit={onSubmit} onFieldChange={onFieldChange}>
                <BaseModal.Body>
                    <div className="body1 mb-3">
                        Processing payment for invoice ({ invoice.number }) for the amount { currencyFormatFromPrice(invoice.due) }
                    </div>
                    <Loader loading={loading}>
                        {
                            !_.isEmpty(paymentMethods) &&
                                <BaseForm.Input
                                    type="select" name="payment_method" label="Select Payment Method"
                                    options={existingPaymentMethodOptions} showSearch={false}
                                />
                        }
                        {
                            (_.isEmpty(paymentMethods) || selectedPaymentMethod === "add_new") &&
                                <>
                                    {
                                        _.includes(["STRIPE", "stripe"], paymentConfig.provider) &&
                                        <div className="payment_input mt-4">
                                            <StripeSetupPaymentMethod
                                                ref={setupRef} {...props}
                                                customer={invoice.customer}
                                                redirectUrl={redirectUrl}
                                            />
                                        </div>
                                    }
                                </>
                        }
                    </Loader>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton>Process Payment</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    )
}

export default AdminInvoicePaymentModal;
