import { useState } from 'react';

// icons
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';

// styles
import classnames from 'classnames';

const iconSize = {
	width: 20,
	height: 20,
};

const CollapsibleFilter = ({ title, onFieldChange, filter, selectedFilters, children }) => {
	const [open, setOpen] = useState(true);

	const handleSelectAll = (selectValue) => {
		let newValue = [];
		if (selectValue) {
			newValue = filter.options.map((option) => option.value);
		}
		onFieldChange(filter.name, newValue);
	};

	return (
		<div className='flex flex-col py-[12px] px-6'>
			<div
				className={classnames({ 'mb-[12px]': open }, 'flex justify-between h-6 items-center cursor-pointer')}
				onClick={() => setOpen(!open)}
			>
				<div className='flex gap-1 items-center'>
					{open ? <ChevronUp {...iconSize} /> : <ChevronDown {...iconSize} />}
					<span className='text-dark-gray font-medium text-base'>{title}</span>
				</div>
				{open && filter.type === 'multi-select' && (
					<div>
						<div className='flex items-center'>
							{selectedFilters[filter.name] && selectedFilters[filter.name].length > 0 ? (
								<span
									className='cursor-pointer text-xs transition primary-link font-bold'
									onClick={(e) => {
										e.stopPropagation();
										handleSelectAll(false);
									}}
								>
									Clear Selection
								</span>
							) : (
								<span
									className='cursor-pointer text-xs transition primary-link font-bold'
									onClick={(e) => {
										e.stopPropagation();
										handleSelectAll(true);
									}}
								>
									Select All
								</span>
							)}
						</div>
					</div>
				)}
			</div>
			{open && <div className='ml-4'>{children}</div>}
		</div>
	);
};

export default CollapsibleFilter;
