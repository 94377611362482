import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import { serverFetch } from '../../helpers/server';
import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from '../../helpers/common';
import { useParams } from 'react-router-dom';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import Section from '../../components/Section';
import moment from 'moment';
const _ = require('lodash');

function SalesUser(props) {
	const { id } = useParams();
	const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [salesUserDetails, setSalesUserDetails] = useState({});
	const [details, setDetails] = useState({});

	const fetchSalesUserData = () => {
		serverFetch(getApiUrl(`/commission/sales_user/${id}`)).then((res) => {
			if (res) {
				setSalesUserDetails(res);
			}
		});
	};

	useEffect(() => {
		fetchSalesUserData();
	}, []);

	useEffect(() => {
		if (_.isEmpty(salesUserDetails)) {
			return;
		}
		let data = {
			ID: salesUserDetails.id,
			Name: salesUserDetails.company_user.user.name,
			Email: salesUserDetails.company_user.user.email,
			Title: salesUserDetails.company_user.title,
			'Current Quota Period Start': moment(salesUserDetails.current_quota_period.start_date).format(
				'MMM D, YYYY',
			),
			'Current Quota Period End': moment(salesUserDetails.current_quota_period.end_date).format('MMM D, YYYY'),
			'Quota Amount': salesUserDetails.quota_amount,
			'Current Quota Achievement': salesUserDetails.current_quota_period.quota_achieved,
			'Current Commission Achievement': salesUserDetails.current_quota_period.commission_achieved,
		};
		setDetails(data);
	}, [salesUserDetails]);

	return (
		<BaseContainer>
			<ContentContainer
				variant={'page'}
				title={!_.isNil(salesUserDetails.company_user) ? salesUserDetails.company_user.user.name : ''}
			>
				<div className='mt-2'>
					<Section title={'Details'} actions={[]}>
						<KeyValueDisplay2 items={details} />
					</Section>
				</div>
			</ContentContainer>
		</BaseContainer>
	);
}

export default SalesUser;
