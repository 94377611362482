import classnames from "classnames";
import { getColStyle, getMdColStyle } from "../../helpers/styles";

const SummaryBar = ({ items, className }) => {
    return (
        <div className={classnames("grid grid-cols-12 bg-white p-[20px] border border-gray2 gap-x-6 gap-y-5 rounded-xl mb-[32px]", className)}>
            {items.map((item) => {
                if (!item) return null;
                return (
                    <div className={classnames(getMdColStyle(item.md), getColStyle(item.colSpan))}>
                        <div className="text-gray6 text-sm">{item.label}</div>
                        <div className="text-dark-gray text-lg font-medium">{item.value}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default SummaryBar;