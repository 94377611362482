import React, { createRef, useContext, useEffect, useState } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import BaseModal from '../../components/modals/BaseModal';
import BaseForm, { BaseFormContext } from '../../components/BaseForm';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import BaseSideModal from '../../components/modals/BaseSideModal';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import { Button, Col, Row } from 'react-bootstrap';
import Link from '../../components/Link';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import DeleteButton from '../../components/DeleteButton';
import Notification from '../../components/Notification';
import { useNavigate } from 'react-router-dom';
import CommissionTierRules from './CommissionTierRules';
const _ = require('lodash');

function CommissionPlanCreate(props) {
	const navigate = useNavigate();
	const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [planType, setPlanType] = useState(null);
	const [numValueRules, setNumValueRules] = useState(1);
	const [numAcceleratorValueRules, setNumAcceleratorValueRules] = useState(0);
	const formRef = createRef();
	const [acceleratorType, setAcceleratorType] = useState(null);

	useEffect(() => {
		setPageTitle(`Commission Plans`);
	}, []);

	const planTypeOptions = [
		{ value: 'QUOTA_BASED', label: 'Quota Based' },
		{ value: 'VALUE_BASED', label: 'Value Based' },
	];
	const payoutEventOptions = [
		{ value: 'CONTRACT_EXECUTED', label: 'On Contract Executed' },
		{ value: 'FIRST_INVOICE_PAID', label: 'On First Invoice Paid' },
	];
	const baseTypeOptions = [
		{ value: 'ACV', label: 'Contract ACV' },
		{ value: 'TCV', label: 'Contract TCV' },
	];
	const acceleratorTypeOptions = [
		// { value: 'ADDITIVE', label: 'Additive' },
		{ value: 'MULTIPLICATIVE', label: 'Multiplicative' },
	];

	const onSubmit = async (data) => {
		const payload = {
			name: data.name,
			plan_type: data.plan_type,
			payout_event: data.payout_event,
			base_type: data.base_type,
		};

		if (planType === 'QUOTA_BASED' && data.commission_rules) {
			payload.rules = Object.keys(data.commission_rules).map((key) => {
				return {
					commission_rate: data.commission_rules[key].commission_rate,
					rule: data.commission_rules[key].rule,
					is_retroactive: data.commission_rules[key].is_retroactive,
				};
			});
		}

		if (planType === 'VALUE_BASED' && data.value_commission_rules) {
			payload.rules = Object.keys(data.value_commission_rules).map((key) => {
				return {
					commission_rate: data.value_commission_rules[key].commission_rate,
					rule: data.value_commission_rules[key].rule,
					is_retroactive: data.value_commission_rules[key].is_retroactive,
				};
			});
		}

		if (planType === 'QUOTA_BASED' && data.value_accelerators && acceleratorType) {
			payload.accelerators = Object.keys(data.value_accelerators).map((key) => {
				return {
					accelerator_rate: data.value_accelerators[key].accelerator_rate,
					rule: data.value_accelerators[key].rule,
					type: acceleratorType,
				};
			});
		}

		if (planType === 'VALUE_BASED' && data.accelerators && acceleratorType) {
			payload.accelerators = Object.keys(data.accelerators).map((key) => {
				return {
					accelerator_rate: data.value_accelerators[key].accelerator_rate,
					rule: data.value_accelerators[key].rule,
					type: acceleratorType,
				};
			});
		}

		serverPost(getApiUrl('/commission/plan'), payload).then((res) => {
			if (res) {
				Notification.Success('Successfully created commission plan');
				navigate(getCompanySpecificUrl('/commissions/plans'));
			} else {
				Notification.Danger('Error creating commission plan');
			}
		});
	};

	const onFieldChange = (name, value) => {
		if (name === 'plan_type') {
			setPlanType(value);
		} else if (name === 'accelerator_type') {
			setAcceleratorType(value);
		}
	};

	const valueRulePropertyKeys = [
		{ Key: 'CONTRACT_ACV', Type: 'Number' },
		{ Key: 'CONTRACT_TCV', Type: 'Number' },
		{ Key: 'PRODUCT_ID', Type: 'Text' },
	];
	const valueRulePropertyValues = [
		{ value: 'CONTRACT_ACV', label: 'Contract ACV' },
		{ value: 'CONTRACT_TCV', label: 'Contract TCV' },
		{ value: 'CONTRACT_LENGTH', label: 'Contract Length (Years)' },
	];

	// const renderValueRuleInputRows = () => {
	// 	return _.map(_.range(numValueRules), (i, _) =>
	// 		<div key={i} className='bg-slate-50 py-2 px-3 rounded-md border-1 border-slate-200 mt-2'>
	//             {
	//                 i > 0 && i === numValueRules-1 &&
	//                 <DeleteButton onDelete={() => setNumValueRules(prev => prev-1)}></DeleteButton>
	//             }
	//             <BaseForm.Input
	//                 name={`commission_rule.${i}.commission_rate`}
	//                 label='Commission %'
	//                 type='number'
	//                 step='0.01'
	//                 min='0'
	//                 max='100'
	//                 colSpan="6"
	//                 required
	//             />
	//             <BaseForm.Input
	//                 colSpan='12'
	//                 name={`commission_rule.${i}.rule`}
	//                 label='Rule'
	//                 type='rule'
	//                 isColored={true}
	//                 propertyValues={valueRulePropertyValues}
	//                 propertyFields={valueRulePropertyKeys}
	// 				required
	//             />
	// 			{
	// 				i > 0 &&
	// 				<div className='bg-slate-50'>
	// 				<BaseForm.Input
	// 					colSpan="4"
	// 					name={`commission_rule.${i}.is_retroactive`}
	// 					value='Apply rate to all previous rules once reached'
	// 					borderless
	// 					type="check"
	// 				/>
	// 				</div>
	// 			}
	//         </div>
	// 	);
	// }

	const renderValueRuleInputRows = () => {
		return (
			<div className='flex flex-col gap-3'>
				{_.map(_.range(numValueRules), (i) => (
					<div key={i} className='bg-slate-50 p-4 rounded-lg border border-slate-200'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>Rule {i + 1}</span>
							{i > 0 && i === numValueRules - 1 && (
								<button
									type='button'
									onClick={() => setNumValueRules((prev) => prev - 1)}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-start'>
							<div className='col-span-3'>
								<div className='flex items-center'>
									<BaseForm.Input
										name={`value_commission_rules.${i}.commission_rate`}
										label='Commission Rate'
										type='number'
										step='0.01'
										min='0'
										max='100'
										required
										className='w-full'
									/>
									<span className='ml-2 mt-6'>%</span>
								</div>
							</div>
							<div className='col-span-9'>
								<BaseForm.Input
									name={`value_commission_rules.${i}.rule`}
									label='Condition'
									type='rule'
									isColored={true}
									propertyValues={valueRulePropertyValues}
									propertyFields={valueRulePropertyKeys}
									required
								/>
							</div>
						</div>
					</div>
				))}
				{/*<button*/}
				{/*	type="button"*/}
				{/*	onClick={() => setNumValueRules(prev => prev + 1)}*/}
				{/*	className="mt-2 flex items-center gap-2 text-blue-600 hover:text-blue-700"*/}
				{/*>*/}
				{/*	<PlusIcon className="h-5 w-5" />*/}
				{/*	<span>Add Rule</span>*/}
				{/*</button>*/}
			</div>
		);
	};

	const renderValueAccelerators = () => {
		return (
			<div className='flex flex-col gap-3'>
				{_.map(_.range(numAcceleratorValueRules), (i) => (
					<div key={i} className='bg-slate-50 p-4 rounded-lg border border-slate-200'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>Accelerator {i + 1}</span>
							{i === numAcceleratorValueRules - 1 && (
								<button
									type='button'
									onClick={() => setNumAcceleratorValueRules((prev) => prev - 1)}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-start'>
							<div className='col-span-3'>
								<div className='flex items-center'>
									<BaseForm.Input
										name={`value_accelerators.${i}.accelerator_rate`}
										label='Accelerator Rate'
										type='number'
										step='0.01'
										min='0'
										max='100'
										required
										className='w-full'
									/>
									<span className='ml-2 mt-6'>x Base Rate</span>
								</div>
							</div>
							<div className='col-span-9'>
								<BaseForm.Input
									name={`value_accelerators.${i}.rule`}
									label='Condition'
									type='rule'
									isColored={true}
									propertyValues={valueRulePropertyValues}
									propertyFields={valueRulePropertyKeys}
									required
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<ContentBox>
					<ContentBox.Body>
						<div>
							<h4>Create Commission Plan</h4>
							<br />
							<BaseForm onSubmit={onSubmit} onFieldChange={onFieldChange} ref={formRef}>
								<Row>
									<BaseForm.Input name='name' label='Plan Name' type='text' colSpan='6' required />
									<BaseForm.Input
										name='plan_type'
										label='Plan Type'
										type='select'
										options={planTypeOptions}
										showSearch={false}
										colSpan='6'
										required
									/>
								</Row>
								<Row>
									<BaseForm.Input
										name='payout_event'
										label='Payout Event'
										type='select'
										showSearch={false}
										options={payoutEventOptions}
										colSpan='6'
										required
									/>
									<BaseForm.Input
										name='base_type'
										label='Base Type'
										type='select'
										showSearch={false}
										options={baseTypeOptions}
										colSpan='6'
										required
									/>
								</Row>
								<br />
								{planType === 'QUOTA_BASED' && (
									<div className='mb-4'>
										<span className='body2'>Quota-based commissions detail</span>
										<div className='mt-2 flex flex-col gap-3'>
											<div>
												<CommissionTierRules name={`commission_rules`} />
											</div>
										</div>
									</div>
								)}
								{planType === 'VALUE_BASED' && (
									<div className='mb-4'>
										<span className='body2'>Value-based commissions detail</span>
										<div className='mt-2 flex flex-col gap-3'>{renderValueRuleInputRows()}</div>
										<Button
											type='button'
											variant='outline-light'
											onClick={() => setNumValueRules((prev) => prev + 1)}
											className='mt-2 flex items-center gap-2'
										>
											<PlusIcon className='h-5 w-5' />
											<span>Add Rule</span>
										</Button>
										{/*<div className="mt-2 mb-4 bg-slate-50 p-3 rounded-md border-1 border-slate-200">*/}
										{/*	<div className="flex flex-row items-center">*/}
										{/*		<Link onClick={() => setNumValueRules(prev => prev+1)}>*/}
										{/*			<div className="text-sm flex flex-row gap-1"><PlusIcon*/}
										{/*				className="w-5 h-5"/><span>Add Rule</span></div>*/}
										{/*		</Link>*/}
										{/*	</div>*/}
										{/*</div>*/}
									</div>
								)}
								{planType && (
									<div className='mb-4'>
										<span className='body2'>Accelerators (Optional)</span>
										<div className='mt-2'>
											<BaseForm.Input
												name='accelerator_type'
												label='Accelerator Type'
												type='select'
												options={acceleratorTypeOptions}
												showSearch={false}
												colSpan='6'
											/>
										</div>
										{acceleratorType && (
											<div className='mt-4'>
												{planType === 'QUOTA_BASED' ? (
													<div>
														{renderValueAccelerators()}
														<Button
															type='button'
															variant='outline-light'
															onClick={() =>
																setNumAcceleratorValueRules((prev) => prev + 1)
															}
															className='mt-2 flex items-center gap-2'
														>
															<PlusIcon className='h-5 w-5' />
															<span>Add Accelerator</span>
														</Button>
													</div>
												) : (
													<CommissionTierRules name='accelerators' />
												)}
											</div>
										)}
									</div>
								)}
								<SubmitButton errorOnRight>Create</SubmitButton>
							</BaseForm>
						</div>
					</ContentBox.Body>
				</ContentBox>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionPlanCreate;
