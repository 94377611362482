import { useState } from 'react';
import moment from 'moment/moment';

/**
 * An object containing predefined date ranges with key as range name and value as date range
 * 
 * @returns An object containing predefined date ranges with key as range name and value as date range.
 */
const useCustomDateRanges = (customRanges) => {
	const [customDateRanges] = useState(customRanges || {
		Today: [moment().startOf('day'), moment().endOf('day')],
		Yesterday: [moment().startOf('day').subtract(1, 'days'), moment().subtract(1, 'days').endOf('day')],
		'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
		'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
		'This Month': [moment().startOf('month'), moment().endOf('day')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
		'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
		'This Year': [moment().startOf('year'), moment().endOf('year')],
		'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
	});

	return customRanges === null ? undefined : customDateRanges;
};

export default useCustomDateRanges;
