import '../App.scss';
import React, {useEffect, useState, forwardRef, useImperativeHandle, useContext, createRef} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import { Row, Col } from 'react-bootstrap';
import {
    renderDescriptionForItemPricing,
    BaseContext,
    UserContext,
    formatTerm,
    currencyFormatFromPrice, getPlanLengthOptions
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../helpers/server";
import Link from "./Link";
import ProductPricingInput from "./ProductPricingInput";
import {PlusIcon} from "@heroicons/react/20/solid";
import moment from 'moment';
const _ = require('lodash');

const ProductPricingSelection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        },
        setInitialData(data) {
            return setInitialData(data);
        },
        onProductPricingCreated(productPricing) {
            return onProductPricingCreated(productPricing)
        }
    }));

    const { getApiUrl, company } = useContext(BaseContext);
    const {isMapleUser} = useContext(UserContext);
    const { t } = useTranslation('common');
    const [coupons, setCoupons] = useState([]);
    const [allProductPricings, setAllProductPricings] = useState([]);
    const [selectedProductPricingsList, setSelectedProductPricingsList] = useState([[]]);
    const [selectedProductPricingIdsList, setSelectedProductPricingIdsList] = useState([[]]);
    const [editable, setEditable] = useState(true);
    const [allowDiscounts, setAllowDiscounts] = useState(true);
    const [requireRecurring, setRequireRecurring] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [discountsTypeMap, setDiscountsTypeMap] = useState({});
    const [allowPhases, setAllowPhases] = useState(false);
    const [phaseStarts, setPhaseStarts] = useState([]);

    const [numberOfPhases, setNumberOfPhases] = useState(1);
    const [phaseRefs, setPhaseRefs] = useState([]);

    useEffect(() => {
        let refs = [];
        for (let i = 0; i < numberOfPhases; i++) {
            refs.push(createRef());
        }
        setPhaseRefs(refs);
        setSelectedProductPricingsList(prevList => {
            const newList = [...prevList];
            if (newList.length < numberOfPhases) {
                newList.push([])
            }
            return newList.slice(0, numberOfPhases);
        })
        setPhaseStarts(prevList => {
            const newList = [...prevList];
            if (newList.length < numberOfPhases) {
                newList.push({
                    count: 1,
                    frequency: "YEAR"
                })
            }
            return newList.slice(0, numberOfPhases);
        })
    }, [numberOfPhases]);

    useEffect(() => {
        if (_.isNil(props.initialSelectionData) || _.isEmpty(props.initialSelectionData)) {
            return;
        }
        if (props.initialSelectionData.discounts) {
            setDiscountsTypeMap(_.mapValues(props.initialSelectionData.discounts, (d) => d.type));
        }
        if (props.initialSelectionData.phases) {
            setNumberOfPhases(props.initialSelectionData.phases.length);
            let pList = [];
            let pStarts = [];
            _.each(props.initialSelectionData.phases, (p, i) => {
                pStarts.push(p.relative_term)
                pList.push(p.product_pricing_ids);
            })
            setSelectedProductPricingIdsList(pList);
            setPhaseStarts(pStarts);
        }
    }, [props.initialSelectionData])

    useEffect(() => {
        if (!_.isNil(props.allowPhases)) {
            setAllowPhases(props.allowPhases);
        }
    }, [props.allowPhases]);

    useEffect(() => {
        setAllProductPricings(props.productPricings);
    }, [props.productPricings])

    useEffect(() => {
        setCoupons(props.coupons);
    }, [props.coupons])

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts);
    }, [props.allowDiscounts])

    useEffect(() => {
        if (_.isNil(props.editable)) {
            setEditable(true);
        } else {
            setEditable(props.editable);
        }
    }, [props.editable])

    useEffect(() => {
        setRequireRecurring(props.requireRecurring || false);
    }, [props.requireRecurring])

    useEffect(() => {
        const selectedIdsList = selectedProductPricingIdsList || [];
        let ppsList = [];
        _.each(selectedIdsList, selectedIds => {
            const selectPricings = []
            _.each(selectedIds, (id) => {
                const pp = _.find(allProductPricings, (p) => p.id === id);
                if (pp) {
                    selectPricings.push(pp);
                }
            })
            ppsList.push(selectPricings)
        })

        setSelectedProductPricingsList(ppsList);
    }, [allProductPricings, selectedProductPricingIdsList])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [])

    const setInitialData = (data) => {
        if (data.discounts) {
            setDiscountsTypeMap(_.mapValues(data.discounts, (d) => d.type));
        }
        if (data.phases) {
            setNumberOfPhases(data.phases.length);
            let pList = [];
            _.each(data.phases, p => {
                pList.push(p.product_pricing_ids);
            })
            setSelectedProductPricingIdsList(pList);
        }
    }

    const onProductPricingCreated = (productPricing) => {
        if (phaseRefs[0].current) {
            phaseRefs[0].current.onProductPricingCreated(productPricing);
        }
    }

    const onFieldChange = (name, value) => {
        if (_.startsWith(name, "discounts.") && _.endsWith(name, ".type")) {
            setDiscountsTypeMap(prevMap => {
                const newMap = _.cloneDeep(prevMap)
                newMap[name.split(".")[1]] = value;
                return newMap;
            })
        } else if (_.startsWith(name, "phases.")) {
            const i = name.split(".")[1];
            if (_.endsWith(name, ".relative_term.count")) {
                setPhaseStarts(prevStarts => {
                    const newStarts = [...prevStarts];
                    newStarts[i].count = value;
                    return newStarts;
                });
            } else if (_.endsWith(name, ".relative_term.frequency")) {
                setPhaseStarts(prevStarts => {
                    const newStarts = [...prevStarts];
                    newStarts[i].frequency = value;
                    return newStarts;
                });
            }
            if (phaseRefs[i] && phaseRefs[i].current) {
                phaseRefs[i].current.onFieldChange(name, value);
            }
        }
    }

    const validate = (allFields) => {
        if (numberOfPhases > 0) {
            for (let i = 0; i < phaseRefs.length; i++) {
                if (phaseRefs[i].current) {
                    const validationError = phaseRefs[i].current.validate(allFields)
                    if (validationError) {
                        return validationError
                    }
                }
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let baseConfig = {}
        let changes = [];
        for (let i = 0; i < phaseRefs.length; i++) {
            if (phaseRefs[i].current) {
                const selectionFields = phaseRefs[i].current.getPricingSelectionFields(allFields)
                if (i === 0) {
                    baseConfig = selectionFields;
                } else {
                    changes.push(selectionFields);
                }
            }
        }

        baseConfig.scheduled_changes = changes;
        return baseConfig
    }

    const onPhaseAdd = () => {
        setNumberOfPhases(prev => prev + 1);
        setPhaseStarts(prevList => {
            const newList = [...prevList];
            newList.push({
                count: 1,
                frequency: "YEAR"
            })
            return newList;
        })
        if (props.setInitialFields) {
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.phases.push({
                    relative_term: {
                        count: 1,
                        frequency: "YEAR"
                    }
                })
                return newFields;
            })
        }
    }

    const onPhaseDelete = (index) => {
        setNumberOfPhases(prev => prev - 1);
    }

    return (
        <div className="mb-4">
            <span className="body2">Pricing Details</span>
            <div className="mt-1 flex flex-col gap-3">
                {
                    _.map(_.range(numberOfPhases), (phase, i) =>
                        <ProductPricingSelectionPhase
                            ref={phaseRefs[i]}
                            key={i} prefix={phase}
                            billableItems={billableItems}
                            allowDiscounts={allowDiscounts}
                            initialFields={props.initialFields && props.initialFields.phases && props.initialFields.phases[i]}
                            allowMinimumSpend={props.allowMinimumSpend}
                            editable={editable}
                            requireRecurring={requireRecurring}
                            menuPlacement={props.menuPlacement}
                            showDuration={numberOfPhases > 1}
                            canDeletePhase={i !== 0}
                            isStart={i === 0}
                            isEnd={i === numberOfPhases-1}
                            preselectedProductPricings={selectedProductPricingsList[phase]}
                            phaseStart={i === 0 ? {} : phaseStarts[i]}
                            onSelectionChange={(pps) => {
                                if (props.onSelectionChange) {
                                    props.onSelectionChange(phase, pps)
                                }
                            }}
                            excludeBundlePricing={props.excludeBundlePricing}
                            extraQueryFilters={props.extraQueryFilters}
                            onPhaseDelete={() => onPhaseDelete(i)}
                            onCreateProductPricing={props.onCreateProductPricing}
                            allowCreation={props.allowCreation}
                        />
                    )
                }
            </div>
            {
                allowPhases &&
                <div className="mt-2 mb-4 bg-slate-50 p-3 rounded-md border-1 border-slate-200">
                    <div className="flex flex-row items-center">
                        <Link onClick={onPhaseAdd}>
                            <div className="text-sm flex flex-row gap-1"><PlusIcon className="w-5 h-5"/><span>Add Phase</span></div>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
})

const ProductPricingSelectionPhase = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        },
        onProductPricingCreated(productPricing) {
            return onProductPricingCreated(productPricing);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [allowDiscounts, setAllowDiscounts] = useState(props.allowDiscounts);
    const [editable, setEditable] = useState(props.editable);
    const [showAddMinimumSpendInput, setShowAddMinimumSpendInput] = useState(false);
    const [discountTypeMap, setDiscountTypeMap] = useState({});

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts)
    }, [props.allowDiscounts]);

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selectedProductPricings);
        }
        if (_.isEmpty(selectedProductPricings)) {
            setShowAddMinimumSpendInput(false);
        }
    }, [selectedProductPricings]);

    useEffect(() => {
        setSelectedProductPricings(props.preselectedProductPricings)
    }, [props.preselectedProductPricings]);

    const onFieldChange = (name, value) => {
        if (_.startsWith(name, `phases.${props.prefix}.discounts.`) && _.endsWith(name, ".type")) {
            setDiscountTypeMap(prevMap => {
                const newMap = {...prevMap};
                newMap[name.split(".")[3]] = value;
                return newMap;
            })
        }
    }

    const validate = (allFields) => {
        if (selectedProductPricings.length === 0) {
            return "Please select at least one product price."
        }
        const uniqueCurrencies = _.uniq(_.map(selectedProductPricings, (pp) => pp.currency));
        if (uniqueCurrencies.length > 1) {
            return "Multiple currencies are not permitted together."
        }
        if (props.requireRecurring) {
            const recurringProductPricings = _.filter(selectedProductPricings, (pp) => pp.type !== "ONETIME_PRICING");
            if (recurringProductPricings.length === 0) {
                return "Please select at least one recurring price.";
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let discounts = [];
        _.each(allFields.phases[props.prefix].discounts, (value, key) => {
            if (parseFloat(value.percentage) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_PERCENT",
                    customer_id: allFields.customer.id,
                    percent: parseFloat(value.percentage)/100,
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            } else if (parseFloat(value.amount) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_AMOUNT",
                    customer_id: allFields.customer.id,
                    amount: {
                        value_in_cents: Math.round(parseFloat(value.amount) * 100),
                        currency: selectedProductPricings[0].currency
                    },
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            }
        })
        let onetime_items = []
        let recurring_pmp_ids = [];
        let scheduled_pmp_ids = [];
        _.each(selectedProductPricings, (p, j) => {
            if (p.type !== "ONETIME_PRICING") {
                // Recurring price
                _.each(p.product_metric_pricings, pmp => {
                    recurring_pmp_ids.push(pmp.id)
                });
                _.each(p.product_metric_pricings, pmp => {
                    if (pmp.item_pricing.type === "SCHEDULED") {
                        scheduled_pmp_ids.push(pmp.id)
                    }
                });
                return;
            }
            const pmp_id = p.product_metric_pricings[0].id;
            onetime_items.push({
                product_pricing_id: p.id,
                aggregate: parseFloat(allFields.phases[props.prefix].config[String(pmp_id)].num_licenses)
            })
        })
        let configItems = [];
        _.each(allFields.phases[props.prefix].config, (v, k) => {
            if (_.includes(scheduled_pmp_ids, k)) {
                configItems.push({
                    ...v,
                    num_licenses: 1,
                    minimum_units: 0,
                    product_metric_pricing_id: k
                })
            } else if (_.includes(recurring_pmp_ids, k)) {
                configItems.push({
                    ...v,
                    num_licenses: parseFloat(v.num_licenses || "0"),
                    minimum_units: parseFloat(v.minimum_units || "0"),
                    product_metric_pricing_id: k
                })
            }
        })
        const phaseData = {
            currency: selectedProductPricings[0].currency,
            config_items: configItems,
            product_pricing_ids: _.map(_.filter(selectedProductPricings, (a) => a.type !== "ONETIME_PRICING"), (p) => p.id),
            onetime_items: onetime_items,
            discounts: discounts,
            minimum_spend: allFields.phases[props.prefix].minimum_spend ? {
                value_in_cents: parseFloat(allFields.phases[props.prefix].minimum_spend) * 100,
                currency: selectedProductPricings[0].currency
            } : { value_in_cents: 0, currency: selectedProductPricings[0].currency }
        }
        if (!props.isStart) {
            phaseData.timing = "RELATIVE";
            phaseData.relative_term = allFields.phases[props.prefix].relative_term
            phaseData.proration_type = "NONE"
        }
        return phaseData;
    }

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    const onProductPricingCreated = (productPricing) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            const newSelectedProductPricings = [...prevSelectedProductPricings];
            newSelectedProductPricings.push(productPricing);
            return newSelectedProductPricings;
        })
    }

    const renderDiscountDescription = (discount) => {
        let description = "";
        if (discount.type === "DISCOUNT_AMOUNT") {
            description = currencyFormatFromPrice(discount.amount);
        } else if (discount.type === "DISCOUNT_PERCENT") {
            description = `${discount.percent * 100 }%`;
        }
        let expirationDescription = null;
        if (discount.expiration_date) {
            expirationDescription = `till ${moment(discount.expiration_date).format("D MMM, YYYY")}`;
        }
        return <div className="flex flex-col grow gap-1">
            <span>{ discount.name }, {description}</span>
            {
                expirationDescription &&
                <span className="text-gray-500 caption">{ expirationDescription }</span>
            }
        </div>;
    }

    const discountTypeOptions = [
        { label: "%", value: "DISCOUNT_PERCENT" },
        { label: "$", value: "DISCOUNT_AMOUNT" },
    ]

    const selectedProductPricingIds = _.map(selectedProductPricings, (pp) => pp.id);

    const showMinimumField = showAddMinimumSpendInput || (props.initialFields && props.initialFields.minimum_spend && props.initialFields.minimum_spend > 0) || false;
    return (
        <div className="bg-slate-50 py-2 px-3 rounded-md border-1 border-slate-200">
            {
                props.showDuration &&
                <div className="flex flex-row gap-2 min-h-[50px] items-center text-md font-semibold text-gray-500 border-b pb-2 mb-3">
                    <span className="text-md font-semibold text-gray-500">
                        {
                            props.isStart ?
                                <span>From start date</span>
                                : <span>After { props.phaseStart && formatTerm(props.phaseStart) }</span>
                        }
                        { props.isEnd ? <span>, till forever</span>: <span>, for</span> }
                    </span>
                    {
                        !props.isEnd &&
                            <Col md="5">
                                <BaseForm.InputGroup formClassName="inline">
                                    <BaseForm.Number name={`phases.${props.prefix+1}.relative_term.count`} min="1" />
                                    <BaseForm.Divider />
                                    <BaseForm.SingleSelect
                                        name={`phases.${props.prefix+1}.relative_term.frequency`} options={getPlanLengthOptions()} showSearch={false}
                                        fullWidth
                                    />
                                </BaseForm.InputGroup>
                            </Col>
                    }
                    <div className="grow"/>
                    { props.canDeletePhase && <Link size="sm" onClick={() => props.onPhaseDelete()} >Remove Phase</Link> }
                </div>
            }
            <div>
            {
                _.map(selectedProductPricings, (pp, i) => {
                    const pmps = pp.product_metric_pricings;
                    return (
                        <div key={i}>
                            <Row>
                                <Col md="12">
                                    <span className="body2">{ pp.product.name }</span>
                                    <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)} />
                                </Col>
                            </Row>
                            {
                                _.map(pmps, (pmp, j) =>
                                    <Row key={j} className="metric-pricing-row">
                                        <Col md="4" xl="5">
                                            { renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item, props.billableItems) }
                                        </Col>
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" && pmp.item_pricing.type !== "SCHEDULED" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "4": "7"} outerInputClassName={`col-xl-${allowDiscounts ? "4": "7"}`} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" && pmp.item_pricing.type === "SCHEDULED" &&
                                            <Col md={allowDiscounts ? "4": "7"}>
                                                <BaseForm.Hidden name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} required />
                                            </Col>
                                        }
                                        {
                                            pmp.metric.type === "ONETIME_METRIC" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "4": "7"} outerInputClassName={`col-xl-${allowDiscounts ? "4": "7"}`} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                            <Col md={allowDiscounts ? "2": "4"} className="d-flex flex-column gap-2">
                                                <div className="body2">Metric</div>
                                                { pmp.metric.name }
                                            </Col>
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                            <BaseForm.Input colSpan={allowDiscounts ? "2": "3"} outerInputClassName={`col-xl-${allowDiscounts ? "2": "4"}`} name={`phases.${props.prefix}.config.${pmp.id}.minimum_units`} label="Minimum (optional)" type="number" step="0.01" min="0" />
                                        }
                                        {
                                            allowDiscounts &&
                                                <Col md="4" xl="3">
                                                <BaseForm.InputGroup label={"Discount"} formInputClassName="gapless">
                                                    <BaseForm.SingleSelect
                                                        name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.type`}
                                                        label="Type" hideLabel placeholder="MM"
                                                        options={discountTypeOptions} showSearch={false}
                                                        alignDropdown={"left"}
                                                        className={"shrink grow-0 basis-1/4"}
                                                    />
                                                    <BaseForm.Divider />
                                                    {
                                                        (!discountTypeMap[pmp.item_pricing.id] || discountTypeMap[pmp.item_pricing.id] === "DISCOUNT_PERCENT") &&
                                                        <BaseForm.Number
                                                            name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.percentage`}
                                                            label="Discount Value" type="number" min="0" step="0.01"
                                                            className={"discount-select bg-red-100 inherit"}
                                                            max="100"
                                                        />
                                                    }
                                                    {
                                                        discountTypeMap[pmp.item_pricing.id] === "DISCOUNT_AMOUNT" &&
                                                        <BaseForm.Number
                                                            name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.amount`}
                                                            label="Discount Amount" type="number" min="0" step="0.01"
                                                            className={"discount-select bg-red-100 inherit"}
                                                            max="1000000"
                                                        />
                                                    }
                                                </BaseForm.InputGroup>
                                                </Col>
                                        }
                                    </Row>
                                )
                            }
                        </div>
                    )
                })
            }
            </div>
            {
                !_.isEmpty(selectedProductPricings) &&
                <div className="mt-1"></div>
            }
            {
                editable &&
                    <Row>
                        <Col lg="6">
                            <ProductPricingInput
                                excludeBundlePricing={props.excludeBundlePricing}
                                extraQueryFilters={props.extraQueryFilters}
                                billableItems={props.billableItems}
                                setSelectedProductPricings={setSelectedProductPricings}
                                selectedProductPricings={selectedProductPricings}
                                onCreateProductPricing={props.onCreateProductPricing}
                                allowCreation={props.allowCreation}
                                menuPlacement={props.menuPlacement}
                            />
                        </Col>
                    </Row>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend && !showMinimumField &&
                <div className="mt-3">
                    <Link onClick={() => setShowAddMinimumSpendInput(true)}>
                        <span className="text-sm">Add Minimum Spend</span>
                    </Link>
                </div>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend && showMinimumField &&
                <div>
                    <Row>
                        <BaseForm.Input
                            colSpan="6" name={`phases.${props.prefix}.minimum_spend`} label={`Minimum Spend (in ${selectedProductPricings[0].currency})`}
                            type="number" step="0.01" min="0" placeholder="0.00" />
                    </Row>
                </div>
            }
        </div>
    )
})

export default ProductPricingSelection;
