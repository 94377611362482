import React, { useContext, useEffect, useMemo } from "react";
import { BaseContext, CustomerContext, CustomerPortalContext } from "../../helpers/common";
import Section from "../../components/Section";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import CustomerPortalCreditsTable from "../../components/CustomerPortalCreditsTable";
import usePostFindCreditsApi from "../../helpers/hooks/api/usePostFindCreditsApi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function CustomerPortalCredits() {
    const { t } = useTranslation("common");

    const navigate = useNavigate();
    const { setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const { portal } = useContext(CustomerPortalContext);
    const showCredits = portal?.options?.credits?.show;

    useEffect(() => {
        if (!showCredits) {
            navigate(-1);
        }
    }, [showCredits]);

    const { credits, isCreditsLoading, hasMoreCredits, onCreditsSearch } = usePostFindCreditsApi({
        options: {
            accessToken,
        },
        limit: 40,
    });

    const isCreditsTableEmpty = credits.length === 0 && !isCreditsLoading;

    const creditsActionOptions = useMemo(() => {
        const options = [];
        if (showCredits && !isCreditsTableEmpty) {
            options.push({
                variant: "text-primary",
                label: t("credits.actions.view_usage"),
                link: getCompanySpecificUrl(`/portal/${portal.id}/credits_log?token=${accessToken}`),
            });
        }
        return options;
    }, [showCredits, isCreditsTableEmpty, portal.id, accessToken]);

    useEffect(() => {
        setPageTitle(t("customer_portal.credits.page_title"));
    }, []);

    useEffect(() => {
        if (showCredits) {
            onCreditsSearch({ keepPreviousData: false });
        }
    }, [showCredits]);

    return (
        <div className="max-w-3xl">
            <Section title="Credits" className="mt-4" actions={creditsActionOptions}>
                {isCreditsTableEmpty ? (
                    <span className={"text-sm text-gray-500"}>{t("credits.table.no_credits")}</span>
                ) : (
                    <InfiniteScroll
                        dataLength={credits.length}
                        next={() => onCreditsSearch({ keepPreviousData: true })}
                        hasMore={hasMoreCredits}
                        scrollableTarget="portal-wrapper"
                        loader={<Loader loading={true} />}
                    >
                        <CustomerPortalCreditsTable
                            creditsData={{
                                credits,
                                hasMoreCredits,
                                isCreditsLoading,
                            }}
                        />
                    </InfiniteScroll>
                )}
            </Section>
        </div>
    );
}

export default CustomerPortalCredits;
