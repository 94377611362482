import React, {useContext, useEffect, useState} from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import BaseSideModal from './BaseSideModal';
import { Row } from 'react-bootstrap';
import BaseForm from '../BaseForm';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../common/buttons/SubmitButton';
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
import _ from "lodash";
import {Link} from "react-router-dom";

function CreateSalesUserModal(props) {
	const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
	const { t } = useTranslation('common');
	const { members: nonPendingMembers, fetchMembers } = useGetMembers(false);
	const [availableCompanyUsers, setAvailableCompanyUsers] = useState([]);

	const loadCommissionPlans = async (query) => {
		const plansPromise = serverPost(getApiUrl('/commission/plan/find'), {
			query: {
				search: query || null,
			},
			sort_key: 'createdAtDesc',
			pagination: { limit: 20 },
		});
		const plansResult = await plansPromise;
		return plansResult.results || [];
	};

	useEffect(() => {
		fetchMembers();
	}, []);

	useEffect(() => {
		const filteredCompanyUsers = _.filter(nonPendingMembers, (user) => {
			return !_.find(props.salesUsers, (salesUser) => salesUser.company_user_id === user.id);
		})

		const teamOptions = _.map(filteredCompanyUsers, (member) => {
			return {
				value: member.id,
				label: member.user.name || member.user.email
			}
		})
		teamOptions.sort((a, b) => {
			if (a.label < b.label) {
				return -1
			} else {
				return 1
			}
		})
		setAvailableCompanyUsers(teamOptions);
	}, [nonPendingMembers, props.salesUsers]);

	const getCommissionPlanLabel = (plan) => {
		return plan.name;
	};

	const getCommissionPlanValue = (plan) => {
		return plan.id;
	};

	const commissionPeriodOptions = [
		{ value: 'MONTHLY', label: 'MONTHLY' },
		{ value: 'QUARTERLY', label: 'QUARTERLY' },
		{ value: 'YEARLY', label: 'YEARLY' },
	];

	const onSubmit = (data) => {
		const payload = {
			company_user_id: data.company_user,
			commission_plan_id: data.commission_plan.id,
			quota_period: data.quota_period,
			payment_period: data.report_period,
			quota_amount: data.quota_amount,
		};
		serverPost(getApiUrl('/commission/sales_user'), payload).then((res) => {
			if (res) {
				props.onSuccess();
			} else {
				props.onFailure();
			}
		});
	};

	return (
		<BaseSideModal {...props}>
			{
				!props.hasCommissionSettings ? (
					<BaseSideModal.Body>
						<div className="alert alert-warning">
							<p>Commission period settings and anchor dates need to be configured before adding sales team members.</p>
							<br/>
							<p>
								<Link to={getCompanySpecificUrl("/commissions/settings")}>
									Configure Commission Settings
								</Link>
							</p>
						</div>
					</BaseSideModal.Body>
				) : (
					<BaseSideModal.Form onSubmit={onSubmit}>
						<BaseSideModal.Header title={'Add Sales Member'} />
						<BaseSideModal.Body>
							<Row>
								<BaseForm.Input colSpan="12" name="company_user" label={"Team Member"} type="select" options={availableCompanyUsers}/>
							</Row>
							<BaseForm.Input
								colSpan='12'
								name='commission_plan'
								label={'Commission plan'}
								type={'selector'}
								loadOptions={loadCommissionPlans}
								getOptionLabel={getCommissionPlanLabel}
								getOptionValue={getCommissionPlanValue}
								required
							/>
							<BaseForm.Input colSpan='12' name='quota_amount' label={'Quota Amount'} type='number' required />
						</BaseSideModal.Body>
						<BaseSideModal.Footer>
							<SubmitButton>Add</SubmitButton>
						</BaseSideModal.Footer>
					</BaseSideModal.Form>
				)
			}
		</BaseSideModal>
	);
}

export default CreateSalesUserModal;
