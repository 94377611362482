import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import _ from 'lodash';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment';
import Columns from '../../components/Columns';
import classnames from 'classnames';
import Label from '../../components/Label';
import CreateSalesUserModal from '../../components/modals/CreateSalesUserModal';
import Notification from '../../components/Notification';

function SalesTeamOverview() {
	const [salesUsers, setSalesUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const [sort, setSort] = useState('createdAtDesc');
	const [fromKey, setFromKey] = useState(null);
	const [meta, setMeta] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const [showCreateSalesUserModal, setShowCreateSalesUserModal] = useState(false);
	const [settings, setSettings] = useState({});
	const [hasCommissionSettings, setHasCommissionSettings] = useState(false);

	useEffect(() => {
		setPageTitle(`Sales Team Overview`);
	}, []);

	const onSearch = (restart = true) => {
		const limit = 50;
		const params = {
			sort_key: sort || 'createdAtDesc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			include_meta: restart,
		};
		serverPost(getApiUrl('/commission/sales_user/find'), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setSalesUsers(results);
				} else {
					setSalesUsers(_.concat(salesUsers, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setLoading(false);
				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
		});
	};

	const fetchSettings = () => {
		setLoading(true);
		serverFetch(getApiUrl("/settings")).then((res) => {
			if (res) {
				setSettings(res);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		onSearch();
		fetchSettings();
	}, []);

	useEffect(() => {
		if (settings.commission_config && settings.commission_config.commission_quota_period_config && settings.commission_config.commission_report_period_config) {
			setHasCommissionSettings(true);
		}
	}, [settings]);

	const onCreateSuccess = () => {
		Notification.Success('Successfully invited sales member');
		setShowCreateSalesUserModal(false);
		onSearch();
	};

	const onCreateFailure = () => {
		Notification.Danger('Error inviting sales member');
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={salesUsers.length}
				>
					<Section
						title='Sales Team Overview'
						loading={loading}
						actions={[
							{
								variant: 'primary',
								icon: 'fa-plus',
								label: 'Add Sales User',
								onClick: () => setShowCreateSalesUserModal(true),
							},
						]}
					>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} />
							<MapleTable.Content>
								<thead>
									<tr>
										<MapleTable.TH>Sales Member</MapleTable.TH>
										<MapleTable.TH>Commission Plan</MapleTable.TH>
										<MapleTable.TH>Quota Achieved</MapleTable.TH>
										<MapleTable.TH>Quota Amount</MapleTable.TH>
										<MapleTable.TH>Commissions Achieved</MapleTable.TH>
										<MapleTable.TH>Status</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{salesUsers.map((row, i) => (
										<MapleTable.TR
											key={i}
											className={classnames(
												row.company_user.pending && 'de-highlighted',
												'cursor-pointer',
												'hover:bg-gray-100',
											)}
											href={getCompanySpecificUrl(`/commissions/sales_user/${row.id}`)}
										>
											<td>
												<Columns.CompanyUserName companyUser={row.company_user} />
											</td>
											<td>{row.commission_plan.name}</td>
											<td>{row.current_quota_period.quota_achieved}</td>
											<td>{row.current_quota_period.quota_value}</td>
											<td>{row.current_quota_period.commission_achieved}</td>
											<td>
												{row.company_user.pending ? (
													<Label.Warning>{'Pending'}</Label.Warning>
												) : (
													<Label.Success>{'Active'}</Label.Success>
												)}
											</td>
										</MapleTable.TR>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
			<CreateSalesUserModal
				show={showCreateSalesUserModal}
				onClose={() => setShowCreateSalesUserModal(false)}
				size={'sm'}
				onSuccess={onCreateSuccess}
				onFailure={onCreateFailure}
				salesUsers={salesUsers}
				hasCommissionSettings={hasCommissionSettings}
			/>
		</BaseContainer>
	);
}

export default SalesTeamOverview;
